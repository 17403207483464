import { Col, Row } from "antd";
import PropTypes from "prop-types";
import React from "react";

import Select from "components/common/Select";
import Tag from "components/common/Tag";

FormSelect.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  listOptions: PropTypes.array,
  optional: PropTypes.bool,
  rules: PropTypes.any,
  disabled: PropTypes.bool,
  width: PropTypes.number,
  subSpan: PropTypes.number
};

function FormSelect({
  label,
  name,
  placeholder,
  listOptions,
  optional,
  rules,
  disabled,
  width,
  subSpan = 24
}) {
  return (
    <Row className="form-item form-select">
      <Col span={7} className="label">
        <h1 className="label">{label}</h1>
      </Col>
      <Col span={9}>
        <Row>
          <Col span={subSpan}>
            <Select
              disabled={disabled}
              rules={rules}
              name={name}
              placeholder={placeholder}
              listOptions={listOptions}
              style={{ width: width && `${width}px` }}
            />
          </Col>
        </Row>
        {optional && <Tag type="optional" />}
      </Col>
    </Row>
  );
}

export default FormSelect;
