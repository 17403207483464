import { Col, Form, Radio } from "antd";
import PropTypes from "prop-types";
import React from "react";

RadioCheckFilter.propTypes = {
  title: PropTypes.string,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  options: PropTypes.array,
  defaultValue: PropTypes.any
};

function RadioCheckFilter({ name, disabled, title, options, defaultValue }) {
  return (
    <Col className="filter-item">
      <div className="mb-5">{title}</div>
      <Form.Item name={name} style={{ width: "250px" }}>
        <Radio.Group
          size="large"
          options={options}
          className="h-36 dis-flex ai-center"
          disabled={disabled}
          defaultValue={defaultValue}
        />
      </Form.Item>
    </Col>
  );
}

export default RadioCheckFilter;
