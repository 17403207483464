import { Col } from "antd";
import Form from "antd/lib/form/Form";
import PropTypes from "prop-types";
import React from "react";

import { INCOME_MAIL_SETTING, PWD_INPUT_VALIDATE_MSG } from "common/constant/account";
import { rulesCreateAccount } from "common/constant/rules";

import {
  FormCheckboxGroup,
  FormDetail,
  FormInput,
  FormInputWithValidate,
  TitleDetail
} from "components/form";


const FormHonshaAccount = ({ form, handleSubmitForm, formData, editMode }) => {
  const onFocus = async () => {
    if(document.getElementById("user_id").hasAttribute("readOnly"))
      document.getElementById("user_id").removeAttribute("readOnly");
  };
  
  return (
    <Col span={18}>
      <Form form={form} onFinish={handleSubmitForm}>
        <div>
          <TitleDetail title="アカウント情報" />
          {editMode ? (
            <FormDetail label="ログインID" value={formData?.user_id} />
          ) : (
            <FormInput
              label="ログインID"
              name="user_id"
              onFocus={onFocus}
              readOnly
              rules={rulesCreateAccount.user_id}
              placeholder=""
              width={320}
            />
          )}
          <FormInput
            width={320}
            label="ユーザー名"
            name="user_nm"
            rules={rulesCreateAccount.user_nm}
            placeholder="テキスト入力"
          />
          <FormInput
            width={320}
            label="ユーザー名（フリガナ）"
            name="user_nm_kana"
            rules={rulesCreateAccount.user_nm_kana}
            placeholder="テキスト入力"
          />
          <FormInput
            width={320}
            label="電話番号"
            name="tel"
            rules={rulesCreateAccount.tel}
            placeholder="08011112222"
          />
          <FormInput
            width={320}
            label="メールアドレス"
            name="mail_addr"
            rules={rulesCreateAccount.mail_addr}
            placeholder="sample.gmail.com"
          />
          {!editMode && (
            <>
              <FormInput
                password
                width={320}
                label="パスワード"
                name="login_password"
                rules={rulesCreateAccount.login_password}
                placeholder="英数字・記号の組み合わせ"
                optional
              />
              <FormInputWithValidate
                password
                validateMsg={PWD_INPUT_VALIDATE_MSG}
                width={320}
                label="パスワード（確認）"
                name="login_password_confirm"
                rules={rulesCreateAccount.login_password_confirm}
                placeholder="英数字・記号の組み合わせ"
                optional
              />
            </>
          )}
          <FormCheckboxGroup
            label="受信メール設定"
            name="income_email_setting"
            listOptions={INCOME_MAIL_SETTING}
            form={form}
            noticeMsg="更新時にメールを受け取る場合はチェックを入れてください"
            isInitValues={formData}
          />
        </div>
      </Form>
    </Col>
  );
};
FormHonshaAccount.propTypes = {
  form: PropTypes.any,
  handleSubmitForm: PropTypes.func,
  formData: PropTypes.object,
  editMode: PropTypes.bool
};
export default FormHonshaAccount;
