import { createSlice } from "@reduxjs/toolkit";

export const slice = createSlice({
  name: "alert",
  initialState: {
    alertObject: { message: "", type: "success" }
  },
  reducers: {
    setAlert: (state, action) => {
      state.alertObject = action.payload;
    }
  }
});

// Action creators are generated for each case reducer function
export const { setAlert } = slice.actions;

// Async action

export const setAlertAction = (object) =>
  function (dispatch) {
    dispatch(setAlert(object));
  };

// Get value from state
export const alertStore = (state) => state.alert;

export default slice.reducer;
