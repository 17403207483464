import { ConfigProvider, Empty, Table } from "antd";
import { IconInvoiceDownLoad } from "assets";
import axios from "axios";
import { saveAs } from "file-saver";
import ProtoTypes from "prop-types";
import React, { useState } from "react";

import { formatDate } from "common/utils/dateHelper";

import { invoiceApi } from "services/invoice";
import { orderApi } from "services/order";

import "./TableListInvoice.scss";
import ViewInvoiceModal from "./ViewInvoiceModal";

import Tag from "components/common/Tag";

const TableListInvoice = ({
  dataSource,
  totalRow,
  onChangePage,
  page,
  onSortInvoices,
  setListInvoices
}) => {
  const [openInvoiceModal, setOpenInvoiceModal] = useState(false);
  const [urlInvoice, setUrlInvoice] = useState();

  const handleCloseModal = () => {
    setOpenInvoiceModal(false);
  };

  const handleOpenModal = async (record) => {
    try {
      const cloudURL = await orderApi.getAttachment(record.invoice_path);
      setUrlInvoice(cloudURL.msg);
    } catch (error) {
      console.log(error);
    }

    setOpenInvoiceModal(true);
  };

  const downloadInvoice = async (record) => {
    try {
      const cloudURL = await orderApi.getAttachment(record.invoice_path);

      const res = await axios.get(cloudURL.msg, {
        responseType: "blob"
      });
      saveAs(res.data, record.file_nm);
      if (record.dl_flg === "0") {
        await invoiceApi.changeDownloadStatus({ file_nm: record.file_nm });
        setListInvoices(
          dataSource.map((data) =>
            data.invoice_no === record.invoice_no &&
            data.seq_no === record.seq_no &&
            data.billing_closing_day &&
            record.billing_closing_day
              ? { ...data, dl_flg: "1" }
              : data
          )
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const columns = [
    {
      title: "ご請求締日",
      width: 130,
      dataIndex: "billing_closing_day",
      sorter: {
        multiple: 1
      },
      render: (text) => formatDate(text)
    },
    {
      title: "お客様名",
      width: 160,
      dataIndex: "billing_addr_nm",
      sorter: {
        multiple: 1
      }
    },
    {
      title: "タカスエ営業所",
      width: 200,
      dataIndex: "office_nm",
      sorter: { multiple: 1 }
    },
    {
      title: "登録日付",
      width: 130,
      dataIndex: "created_date",
      sorter: { multiple: 1 },
      render: (text) => formatDate(text)
    },
    {
      title: "請求書",
      width: 340,
      dataIndex: "file_nm",
      sorter: { multiple: 1 },
      render: (text, record) => (
        <div
          className="preview-btn"
          onClick={() => {
            handleOpenModal(record);
          }}
        >
          {text}
        </div>
      )
    },
    {
      title: "",
      width: 90,
      render: (_, record) =>
        record?.btn_dl_flg === "1" && (
          <div
            className="download-invoice"
            onClick={() => {
              downloadInvoice(record);
            }}
          >
            PDF <IconInvoiceDownLoad />
          </div>
        )
    },
    {
      title: "",
      width: 202,
      dataIndex: "dl_flg",
      render: (text) => (
        <Tag type={text === "0" ? "invoice-pending" : "invoice-complete"} size="sm" />
      )
    }
  ];
  return (
    <div className="list-invoice">
      <ConfigProvider
        renderEmpty={() => (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="適当な検索結果がありません" />
        )}
      >
        <Table
          onChange={onSortInvoices}
          columns={columns}
          rowKey={(record) => `${record.invoice_no} ${record.seq_no} ${record.billing_closing_day}`}
          dataSource={dataSource}
          title={() => (
            <div className="title">
              {dataSource.length > 0 && `全${totalRow}件中${dataSource.length}件を表示`}
            </div>
          )}
          pagination={{
            current: page,
            position: ["topRight"],
            defaultPageSize: 30,
            total: totalRow,
            showSizeChanger: false,
            onChange: onChangePage
          }}
          scroll={{ x: 400, y: 490 }}
        />
      </ConfigProvider>

      <ViewInvoiceModal
        url={urlInvoice}
        isOpenModal={openInvoiceModal}
        onCancelModal={handleCloseModal}
      />
    </div>
  );
};
TableListInvoice.propTypes = {
  dataSource: ProtoTypes.array,
  totalRow: ProtoTypes.number,
  onChangePage: ProtoTypes.func,
  page: ProtoTypes.number,
  onSortInvoices: ProtoTypes.func,
  setListInvoices: ProtoTypes.func
};
export default TableListInvoice;
