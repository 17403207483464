import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import useAuth from "common/hooks/useAuth";

import Attachment from "pages/attachment";
import LoginDriver from "pages/loginDriver/LoginDriver";
import Maintenance from "pages/maintenance/Maintenance";

import routes from "./common/routers";
import "./styles/theme.less";

import Alert from "components/common/Alert";
import LayoutWrapper from "components/layoutWrapper/LayoutWrapper";

function App() {
  const { per } = useAuth();
  const generateRoutes = () => {
    let result = null;
    if (routes.length > 0) {
      result = routes.map((route, index) => {
        return route?.withAuth ? (
          <Route
            key={index}
            path={route.path}
            element={
              <LayoutWrapper
                enablePage={!route?.per || per?.includes(route?.per)}
                haveBtnBack={route.haveBtnBack}
                textBack={route.textBack}
                noPadding={route.noPadding}
              >
                {route.element}
              </LayoutWrapper>
            }
          />
        ) : (
          <Route key={index} path={route.path} element={route.element} />
        );
      });
    }
    return result;
  };

  return (
    <div className="App">
      <Alert />
      <Routes>
        <Route path="/" element={<Navigate to={"/order_list"} replace />} />
        {generateRoutes()}
        <Route path="/maintenance" element={<Maintenance />} />
        <Route path="/login-driver" element={<LoginDriver />} />
        <Route path="/attachment/*" element={<Attachment />} />
        <Route path="/*" element={<Navigate to={"/order_list"} replace />} />
      </Routes>
    </div>
  );
}

export default App;
