import axios from "axios";

import { PATH_NAME } from "common/constant";
import cache from "common/utils/cache";
import sessionCache from "common/utils/sessionCache";

import { BASE_URL } from "../common/config";
import { stringifyParams } from "../common/utils/stringHelper";

const api = axios.create({
  baseURL: BASE_URL,
  paramsSerializer: (params) => stringifyParams(params),
  headers: {
    "Content-Type": "application/json"
  }
});

// Add a request interceptor
api.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    const token = cache.get("token") ? cache.get("token") : sessionCache.get("token");
    if (token) config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

let isRefreshing = false;
let refreshSubscribers = [];

function addSubscriber(callback) {
  refreshSubscribers.push(callback);
}

// Hàm gọi tất cả các callback trong mảng chờ khi token đã được làm mới
function onRefreshed(newToken) {
  refreshSubscribers.forEach((callback) => callback(newToken));
  refreshSubscribers = [];
  isRefreshing = false;
}

// Add a response interceptor
api.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    if (response.request.responseType === "blob") {
      return response;
    }
    return response.data;
  },
  async function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (
      error.response.status === 401 &&
      window.location.pathname !== PATH_NAME.LOGIN &&
      !error.config._retry
    ) {
      error.config._retry = true;
      const refreshToken = cache.get("refreshToken") || sessionCache.get("refreshToken");
      try {
        if (!isRefreshing) {
          isRefreshing = true;
          const { data } = await axios.post(`${BASE_URL}/refresh-token`, {
            refresh_token: refreshToken
          });

          onRefreshed(data.accessToken);
          if (cache.get("token")) {
            cache.set("token", data?.access_token);
            cache.set("refreshToken", data?.refresh_token);
          } else {
            sessionCache.set("token", data?.access_token);
            sessionCache.set("refreshToken", data?.refresh_token);
          }
          error.config.headers.Authorization = `Bearer ${data?.access_token}`;
          return api(error.config);
        }

        return new Promise((resolve) => {
          addSubscriber((token) => {
            error.config.headers.Authorization = `Bearer ${token}`;
            resolve(api(error.config));
          });
        });
      } catch (error) {
        if (cache.get("token")) {
          cache.remove("token");
          cache.remove("refreshToken");
        } else {
          sessionCache.remove("token");
          sessionCache.remove("refreshToken");
        }
        window.location = PATH_NAME.LOGIN;
      }
    }
    if (error.response.status === 503 && window.location.pathname !== PATH_NAME.MAINTENANCE) {
      window.location = PATH_NAME.MAINTENANCE;
    }
    return Promise.reject(error);
  }
);

export default api;
