import moment from "moment";

import {
  DATE_FORMAT,
  DATE_FORMAT_DISPLAY,
  DATE_FORMAT_ORDER,
  DATE_FORMAT_SLASH
} from "common/constant";

export const formatDate = (value) => {
  const dateMoment = value?.length === 10 ? moment(value, DATE_FORMAT) : value;
  return dateMoment?.locale("ja").format(DATE_FORMAT_DISPLAY);
};

export const formatFilterDate = (value) => moment(value).format(DATE_FORMAT);
export const formatFilterDateSlash = (value) => moment(value).format(DATE_FORMAT_SLASH);

export const formatDateOrder = (value) => {
  const date = moment(value);
  return date.isValid() ? date.format(DATE_FORMAT_ORDER) : "";
};

export const formatDateTime = (value, format = DATE_FORMAT_DISPLAY) => {
  if (!value) return "";
  const date = moment(value);
  return date.isValid() ? date.format(format) : "";
};

// Apply when date returned by server has string type "yyyymmdd"
export const formatDateString = (value) => {
  return value.slice(0, 4) + "-" + value.slice(4, 6) + "-" + value.slice(6, 8);
};
