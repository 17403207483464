export const DATE_FORMAT = "YYYY-MM-DD";
export const TIME_FORMAT = "HH:mm";
export const DATE_FORMAT_SLASH = "YYYY/MM/DD";
export const DATE_FORMAT_DISPLAY = "YYYY年MM月DD日(ddd)";
export const DATE_FORMAT_ORDER = "YYYY-MM-DD HH:mm";
export const DATE_FORMAT_POST = "YYYYMMDD";
export const PATH_NAME = {
  HOME: "/",
  ORDER_LIST: "/order_list",
  CREATE_ORDER: "/order",
  UPDATE_ORDER: "order/upd/:orderId",
  CSV_CAPTURE: "/order/csvcapture",
  INVOICE_LIST: "/invoice_list",
  USERS: "/users",
  ACCOUNT_LIST: "/account_list",
  ACCOUNT_DETAIL: "/account/:userId",
  ACC_REG_MAIN: "/account_regi/main",
  ACC_REG_HONSHA: "/account_regi/honsha",
  ACC_REG_UNSO: "/account_regi/unso",
  ACC_UPD_MAIN: "/account_upd/main/:userId",
  ACC_UPD_HONSHA: "/account_upd/honsha/:userId",
  ACC_UPD_UNSO: "/account_upd/unso/:userId",
  USER_DETAIL: "/userinfo/detail",
  USER_UPDATE: "/userinfo/upd",
  LOGIN: "/login",
  MAINTENANCE: "/maintenance",
  MASTER_LIST: "/master_list",
  BASE_LIST: "/base_list",
  BASE_UPDATE: "/base_upd/:baseId",
  ARRIVAL_LIST: "/arrival_list",
  SERVICE_LIST: "/service_list",
  SERVICE_DETAIL: "/service_detail/:serviceNo",
  OPERATION: "/operation",
  PRIVACY_POLICY: "/policy/privacy",
};

export const ROLE = {
  CUSTOMER: "01",
  ADMIN: "02",
  SUB_ADMIN: "03"
};

export const ADMIN_ROLE = "02";

export const PER_ACCESS_PAGES = {
  ACCOUNT_LIST: "CMG0101",
  CREATE_ACCOUNT: "CMG0102_1",
  ADD_CLIENT: "CMG0102_2",
  ADD_TRANS: "CMG0102_3",
  CREATE_ORDER: "TRG0101",
  ORDER_LIST: "TRG0102",
  INVOICE_LIST: "FMG0103",
  LIST_MASTER: "MST0101",
  LIST_MASTER_BASE: "MST0201",
  UPDATE_MASTER_BASE: "MST0202",
  ARRIVAL_LIST: "TRG0105",
  SERVICE_LIST: "TRG0201",
  SERVICE_DETAIL: "TRG0202",
  OPERATION: "TRG0203"
};

export const PER_AUTH_DIV = {
  CRUD: "01",
  ONLY_VIEW: "02"
};

export const STATUS = {
  TEMP: "01",
  DONE: "02"
};

export const DIV_BOOL = [
  {
    value: "1",
    label: "可"
  },
  {
    value: "0",
    label: "不可"
  }
];

export const DIV_PACKING = [
  {
    name: "packing_sepa",
    label: "バラ"
  },
  {
    name: "packing_pl",
    label: "PL"
  },
  {
    name: "packing_lo",
    label: "長尺物"
  },
  {
    name: "packing_variant",
    label: "異形物"
  }
];

export const LIST_OFFICE = [
  {
    value: 1,
    label: "名古屋営業所"
  },
  {
    value: 2,
    label: "東京営業所"
  },
  {
    value: 3,
    label: "東京営業所"
  },
  {
    value: 4,
    label: "東京営業所"
  },
  {
    value: 5,
    label: "東京営業所"
  },
  {
    value: 6,
    label: "東京営業所"
  },
  {
    value: 7,
    label: "東京営業所"
  }
];

export const REGI_STATUS = [
  { label: "登録済", value: "0" },
  { label: "途中保存", value: "1" }
];

export const OPTION_CUSTOMER_DIV = [
  { value: "01", label: "荷主" },
  { value: "02", label: "荷受人" }
];

export const PDF_TYPE = "application/pdf";

export const IMAGE_TYPES = [
  "image/jpg",
  "image/jpeg",
  "image/png",
  "image/heic",
  "image/tiff",
  "image/bmp",
  "image/gif"
];

export const SCREEN_TEXT_BACK = {
  UPDATE_BASE: "マスタ"
};
