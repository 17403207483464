import { Col, Form, Input } from "antd";
import PropTypes from "prop-types";
import React from "react";

InputFilter.propTypes = {
  title: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  width: PropTypes.number,
  rules: PropTypes.any
};

function InputFilter({ title, placeholder, name, disabled, width, rules }) {
  return (
    <Col className="filter-item">
      <div className="mb-5">{title}</div>
      <Form.Item name={name} rules={rules}>
        <Input
          placeholder={placeholder}
          style={{ width: `${width ? `${width}px` : "280px"}` }}
          name={name}
          disabled={disabled}
        />
      </Form.Item>
    </Col>
  );
}

export default InputFilter;
