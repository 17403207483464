import { createSlice } from "@reduxjs/toolkit";

export const slice = createSlice({
  name: "flowAccount",
  initialState: {
    formData: null,
    listClientCd: [],
    listBillingAddress: [],
    listOptOfficeInvoice: [],
    listOptOfficeOrder: [],
    isEmptyClient: false,
    isEmptyBilling: false
  },
  reducers: {
    setFormData: (state, action) => {
      state.formData = action.payload.formData;
    },
    setListOfficeClient: (state, action) => {
      state.listClientCd = action.payload.listClientCd;
    },
    setListBillingAddress: (state, action) => {
      state.listBillingAddress = action.payload.listBillingAddress;
    },
    setListOptOfficeInvoice: (state, action) => {
      state.listOptOfficeInvoice = action.payload.listOptOfficeInvoice;
    },
    setListOptOfficeOrder: (state, action) => {
      state.listOptOfficeOrder = action.payload.listOptOfficeOrder;
    },
    setIsEmptyClient: (state, action) => {
      state.isEmptyClient = action.payload.isEmpty;
    },
    setIsEmptyBilling: (state, action) => {
      state.isEmptyBilling = action.payload.isEmpty;
    }
  }
});

// Action creators are generated for each case reducer function
export const {
  setFormData,
  setListOfficeClient,
  setListBillingAddress,
  setListOptOfficeInvoice,
  setListOptOfficeOrder,
  setIsEmptyClient,
  setIsEmptyBilling
} = slice.actions;

// Async action

export const setFormDataAction = (formData) =>
  function (dispatch) {
    dispatch(setFormData({ formData }));
  };

export const setListOfficeClientAction = (listClientCd) =>
  function (dispatch) {
    dispatch(setListOfficeClient({ listClientCd }));
  };

export const setListBillingAddressAction = (listBillingAddress) =>
  function (dispatch) {
    dispatch(setListBillingAddress({ listBillingAddress }));
  };

export const setListOptOfficeInvoiceAction = (listOptOfficeInvoice) =>
  function (dispatch) {
    dispatch(setListOptOfficeInvoice({ listOptOfficeInvoice }));
  };

export const setListOptOfficeOrderAction = (listOptOfficeOrder) =>
  function (dispatch) {
    dispatch(setListOptOfficeOrder({ listOptOfficeOrder }));
  };

export const setIsEmptyClientAction = (isEmpty) =>
  function (dispatch) {
    dispatch(setIsEmptyClient({ isEmpty }));
  };

export const setIsEmptyBillingAction = (isEmpty) =>
  function (dispatch) {
    dispatch(setIsEmptyBilling({ isEmpty }));
  };

export const clearDataAction = () =>
  function (dispatch) {
    dispatch(setFormData({ formData: null }));
    dispatch(setListOfficeClient({ listClientCd: [] }));
    dispatch(setListBillingAddress({ listBillingAddress: [] }));
    dispatch(setListOptOfficeInvoice({ listOptOfficeInvoice: [] }));
    dispatch(setListOptOfficeOrder({ listOptOfficeOrder: [] }));
    dispatch(setIsEmptyClient({ isEmpty: false }));
    dispatch(setIsEmptyBilling({ isEmpty: false }));
  };

// Get value from state
export const flowAccountStore = (state) => state.flowAccount;

export default slice.reducer;
