import { Button } from "antd";
import { IconRegiSuccess } from "assets";
import { PATH_NAME } from "common/constant";
import React from "react";
import { useNavigate } from "react-router";

const CreateAccountSuccess = () => {
  const navigate = useNavigate();
  return (
    <div className="account-success">
      <IconRegiSuccess />
      <h1>登録が完了しました</h1>
      <Button
        onClick={() => {
          navigate(PATH_NAME.ACCOUNT_LIST);
        }}
        size="large"
        style={{ width: "280px" }}
      >
        アカウント一覧に戻る
      </Button>
    </div>
  );
};

export default CreateAccountSuccess;
