import { Button, Col, Row } from "antd";
import PropTypes, { array, string } from "prop-types";
import React, { useState } from "react";

import { STATUS } from "common/constant";
import { formatDate } from "common/utils/dateHelper";
import { getLabel } from "common/utils/stringHelper";

import LinkUpload from "components/common/LinkUpload";
import Tag from "components/common/Tag";

FormDetail.propTypes = {
  type: PropTypes.oneOf([
    "status",
    "text",
    "date",
    "link",
    "select",
    "postcd",
    "password",
    "checkbox-group"
  ]),
  label: string,
  value: PropTypes.any,
  listOptions: array
};

function FormDetail({ type, label, value, listOptions }) {
  const [pwdView, setPwdView] = useState(false);
  const togglePwdView = () => {
    setPwdView(!pwdView);
  };
  let valueGenerated;
  switch (type) {
    case "status":
      valueGenerated =
        value === STATUS.DONE ? <Tag type="fix" size="sm" /> : <Tag type="un-fix" size="sm" />;
      break;
    case "text":
      valueGenerated = value;
      break;
    case "postcd":
      valueGenerated = `〒${value || ""}`;
      break;
    case "password":
      valueGenerated = (
        <div>
          {pwdView ? value : "**********"}
          <Button onClick={togglePwdView} type="link">
            {pwdView ? "パスワードを非表示" : "パスワードを表示"}
          </Button>
        </div>
      );
      break;
    case "date":
      valueGenerated = formatDate(value);
      break;
    case "link":
      valueGenerated = value?.map((file) => <LinkUpload key={file?.uid} file={file} />);
      break;
    case "select":
      valueGenerated = getLabel(value, listOptions);
      break;
    case "checkbox-group":
      if (value) {
        valueGenerated = listOptions?.map(({ name, label }) => {
          if (value[name] === "1")
            return (
              <div key={name} className="div-packing">
                {label}
              </div>
            );
          return;
        });
      }
      break;
    default:
      valueGenerated = value;
      break;
  }

  return (
    <Row className="form-item form-input">
      <Col span={7} className="label">
        <h1 className="label">{label}</h1>
      </Col>
      <Col span={17} className="value-confirm">
        {valueGenerated}
      </Col>
    </Row>
  );
}

export default FormDetail;
