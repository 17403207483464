import React from "react";
import PropTypes from "prop-types";
import { Button, Modal, Progress } from "antd";
import "./ModalUploadCSV.scss";
import { useNavigate } from "react-router-dom";
import { PATH_NAME } from "common/constant";

ModalUploadCSV.propTypes = {
  percentProcess: PropTypes.number,
  isShowModal: PropTypes.bool,
  handleCancel: PropTypes.func,
  importSuccess: PropTypes.bool
};

// eslint-disable-next-line no-unused-vars
function ModalUploadCSV({ percentProcess, isShowModal, handleCancel, importSuccess }) {
  const navigate = useNavigate();

  return (
    <Modal
      wrapClassName="modal-upload-csv"
      footer={null}
      visible={isShowModal}
      onCancel={handleCancel}
    >
      <div className="content">
        {percentProcess >= 100 && importSuccess ? (
          <>
            <h1>アップロード完了</h1>
            <h2>アップロードがすべて完了しました。</h2>
            <Button
              type="primary"
              size="large"
              style={{ width: "280px" }}
              onClick={() => navigate(PATH_NAME.ORDER_LIST)}
            >
              配送依頼一覧に戻る
            </Button>
          </>
        ) : (
          <>
            <h1>アップロード中</h1>
            <h2>しばらくお待ちください。</h2>
            <Progress percent={percentProcess} strokeColor="#003399" showInfo={false} />
          </>
        )}
      </div>
    </Modal>
  );
}

export default ModalUploadCSV;
