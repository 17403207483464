import Icon from "@ant-design/icons/lib/components/Icon";
import { Button } from "antd";
import { IconTrash } from "assets";
import PropTypes from "prop-types";
import React from "react";

import { ROLE } from "common/constant";
import useAuth from "common/hooks/useAuth";

FooterDetail.propTypes = {
  handleRemove: PropTypes.func,
  handleEdit: PropTypes.func
};

function FooterDetail({ handleRemove, handleEdit }) {
  const { role } = useAuth();
  return (
    <div className="footer-create-customer bg-white">
      <div className="hint">内容を変更する場合はアカウント情報の変更を押してください</div>
      <div>
        {role === ROLE.ADMIN && (
          <Button
            className="btn-submit"
            style={{ marginTop: "8px" }}
            size="large"
            danger
            icon={<Icon style={{ fontSize: "20px" }} component={IconTrash} />}
            onClick={handleRemove}
          >
            アカウント削除
          </Button>
        )}
        <Button onClick={handleEdit} className="btn-submit" size="large">
          アカウント内容の変更
        </Button>
      </div>
    </div>
  );
}

export default FooterDetail;
