import { ConfigProvider, Empty, Popover, Space, Table } from "antd";
import { IconDownload } from "assets";
import ProtoTypes from "prop-types";
import React, { useState } from "react";
import { Link } from "react-router-dom";

import { ROLE } from "common/constant";
import { formatDate, formatDateOrder, formatDateString } from "common/utils/dateHelper";

import { arrivalApi } from "services/arrival";

import OptionStatus from "./OptionStatus";
import "./TableListArrival.scss";

import Tag from "components/common/Tag";

const TableListArrival = ({
  dataSource,
  totalRow,
  onPageChange,
  page,
  onSortOrders,
  handleDownloadSignImage,
  refetch,
  role
}) => {
  const [open, setOpen] = useState({ id: null, status: null });

  const handleOpen = ({ id, status }) => {
    if (role !== ROLE.CUSTOMER) {
      setOpen({ id, status });
    }
  };

  const handleOpenChange = (newOpen) => {
    if (!newOpen && role !== ROLE.CUSTOMER) {
      setOpen({ id: null, status: null });
    }
  };

  const handleChangeDeliStatus = async (status) => {
    try {
      await arrivalApi.updateDeliStatus(open.id, { last_deli_status: status });
      await refetch();
    } catch (error) {
      console.log(error);
    } finally {
      setOpen({ id: null, status: null });
    }
  };

  const baseColumns = [
    {
      title: "配達状況",
      width: 110,
      dataIndex: "last_deli_status",
      sorter: {
        multiple: 1
      },
      render: (text, record) => {
        let type;
        switch (text) {
          case "01":
            type = "delivery-uncomplete";
            break;
          case "02":
            type = "delivery-complete";
            break;
          case "03":
            type = "delivery-return";
            break;
        }
        return text ? (
          <Popover
            className="status"
            content={<OptionStatus check={open.status} onChangeStatus={handleChangeDeliStatus} />}
            trigger="click"
            open={open.id === record.service_instruct_no}
            onOpenChange={handleOpenChange}
            placement="bottom"
          >
            <Tag
              onClick={() => {
                handleOpen({ id: record.service_instruct_no, status: record.last_deli_status });
              }}
              type={type}
              size="sm"
            />
          </Popover>
        ) : null;
      }
    },
    {
      title: "配達日",
      width: 160,
      dataIndex: "service_ymd",
      sorter: {
        multiple: 1
      },
      render: (text) => (text ? formatDate(formatDateString(text)) : null)
    },
    {
      title: "配達先",
      width: 160,
      dataIndex: "last_customer_nm",
      sorter: {
        multiple: 1
      }
    },
    {
      title: "受注No.",
      width: 120,
      dataIndex: "tls_order_no",
      sorter: {
        multiple: 1
      },
      render: (text, record) =>
        record?.exist_order_no ? (
          <Link to={`/order/upd/${record?.tls_order_no}`}>{text}</Link>
        ) : (
          text
        )
    },
    {
      title: "お客様管理No.",
      width: 150,
      dataIndex: "client_manage_no",
      sorter: {
        multiple: 1
      }
    },
    {
      title: "電子サイン",
      width: 120,
      dataIndex: "has_sign_img",
      sorter: {
        multiple: 1
      },
      render: (text, record) => {
        return text ? (
          <Space>
            サイン
            <IconDownload
              onClick={() => handleDownloadSignImage(record?.service_instruct_no)}
              className="cursor-pointer"
            />
          </Space>
        ) : null;
      }
    },
    {
      title: "数量",
      width: 90,
      dataIndex: "quantity",
      sorter: {
        multiple: 1
      },
      render: (text, record) => (text ? `${text} ${record?.quantity_unit_nm || ""}` : null)
    },
    {
      title: "重量",
      width: 90,
      dataIndex: "weight",
      sorter: {
        multiple: 1
      },
      render: (text, record) => (text ? `${text} ${record?.weight_unit_nm || ""}` : null)
    },
    {
      title: "容積",
      width: 80,
      dataIndex: "volume",
      sorter: {
        multiple: 1
      },
      render: (text, record) => (text ? `${text} ${record?.volume_unit_nm || ""}` : null)
    },
    {
      title: "受領日",
      width: 160,
      dataIndex: "work_time",
      sorter: {
        multiple: 1
      },
      render: (text) => (text ? formatDate(text) : null)
    },
    {
      title: "集荷日",
      width: 160,
      dataIndex: "picking_date",
      sorter: {
        multiple: 1
      },
      render: (text) => (text ? formatDate(formatDateString(text)) : null)
    },
    {
      title: "集荷先",
      width: 160,
      dataIndex: "pickup_addr_nm",
      sorter: {
        multiple: 1
      }
    },
    {
      title: "お客様",
      width: 160,
      dataIndex: "billing_addr_nm",
      sorter: {
        multiple: 1
      }
    },
    {
      title: "タカスエ営業所",
      width: 260,
      dataIndex: "office_nm",
      render: (text, record) =>
        `${text || ""} ${record.org_office_nm ? `(${record.org_office_nm})` : ""}`,
      sorter: {
        multiple: 1
      }
    },
    {
      title: "タカスエ管理No.",
      width: 160,
      dataIndex: "slip_no",
      sorter: {
        multiple: 1
      }
    }
  ];

  const columns =
    role !== ROLE.CUSTOMER
      ? [
        ...baseColumns,
        {
          title: "最終更新者",
          width: 140,
          dataIndex: "upd_last_deli_status_by",
          sorter: {
            multiple: 1
          }
        },
        {
          title: "最終更新日時",
          width: 160,
          dataIndex: "upd_last_deli_status_time",
          sorter: {
            multiple: 1
          },
          render: (text) => (text ? formatDateOrder(text) : null)
        }
      ]
      : baseColumns;

  return (
    <div className="table-arrival">
      <ConfigProvider
        renderEmpty={() => (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="適当な検索結果がありません" />
        )}
      >
        <Table
          title={() => (
            <div className="title">
              {dataSource.length > 0 && `全${totalRow}件中${dataSource.length}件を表示`}
            </div>
          )}
          columns={columns}
          rowKey={(record) => record.id}
          dataSource={dataSource}
          onChange={onSortOrders}
          pagination={{
            current: page,
            onChange: onPageChange,
            position: ["topRight"],
            defaultPageSize: 30,
            total: totalRow,
            showSizeChanger: false
          }}
          scroll={{ x: 400, y: "60vh" }}
        />
      </ConfigProvider>
    </div>
  );
};
TableListArrival.propTypes = {
  dataSource: ProtoTypes.array,
  totalRow: ProtoTypes.number,
  onPageChange: ProtoTypes.func,
  page: ProtoTypes.number,
  onSortAccounts: ProtoTypes.func,
  onSortOrders: ProtoTypes.func,
  handleDownloadSignImage: ProtoTypes.func,
  refetch: ProtoTypes.func,
  role: ProtoTypes.string
};
export default TableListArrival;
