import { saveAs } from "file-saver";

export const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

export function generateBase64BlobURL(base64Data) {
  const sliceSize = 1024;
  const byteCharacters = atob(base64Data);
  const bytesLength = byteCharacters.length;
  const slicesCount = Math.ceil(bytesLength / sliceSize);
  const byteArrays = new Array(slicesCount);

  for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
    const begin = sliceIndex * sliceSize;
    const end = Math.min(begin + sliceSize, bytesLength);

    const bytes = new Array(end - begin);
    for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
      bytes[i] = byteCharacters[offset].charCodeAt(0);
    }
    byteArrays[sliceIndex] = new Uint8Array(bytes);
  }
  const blob = new Blob(byteArrays, { type: "application/pdf" });
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    // support IE
    window.navigator.msSaveOrOpenBlob(blob, "file.pdf");
  } else {
    const blobUrl = URL.createObjectURL(blob);
    return blobUrl;
  }

  return null;
}

export const saveFileWithExactName = (res) => {
  const fileName = res.headers?.["content-disposition"]?.slice(21);
  const dataBlob = res.data;
  saveAs(dataBlob, fileName);
};
