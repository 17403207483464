import { IconRedTrash } from "assets";
import PropTypes from "prop-types";
import React from "react";

const ListChosePartner = ({ partners, handleRemoveItem }) => {
  const handleRemovePartner = (partner) => {
    if (!partner?.isFixed) {
      handleRemoveItem(partner.id);
    }
  };
  return (
    <div className="list-chose-partners">
      <h2 className="title">追加した取引先</h2>
      <div className="chose-partners">
        {partners.map((partner) => (
          <div className="chose-partner" key={partner.id}>
            {partner.name}
            <div
              style={{ cursor: partner?.isFixed ? "not-allowed" : "pointer" }}
              onClick={() => {
                handleRemovePartner(partner);
              }}
            >
              <IconRedTrash />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

ListChosePartner.propTypes = {
  partners: PropTypes.array,
  handleRemoveItem: PropTypes.func
};
export default ListChosePartner;
