import { Form, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { PATH_NAME, ROLE } from "common/constant";
import { genPostBodyAcc } from "common/utils/accountHelper";
import { isEmptyObj, trimObj } from "common/utils/objectHelper";

import { userApi } from "services/user";

import {
  clearDataAction,
  flowAccountStore,
  setFormDataAction,
  setIsEmptyBillingAction,
  setIsEmptyClientAction
} from "store/slices/flowAccount";

import {
  CreateAccountSuccess,
  FooterCreate,
  FormCustomerAccount,
  HeadOfficePartners,
  InforAccount,
  RightCreate,
  ShippingPartners
} from "../components";
import "./CreateCustomerAccount.scss";

import Divider from "components/common/Divider";

const CreateCustomerAccount = () => {
  const [isRegisterSuccess, setIsRegisterSuccess] = useState(false);
  const [currentPageFlag, setCurrentPageFlag] = useState("form");

  const { formData, listBillingAddress, listClientCd } = useSelector(flowAccountStore);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [form] = Form.useForm();

  const toggleFormDone = async () => {
    const valueObj = isEmptyObj(form.getFieldsValue()) ? formData : form.getFieldsValue();
    const haveClientPage = valueObj["main_office_div"] === "1";
    const haveTransPage = valueObj["transport_div"] === "1";
    switch (currentPageFlag) {
      case "form": {
        await form
          .validateFields()
          .then(() => {
            form.submit();
            if (haveClientPage) setCurrentPageFlag("client");
            else if (haveTransPage) setCurrentPageFlag("trans");
            else setCurrentPageFlag("infor");
          })
          .catch((e) => {
            console.log("error", e);
            // setValidateFail(true);
          });
        break;
      }
      case "client": {
        if (listClientCd.length > 0) {
          if (haveTransPage) setCurrentPageFlag("trans");
          else setCurrentPageFlag("infor");
        } else dispatch(setIsEmptyClientAction(true));
        break;
      }
      case "trans": {
        if (listBillingAddress.length > 0) setCurrentPageFlag("infor");
        else dispatch(setIsEmptyBillingAction(true));
        break;
      }
      case "infor": {
        const postBody = genPostBodyAcc(valueObj, listClientCd, listBillingAddress);
        await userApi
          .createUser(postBody)
          .then((res) => {
            if (res) {
              setIsRegisterSuccess(true);
            }
          })
          .catch((e) => console.log(e));
        break;
      }
      default:
        break;
    }
  };

  const handleSaveDraft = async () => {
    const valueObj = isEmptyObj(form.getFieldsValue()) ? formData : form.getFieldsValue();
    const emptyArrr = [{ id: "00000000000", name: "null" }];
    const listClientDraft = listClientCd.length > 0 ? listClientCd : emptyArrr;
    const listBillingDraft = listBillingAddress.length > 0 ? listBillingAddress : emptyArrr;
    const postBody = genPostBodyAcc(valueObj, listClientDraft, listBillingDraft, true);
    if (currentPageFlag === "form") {
      await form
        .validateFields()
        .then(async () => {
          await userApi
            .createUser(postBody)
            .then((res) => {
              if (res) {
                navigate(PATH_NAME.ACCOUNT_LIST);
              }
            })
            .catch((e) => console.log(e));
        })
        .catch((e) => {
          console.log("error", e);
          // setValidateFail(true);
        });
    } else {
      await userApi
        .createUser(postBody)
        .then((res) => {
          if (res) {
            navigate(PATH_NAME.ACCOUNT_LIST);
          }
        })
        .catch((e) => console.log(e));
    }
  };

  const handleSubmitForm = (params) => {
    const paramsTrimed = trimObj({ ...params, use_user_div: ROLE.CUSTOMER });
    dispatch(setFormDataAction(paramsTrimed));
  };

  const generateUi = (component) => {
    return (
      <Row justify="space-between" gutter={[100]} className="form-customer-account">
        {component}
        <RightCreate
          isFormConfirm={currentPageFlag === "infor"}
          toggleFormDone={toggleFormDone}
          setCurrentPageFlag={setCurrentPageFlag}
          currentPageFlag={currentPageFlag}
          handleSaveDraft={handleSaveDraft}
        />
      </Row>
    );
  };

  const listPage = {
    form: generateUi(<FormCustomerAccount form={form} handleSubmitForm={handleSubmitForm} />),
    client: generateUi(<HeadOfficePartners />),
    trans: generateUi(<ShippingPartners />),
    infor: generateUi(<InforAccount />)
  };

  useEffect(() => {
    dispatch(clearDataAction());
    form.resetFields();
    form.setFieldsValue({
      account_info_upd_send_flg: "1",
      invoice_send_flg: "1",
      delivery_req_send_flg: "1",
      office_logic_flag: "some"
    });
  }, []);

  return (
    <div className="create-customer-account-page">
      {isRegisterSuccess ? (
        <div className="content bg-white">
          <CreateAccountSuccess />
        </div>
      ) : (
        <>
          <div className="main-content bg-white">
            <h1 className="title">
              {currentPageFlag === "infor" ? "登録内容確認" : "アカウント登録"}
            </h1>
            <Divider height="2px" />
            {listPage[currentPageFlag]}
          </div>
          <FooterCreate
            currentPageFlag={currentPageFlag}
            toggleFormDone={toggleFormDone}
            isFormConfirm={currentPageFlag === "infor"}
            setCurrentPageFlag={setCurrentPageFlag}
            handleSaveDraft={handleSaveDraft}
          />
        </>
      )}
    </div>
  );
};

export default CreateCustomerAccount;
