import { Form, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { PATH_NAME, ROLE } from "common/constant";
import { DIV_AUTH_GROUP_CODE } from "common/constant/account";
import { isEmptyObj, trimObj } from "common/utils/objectHelper";

import { userApi } from "services/user";

import { flowAccountStore, setFormDataAction } from "store/slices/flowAccount";

import {
  CreateAccountSuccess,
  FooterCreate,
  FormHonshaAccount,
  InforAccount,
  RightCreate
} from "../components";
import "./CreateCustomerAccount.scss";

import Divider from "components/common/Divider";

const CreateHonsaAccount = () => {
  const [isFormConfirm, setIsFormConfirm] = useState(false);
  const [isRegisterSuccess, setIsRegisterSuccess] = useState(false);
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const { formData } = useSelector(flowAccountStore);
  const dispatch = useDispatch();

  const toggleConfirmForm = async () => {
    try {
      if (!isFormConfirm) {
        await form.validateFields();
        form.submit();
        setIsFormConfirm(true);
      } else {
        const {
          user_id,
          invoice_send_flg,
          delivery_req_send_flg,
          account_info_upd_send_flg,
          login_password,
          ...rest
        } = formData;
        const dataPost = {
          user: {
            ...rest,
            company_cd: 10,
            id: user_id,
            login_password: login_password || "",
            use_user_div: ROLE.ADMIN
          },
          user_mail_manage: {
            user_id,
            account_info_upd_send_flg,
            invoice_send_flg,
            delivery_req_send_flg
          },
          billing_addr_ele: {
            office_to_billing_cds: ["0000"],
            billing_addr_cds: ["00000000000"],
            billing_addr_nms: [null]
          },
          auth_group_ids: [DIV_AUTH_GROUP_CODE.ADMIN]
        };
        await userApi
          .createUser(dataPost)
          .then((res) => {
            if (res) setIsRegisterSuccess(true);
          })
          .catch((e) => console.log(e));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSaveDraft = async () => {
    const valueObj = isEmptyObj(form.getFieldsValue()) ? formData : form.getFieldsValue();
    const paramsTrimed = trimObj({ ...valueObj, use_user_div: ROLE.ADMIN });
    const {
      user_id,
      login_password,
      invoice_send_flg,
      delivery_req_send_flg,
      account_info_upd_send_flg,
      ...rest
    } = paramsTrimed;
    const dataPost = {
      user: {
        ...rest,
        login_password: login_password || "",
        company_cd: 10,
        id: user_id,
        use_user_div: ROLE.ADMIN,
        save_flg: "1"
      },
      user_mail_manage: {
        user_id,
        account_info_upd_send_flg,
        invoice_send_flg,
        delivery_req_send_flg
      },
      billing_addr_ele: {
        office_to_billing_cds: ["0000"],
        billing_addr_cds: ["00000000000"],
        billing_addr_nms: [null]
      },
      auth_group_ids: [DIV_AUTH_GROUP_CODE.ADMIN]
    };
    await form
      .validateFields()
      .then(async () => {
        await userApi
          .createUser(dataPost)
          .then((res) => {
            if (res) {
              navigate(PATH_NAME.ACCOUNT_LIST);
            }
          })
          .catch((e) => console.log(e));
      })
      .catch((e) => {
        console.log("error", e);
      });
  };

  const handleSubmitForm = (params) => {
    const paramsTrimed = trimObj({ ...params, use_user_div: ROLE.ADMIN });
    dispatch(setFormDataAction(paramsTrimed));
  };

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue({
      account_info_upd_send_flg: "1",
      invoice_send_flg: "1",
      delivery_req_send_flg: "1"
    });
  }, []);

  return (
    <div className="create-customer-account-page">
      {isRegisterSuccess ? (
        <div className="content bg-white">
          <CreateAccountSuccess />
        </div>
      ) : (
        <>
          <div className="main-content bg-white">
            <h1 className="title">{isFormConfirm ? "登録内容確認" : "アカウント登録"}</h1>
            <Divider height="2px" />
            <Row justify="space-between" gutter={[100]} className="form-customer-account">
              {isFormConfirm ? (
                <InforAccount />
              ) : (
                <FormHonshaAccount form={form} handleSubmitForm={handleSubmitForm} />
              )}
              <RightCreate
                setIsFormConfirm={setIsFormConfirm}
                toggleFormDone={toggleConfirmForm}
                isFormConfirm={isFormConfirm}
                handleSaveDraft={handleSaveDraft}
              />
            </Row>
          </div>
          <FooterCreate
            toggleFormDone={toggleConfirmForm}
            isFormConfirm={isFormConfirm}
            setIsFormConfirm={setIsFormConfirm}
            handleSaveDraft={handleSaveDraft}
          />
        </>
      )}
    </div>
  );
};

export default CreateHonsaAccount;
