import { Form, Row } from "antd";
import { IconInfo } from "assets";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ROLE } from "common/constant";
import useAuth from "common/hooks/useAuth";
import { formatFilterDate } from "common/utils/dateHelper";

import { invoiceApi } from "services/invoice";

import { authStore } from "store/slices/auth";
import { getListOptOfficesAction, pulldownStore } from "store/slices/pulldown";

import TableListInvoice from "../components/TableListInvoice";
import "./ListInvoice.scss";

import DatePicker from "components/common/DatePicker";
import Divider from "components/common/Divider";
import InputFilter from "components/filter/InputFilter";
import SelectFilter from "components/filter/SelectFilter";

const ListInvoice = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { role } = useAuth(authStore);
  const { listOptOfficesInvoice } = useSelector(pulldownStore);
  
  const [listInvoices, setListInvoices] = useState([]);
  const [totalInvoices, setTotalInvoices] = useState();
  const [params, setParams] = useState({ page: 1 });
  const [sortParams, setSortParams] = useState([]);

  const handleFilter = (changedFields) => {
    if (changedFields[0].value) {
      if (changedFields[0].name[0] === "billing_closing_day") {
        setParams({
          ...params,
          [changedFields[0].name[0]]: formatFilterDate(changedFields[0]?.value?._d),
          page: 1
        });
      } else {
        setParams({
          ...params,
          [changedFields[0].name[0]]: changedFields[0].value,
          page: 1
        });
      }
    } else {
      delete params[changedFields[0].name[0]];
      setParams({ ...params, page: 1 });
    }
  };

  const handleSortInvoices = (pagination, _, sorter) => {
    if (pagination.current === params.page) {
      if (sorter.length > 1) {
        setSortParams(
          sorter.map((item) => (item.order === "descend" ? `-${item.field}` : item.field))
        );
      } else if (sorter.column) {
        setSortParams([sorter.order === "descend" ? `-${sorter.field}` : sorter.field]);
      } else {
        setSortParams([]);
      }
    }
  };

  const disabledDate = (current) =>
    (current && current.diff(moment(), "day") < -90) || current.diff(moment(), "day") > 30;

  const handleChangePage = (page) => {
    setParams({ ...params, page });
  };

  const fetListInvoice = async () => {
    const res = await invoiceApi.getList({ ...params, sort: sortParams });
    setListInvoices(res.items);
    setTotalInvoices(res.total_items);
  };

  useEffect(() => {
    fetListInvoice();
  }, [params, sortParams]);

  useEffect(() => {
    if (role === ROLE.SUB_ADMIN && listOptOfficesInvoice.length > 0) {
      form.setFieldsValue({ office_cd: listOptOfficesInvoice[0].label });
    }
  }, [listOptOfficesInvoice, role]);

  useEffect(() => {
    dispatch(getListOptOfficesAction());
  }, []);

  return (
    <div className="list-invoice-page bg-white">
      <Row className="header-invoice">
        <h1>請求書一覧</h1>
        <IconInfo className="info-icon" />
        <h2>各種お問い合わせは請求書記載のお問い合わせ先にお願い致します。</h2>
      </Row>
      <Divider height="2px" />
      <Form
        form={form}
        onFieldsChange={handleFilter}
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        autoComplete="off"
        className="invoice-filter-form"
      >
        <Row gutter={[16, 12]}>
          <InputFilter title="お客様名" placeholder="テキスト入力" name="customer_nm" />
          {role !== ROLE.SUB_ADMIN ? (
            <SelectFilter
              title="タカスエ営業所"
              placeholder=""
              name="office_cd"
              listOptions={listOptOfficesInvoice}
              width={240}
            />
          ) : (
            <InputFilter title="タカスエ営業所" placeholder="" name="office_cd" disabled />
          )}

          <div className="invoice-date-picker">
            <div className="mb-5">
              ご請求締日
              <span className="expiration-note">
                ※3ヶ月以上前の請求データはお問い合わせください
              </span>
            </div>
            <DatePicker name="billing_closing_day" disabledDate={disabledDate} />
          </div>
        </Row>
      </Form>
      <TableListInvoice
        page={params.page}
        onSortInvoices={handleSortInvoices}
        onChangePage={handleChangePage}
        dataSource={listInvoices}
        totalRow={totalInvoices}
        setListInvoices={setListInvoices}
      />
    </div>
  );
};

export default ListInvoice;
