import api from "./baseApi";

export const masterDataApi = {
  getDivDeliveryTime() {
    return api.get("/div/delivery-time");
  },
  getDivDeliveryVehicle() {
    return api.get("/div/delivery-vehicle");
  },
  getDivUnitQuantity() {
    return api.get("/div/quantity-category");
  },
  getDivUnitVolume() {
    return api.get("/div/volume-classification");
  },
  getDivStatus() {
    return api.get("/div/order-status");
  },
  getDivVehicleChapter() {
    return api.get("/div/charter-vehicle");
  },
  getUserTypes() {
    return api.get("/div/user-classification");
  },
  getOffices() {
    return api.get("/offices/dropdown");
  },
  getOperationPageOffices() {
    return api.get("/offices/dropdown/operation");
  },
  getOfficesInvoices() {
    return api.get("/invoices/dropdown");
  },
  getBillingAddresses(office_cd) {
    return api.get(`/billing_addresses/?office_cd=${office_cd}`);
  },
  getAddressJis(post_cd) {
    return api.get(`/address_jis/?post_cd=${post_cd}`);
  },
  getPickupAddress({ office_cd, billing_addr_cd }) {
    return api.get(
      `/pickup_addresses/?company_cd=10&office_cd=${office_cd}&billing_addr_cd=${billing_addr_cd}`
    );
  },
  getItems({ office_cd, pickup_addr_cd }) {
    return api.get(`/items/?office_cd=${office_cd}&pickup_addr_cd=${pickup_addr_cd}`);
  },
  getAuthGroups() {
    return api.get("/auth_groups/");
  },
  getListMainOfficeClient(params) {
    return api.get("/main_office_client/", { params });
  },
  getListTransportAddress(params) {
    return api.get("/transport_sys_billing_addresses/", { params });
  },
  getOfficesByFlag(flag) {
    if (flag === "all") {
      return api.get("/offices/");
    }
    return api.get(`/offices/?flg=${flag}`);
  },
  checkHoliday({ pickup_ymd, office_cd }) {
    return api.get(`/offices/${office_cd}/holiday/${pickup_ymd}`);
  },
  getDriver(params) {
    return api.get("/drivers/driver-pulldown", { params });
  },
  getServiceYmdDiv() {
    return api.get("/div/service-ymd");
  }
};
