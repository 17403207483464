import { Col, Form, Input, Row } from "antd";
import PropTypes from "prop-types";
import React from "react";

import Tag from "components/common/Tag";

FormAreaHorizon.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  helperText: PropTypes.string,
  optional: PropTypes.bool,
  form: PropTypes.any,
  disabled: PropTypes.bool,
  rules: PropTypes.array
};

function FormAreaHorizon({ label, name, placeholder, optional, disabled, rules }) {
  return (
    <Row className="form-item">
      <Col span={7}>
        <h1 className="label">
          {label}
          {optional && <Tag type="optional" />}
        </h1>
      </Col>
      <Col span={17}>
        <Form.Item rules={rules} name={name}>
          <Input.TextArea disabled={disabled} placeholder={placeholder} autoSize={{ minRows: 3 }} />
        </Form.Item>
      </Col>
    </Row>
  );
}

export default FormAreaHorizon;
