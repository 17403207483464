import React from "react";
import PropTypes from "prop-types";
import { Form, Input } from "antd";
import Tag from "components/common/Tag";

FormArea.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  helperText: PropTypes.string,
  optional: PropTypes.bool,
  form: PropTypes.any,
  disabled: PropTypes.bool,
  rules: PropTypes.array
};

function FormArea({ label, name, placeholder, optional, disabled, rules }) {
  return (
    <div className="form-item form-area">
      <h1 className="label">
        {label}
        {optional && <Tag type="optional" />}
      </h1>
      <Form.Item rules={rules} name={name}>
        <Input.TextArea disabled={disabled} placeholder={placeholder} autoSize={{ minRows: 3 }} />
      </Form.Item>
    </div>
  );
}

export default FormArea;
