import { Button, Col } from "antd";
import PropTypes from "prop-types";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { PATH_NAME, ROLE } from "common/constant";

import { flowAccountStore } from "store/slices/flowAccount";

const RightUpdate = ({
  currentPageFlag,
  toggleFormDone,
  isFormConfirm,
  setIsFormConfirm,
  setCurrentPageFlag,
  handleSaveDraft
}) => {
  const navigate = useNavigate();

  const { formData } = useSelector(flowAccountStore);
  const isDraft = formData?.save_flg === "1";
  const isAdmin = formData?.use_user_div === ROLE.ADMIN;

  const redirectEdit = () => {
    setIsFormConfirm && setIsFormConfirm(false);
    setCurrentPageFlag && setCurrentPageFlag("form");
  };

  const handleBack = () => {
    const haveClientPage = formData["main_office_div"] === "1" && isAdmin;
    switch (currentPageFlag) {
      case "trans": {
        if (haveClientPage) setCurrentPageFlag("client");
        else setCurrentPageFlag("form");
        break;
      }
      case "client": {
        setCurrentPageFlag("form");
        break;
      }
      default:
        break;
    }
  };

  const handleBtnSecondary = () => {
    if (isDraft) {
      handleSaveDraft();
    } else {
      navigate(PATH_NAME.ACCOUNT_LIST);
    }
  };

  const textBtnPri = isDraft ? "登録" : "変更";

  return (
    <Col span={6}>
      <div className="right-customer-account">
        <p>{isFormConfirm ? "内容を変更する場合は" : "内容の入力後"}</p>
        <p>{isFormConfirm ? `${textBtnPri}を押してください。` : "次へ進んでください。"}</p>
        <div>
          <Button onClick={toggleFormDone} className="btn-submit" type="primary" size="large">
            {isFormConfirm ? textBtnPri : "次へ"}
          </Button>
        </div>

        <Button onClick={handleBtnSecondary} className="btn-submit" size="large">
          {isDraft ? "下書き保存" : "キャンセル"}
        </Button>
        {isFormConfirm ? (
          <Button onClick={redirectEdit} type="link">
            内容を修正する
          </Button>
        ) : (
          currentPageFlag !== "form" &&
          currentPageFlag && (
            <Button onClick={handleBack} type="link">
              前のページへ移動する
            </Button>
          )
        )}
      </div>
    </Col>
  );
};
RightUpdate.propTypes = {
  currentPageFlag: PropTypes.string,
  toggleFormDone: PropTypes.func,
  isFormConfirm: PropTypes.bool,
  setIsFormConfirm: PropTypes.func,
  setCurrentPageFlag: PropTypes.func,
  handleSaveDraft: PropTypes.func
};
export default RightUpdate;
