import React, { useCallback, useEffect, useRef, useState } from "react";
import { any, array, func, number, string } from "prop-types";
import { Form, Input, Spin } from "antd";
import "./InputSearch.scss";

InputSearch.propTypes = {
  fetchOptions: func,
  debounceTimeout: number,
  name: string,
  setValue: func,
  value: string,
  form: any,
  hint: string,
  handleAfterSelect: func,
  rules: array
};

function InputSearch({
  form,
  name,
  fetchOptions,
  debounceTimeout = 500,
  setValue,
  value,
  hint = "過去に使用した納品先名称",
  handleAfterSelect,
  rules,
  ...props
}) {
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState([]);
  const [showOptions, setShowOptions] = useState(false);

  const firstRender = useRef(true);

  const handleOnChange = (e) => {
    setOptions([]);
    setValue(e.target.value);
    form && form.setFieldsValue({ [name]: e.target.value });
    setShowOptions(true);
    form.validateFields([name]);
  };

  const handleOnBlur = () => {
    setShowOptions(false);
  };

  const handleClickOption = (option) => {
    setValue(option?.label);
    form && form.setFieldsValue({ [name]: option?.label });
    setShowOptions(false);
    handleAfterSelect(option?.value);
  };

  const debounceFetcher = useCallback(() => {
    setFetching(true);
    fetchOptions(value).then((newOptions) => {
      if (newOptions.length > 0) setOptions(newOptions);
      setFetching(false);
      setShowOptions(true);
    });
  }, [value]);

  useEffect(() => {
    if (!firstRender.current && showOptions) {
      const timeOutId = setTimeout(() => debounceFetcher(), debounceTimeout);
      return () => clearTimeout(timeOutId);
    } else firstRender.current = false;
  }, [value]);

  return (
    <Form.Item rules={rules} name={name} className="input-search" onBlur={handleOnBlur}>
      <Input value={value} type="text" {...props} onChange={handleOnChange} />
      {fetching ? <Spin size="small" /> : null}
      {showOptions && options?.length > 0 && (
        <div className="content-search">
          {hint && <div className="hint">{hint}</div>}
          {options?.map((item) => (
            <div
              key={item?.value}
              onMouseDown={(e) => e.preventDefault()}
              onClick={() => handleClickOption(item)}
              className="item-search"
            >
              {item?.label}
            </div>
          ))}
        </div>
      )}
    </Form.Item>
  );
}
export default InputSearch;
