import { Col, Divider, Row, Spin } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { WORK_DIV } from "common/constant/service";
import { formatDateTime } from "common/utils/dateHelper";

import { serviceApi } from "services/service";

import ServiceDetailContent from "../components/ServiceDetailContent";
import ServiceDetailHeader from "../components/ServiceDetailHeader";
import ServiceMap from "../components/ServiceMap";
import "./DetailService.scss";

function DetailService() {
  const { serviceNo } = useParams();
  const [loading, setLoading] = useState(false);
  const [commonData, setCommonData] = useState();
  const [listCard, setListCard] = useState([]);
  const [roundNumOption, setRoundNumOption] = useState([]);
  const [roundNum, setRoundNum] = useState();
  const [highlighted, setHighlighted] = useState();
  const [dragData, setDragData] = useState();
  const [listInstructNo, setListInstructNo] = useState();

  useEffect(async () => {
    const data = await serviceApi.getDetailService(serviceNo);
    const { list_instruct, ...rest } = data;

    let distance = 0;
    list_instruct.forEach((instruct) => {
      instruct.service_acts
        .filter((act) => act.work_div)
        .forEach((act) => {
          distance += act.distance;
        });
    });
    let listCards = [];

    const getCardFromInstruct = (instruct) => {
      const {
        pickup_order,
        pickup_addr_nm,
        pickup_chart_cd,
        pickup_addr1,
        pickup_time,
        pickup_status,
        pickup_instruct,
        pickup_memo,
        pickup_information,
        pickup_latitude,
        pickup_longitude,
        service_acts,
        delivery_order,
        consignee_nm,
        consignee_chart_cd,
        consignee_addr,
        delivery_time,
        delivery_status,
        delivery_instruct,
        delivery_memo,
        consignee_information,
        consignee_latitude,
        consignee_longitude,
        service_instrct_no,
        ...commonInstruct
      } = instruct;

      if (pickup_order) {
        const loadCompleteAct = service_acts.find((act) => act.work_div === WORK_DIV.LOAD_COMPLETE);
        let updateTime;
        if (loadCompleteAct) {
          const updateTimeMinute =
            loadCompleteAct &&
            moment(loadCompleteAct.work_time).diff(moment(rest.service_ymd), "minutes");
          const updateHour = Math.floor(updateTimeMinute / 60);
          const updateMinute = updateTimeMinute - updateHour * 60;
          updateTime =
            updateHour.toString() +
            ":" +
            (updateMinute < 10 ? `0${updateMinute}` : updateMinute.toString());
        }
        const arrivalTime = service_acts.find(
          (act) => act.work_div === WORK_DIV.COL_ARRIVAL
        )?.work_time;

        const workStartTime = service_acts.find(
          (act) => act.work_div === WORK_DIV.LOAD_START
        )?.work_time;

        const workEndTime = service_acts.find(
          (act) => act.work_div === WORK_DIV.LOAD_COMPLETE
        )?.work_time;

        listCards.push({
          order: pickup_order,
          baseNm: pickup_addr_nm,
          chartCd: pickup_chart_cd,
          address: pickup_addr1,
          specifiedTime: pickup_time,
          deliveryStatus: pickup_status,
          updateTime,
          deliveryInstruct: pickup_instruct,
          arrivalTime,
          workStartTime,
          workEndTime,
          memo: pickup_memo,
          information: pickup_information,
          latitude: pickup_latitude,
          longitude: pickup_longitude,
          isLoad: true,
          base_cd: pickup_chart_cd,
          service_instrct_no,
          ...commonInstruct
        });
      }

      if (delivery_order) {
        const unLoadCompleteAct = service_acts.find(
          (act) => act.work_div === WORK_DIV.UNLOAD_COMPLETE
        );
        let updateTime;
        if (unLoadCompleteAct) {
          const updateTimeMinute =
            unLoadCompleteAct &&
            moment(unLoadCompleteAct.work_time).diff(moment(rest.service_ymd), "minutes");
          const updateHour = Math.floor(updateTimeMinute / 60);
          const updateMinute = updateTimeMinute - updateHour * 60;
          updateTime =
            updateHour.toString() +
            ":" +
            (updateMinute < 10 ? `0${updateMinute}` : updateMinute.toString());
        }
        const arrivalTime = service_acts.find(
          (act) => act.work_div === WORK_DIV.DELI_ARRIVAL
        )?.work_time;

        const workStartTime = service_acts.find(
          (act) => act.work_div === WORK_DIV.UNLOAD_START
        )?.work_time;

        const workEndTime = service_acts.find(
          (act) => act.work_div === WORK_DIV.UNLOAD_COMPLETE
        )?.work_time;

        listCards.push({
          order: delivery_order,
          baseNm: consignee_nm,
          chartCd: consignee_chart_cd,
          address: consignee_addr,
          specifiedTime: delivery_time,
          deliveryStatus: delivery_status,
          updateTime,
          deliveryInstruct: delivery_instruct,
          arrivalTime,
          workStartTime,
          workEndTime,
          memo: delivery_memo,
          information: consignee_information,
          latitude: consignee_latitude,
          longitude: consignee_longitude,
          isLoad: false,
          base_cd: consignee_chart_cd,
          service_instrct_no,
          ...commonInstruct
        });
      }
    };

    const listRoundNum = list_instruct?.map((intruct) => intruct.round_num);
    const listRoundNumOption = [...new Set(listRoundNum)].sort((a, b) => a - b);

    setRoundNumOption(
      [
        { value: 0, label: "全て" },
        ...listRoundNumOption.map((option) => ({
          value: option,
          label: `${option}回戦`
        }))
      ]
    );
    if (!roundNum) setRoundNum(0);

    list_instruct.forEach((item) => {
      getCardFromInstruct(item);
    });

    setCommonData({ ...rest, distance });
    setListCard(listCards.sort((a, b) => {
      if (a.round_num > b.round_num) return 1;
      if (a.round_num < b.round_num) return -1;
      if (a.order > b.order) return 1;
      if (a.order < b.order) return -1;
      return 0;
    }));
    setListInstructNo(list_instruct.filter(
      (instruct) => roundNum === 0 || instruct.round_num === roundNum));
  }, [roundNum, dragData]);

  return (
    <Spin size="large" spinning={loading} wrapperClassName="spin-loading">
      <div className="list-order-page bg-white">
        <Row className="header-order" align="middle" justify="space-between">
          <h1 className="fs-24 fw-700">運行詳細</h1>
        </Row>
        <Divider height="2px" />
        <Row justify="space-between" align="middle" className="my-20">
          <Col className="fw-700 fs-18">
            {formatDateTime(commonData?.service_ymd)} &nbsp;の運行情報
          </Col>
        </Row>
        <ServiceDetailHeader data={commonData} />
        <Row gutter={[20, 20]} className="mt-20" justify="space-between">
          <Col span={12}>
            <ServiceDetailContent
              listCard={listCard.filter((card) => roundNum === 0 || card.round_num === roundNum)}
              roundNumOption={roundNumOption}
              roundNum={roundNum}
              setRoundNum={setRoundNum}
              highlighted={highlighted}
              listInstructNo={listInstructNo}
              setDragData={setDragData}
              setLoading={setLoading}
              setHighlighted={setHighlighted}
            />
          </Col>
          <Col span={12}>
            <ServiceMap
              listCard={listCard.filter((card) => roundNum === 0 || card.round_num === roundNum)}
              roundNum={roundNum}
              highlighted={highlighted}
              setHighlighted={setHighlighted}
            />
          </Col>
        </Row>
      </div>
    </Spin>
  );
}

export default DetailService;
