import { Button } from "antd";
import { IconOrderSuccess } from "assets";
import { PATH_NAME } from "common/constant";
import { bool } from "prop-types";
import React from "react";
import { useNavigate } from "react-router-dom";
import "./CreateOrderSuccess.scss";

CreateOrderSuccess.propTypes = {
  isUpdate: bool
};

function CreateOrderSuccess({ isUpdate }) {
  const navigate = useNavigate();

  return (
    <div className="order-success">
      <IconOrderSuccess />
      <h1>{isUpdate ? "配送依頼を変更しました。" : "配送依頼が完了しました。"}</h1>
      <Button
        onClick={() => navigate(PATH_NAME.ORDER_LIST)}
        size="large"
        style={{ width: "280px" }}
      >
        配送一覧に戻る
      </Button>
    </div>
  );
}

export default CreateOrderSuccess;
