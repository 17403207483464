import { Button, Row, Upload } from "antd";
import { IconCSV, IconError } from "assets";
import Divider from "components/common/Divider";
import React from "react";
import { useState } from "react";
import { orderApi } from "services/order";
import ModalUploadCSV from "../components/ModalUploadCSV";
import "./CaptureOrderCSV.scss";

function CaptureOrderCSV() {
  const [fileList, setFileList] = useState([]);
  const [percentProcess, setPercentProcess] = useState(0);
  const [showModalUpload, setShowModalUpload] = useState(false);
  const [importSuccess, setImportSuccess] = useState(false);

  const [reasonUploadFail, setReasonUploadFail] = useState();

  const configDragger = {
    name: "file",
    accept: ".csv",
    onChange({ file }) {
      // you store them in state, so that you can make a http req with them later
      setFileList([file]);
      setReasonUploadFail();
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
      setReasonUploadFail();
    },
    beforeUpload: () => false,
    fileList,
    showUploadList: false
  };

  const handleUpload = async (event) => {
    event.preventDefault();
    setShowModalUpload(true);
    let formData = new FormData();
    formData.append("file", fileList[0]);

    await orderApi
      .importCSV(formData, {
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setPercentProcess(percentCompleted);
        }
      })
      .then((res) => {
        if (!res.success) {
          const listErr = res?.errors?.map(({ error, row }) => ({
            error,
            row
          }));
          setReasonUploadFail(listErr?.slice(0, 5));
          setShowModalUpload(false);
        } else {
          setImportSuccess(true);
          setReasonUploadFail();
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const handleCloseModal = () => {
    setShowModalUpload(false);
    setPercentProcess(0);
    setImportSuccess(false);
  };

  return (
    <div className="csv-capture bg-white">
      {showModalUpload && (
        <ModalUploadCSV
          isShowModal={showModalUpload}
          handleCancel={handleCloseModal}
          percentProcess={percentProcess}
          importSuccess={importSuccess}
        />
      )}
      <div className="header">
        <h1>CSVファイル取り込み</h1>
        <Divider height="2px" />
      </div>
      <div className="hint">
        配送依頼内容をCSVファイル形式で取り込むことで情報入力を効率化することができます。
      </div>
      <div className="hint">
        対象のCSVファイルをドラッグ＆ドロップでアップロードし、「ファイル取り込み」をクリックしてください。
      </div>
      {reasonUploadFail?.length > 0 && (
        <div className="upload-fail">
          {reasonUploadFail?.map(({ error, row }, key) => (
            <Row key={key} className="reason">
              <IconError />
              {row ? `${row}行目：${error}` : error}
            </Row>
          ))}
        </div>
      )}
      <div className="upload-csv form-item">
        <Upload.Dragger className="order-upload-dragger" {...configDragger}>
          <p className="ant-upload-drag-icon">
            <IconCSV />
          </p>
          {fileList.length > 0 ? (
            <>
              <p className="upload-text">
                {fileList[0].name}
                <br />
                <span>ファイルを再アップロード</span>
              </p>
            </>
          ) : (
            <>
              <p className="upload-text">
                ここにドラッグ＆ドロップ
                <br />
                または<span>ファイルを選択</span>
              </p>
            </>
          )}

          <div className="flex-center">
            <div className="upload-hint">※ csvファイルのみアップロード可能</div>
          </div>
        </Upload.Dragger>
      </div>
      <Button
        type="primary"
        className="btn-upload"
        size="large"
        onClick={handleUpload}
        disabled={fileList.length === 0}
      >
        ファイル取り込み
      </Button>
    </div>
  );
}

export default CaptureOrderCSV;
