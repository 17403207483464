import React from "react";
import { Alert as AlertAntd } from "antd";
import "./Alert.scss";
import { IconSuccess } from "assets";
import { IconClose } from "assets";
import { useSelector } from "react-redux";
import { alertStore } from "store/slices/alert";

function Alert(props) {
  const { message, type } = useSelector(alertStore);
  return (
    <div className={`my-alert ${message && "show"}`}>
      <AlertAntd
        icon={<IconSuccess />}
        {...props}
        closable
        closeIcon={<IconClose />}
        showIcon
        message={message}
        type={type}
      />
    </div>
  );
}

export default Alert;
