import { Button, Col, Image } from "antd";
import { IconDownload } from "assets";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import { saveFileWithExactName } from "common/utils/base64Helper";

import { serviceApi } from "services/service";

import { isImage } from "../../../common/utils/imageHelper";
import "./ItemImageView.scss";

ItemImgView.propTypes = {
  url: PropTypes.string,
  deletable: PropTypes.bool,
  onDelete: PropTypes.func,
  fullView: PropTypes.bool,
  className: PropTypes.string,
  id: PropTypes.string
};

function ItemImgView({ url, fullView, className, id }) {
  const [isValidImage, setIsValidImage] = useState(false);

  useEffect(() => {
    isImage(url).then((result) => {
      setIsValidImage(result);
    });
  }, []);

  const onDownloadImg = async () => {
    const res = await serviceApi.downloadSignReceiptImage(id);
    saveFileWithExactName(res);
  };

  return isValidImage ? (
    <Col span={12} className="image-item-view pos-relative">
      <div className="rounded-4 overflow-hidden">
        <Image
          height={180}
          src={url}
          alt={url}
          className="w-full h-full"
          rootClassName={`${fullView ? "" : "image-preview"} ${className}`}
          preview={{
            mask: false
          }}
        />
      </div>

      <Button
        onClick={onDownloadImg}
        className="pos-absolute primary fw-500 fs-12 top-5 right-10 dis-flex ai-center bg-white"
        size="small"
        type="text"
      >
        ダウンロード <IconDownload />
      </Button>
    </Col>
  ) : null;
}

export default ItemImgView;
