import React from "react";
import PropTypes from "prop-types";
import { Col, Form, Radio, Row, Tag } from "antd";

FormRadioGroup.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  listOptions: PropTypes.array,
  optional: PropTypes.bool,
  form: PropTypes.any,
  rules: PropTypes.array,
  disabled: PropTypes.bool
};

function FormRadioGroup({ label, name, listOptions, optional, rules, disabled }) {
  return (
    <Row className="form-item form-radio-group">
      <Col span={7} className="label">
        <h1 className="label">{label}</h1>
      </Col>
      <Col>
        <Form.Item name={name} rules={rules}>
          <Radio.Group disabled={disabled}>
            {listOptions?.map(({ value, label }) => (
              <Radio key={value} value={value}>
                {label}
              </Radio>
            ))}
          </Radio.Group>
        </Form.Item>
        {optional && <Tag type="optional" />}
      </Col>
    </Row>
  );
}

export default FormRadioGroup;
