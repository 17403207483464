import { Form, Modal, Upload } from "antd";
import { IconUpload } from "assets";
import { saveAs } from "file-saver";
import PropTypes from "prop-types";
import React, { useState } from "react";

import { generateBase64BlobURL, getBase64 } from "common/utils/base64Helper";

UploadDragger.propTypes = {
  name: PropTypes.string,
  setStateFileList: PropTypes.func,
  stateFileList: PropTypes.array,
  defaultFileList: PropTypes.array
};

function UploadDragger({ name, stateFileList, setStateFileList, defaultFileList }) {
  const [filePreview, setFilePreview] = useState({
    type: "",
    base64Blob: ""
  });

  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setFilePreview({
      type: "",
      base64Blob: ""
    });
    setIsModalVisible(false);
  };

  const onPreview = async (file) => {
    const parseFileName = file?.name?.split(".");
    const type = parseFileName?.at(-1);
    if (file?.originFileObj) {
      const srcBase64 = await getBase64(file.originFileObj);
      const base64Blob = generateBase64BlobURL(srcBase64.replace("data:", "").replace(/^.+,/, ""));
      setFilePreview({
        type,
        base64Blob
      });
      if (type === "xlsx" || type === "xls") {
        saveAs(base64Blob, file?.name);
      } else showModal();
    } else {
      setFilePreview({
        type,
        base64Blob: file?.url
      });
      if (type === "xlsx" || type === "xls") {
        saveAs(file?.url, file?.name);
      } else showModal();
    }
  };

  const configDragger = {
    name: "file",
    multiple: true,
    maxCount: 10,
    beforeUpload(file) {
      const parseFileName = file?.name?.split(".");
      const type = parseFileName?.at(-1);
      const whitelistType = ["xlsx", "xls", "png", "jpeg", "pdf", "jpg"];
      return whitelistType.includes(type) ? false : Upload.LIST_IGNORE;
    },
    onChange({ fileList }) {
      if (fileList && setStateFileList) setStateFileList(fileList);
    }
  };
  const getFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList.map((item) => item?.originFileObj || item);
  };

  return (
    <div className="form-item">
      {isModalVisible && (
        <>
          <Modal
            width={976}
            visible={isModalVisible}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={null}
            className="preview-file"
          >
            {filePreview.type === "pdf" ? (
              <iframe title="TestPDF" width="100%" height="700px" src={filePreview.base64Blob} />
            ) : (
              <img width="100%" height="auto" src={filePreview.base64Blob} />
            )}
          </Modal>
        </>
      )}
      <h1 className="label">添付資料</h1>
      <Form.Item name={name} getValueFromEvent={getFile}>
        <Upload.Dragger
          defaultFileList={defaultFileList}
          onPreview={onPreview}
          showUploadList={{ showPreviewIcon: true }}
          className="order-upload-dragger"
          fileList={stateFileList && stateFileList}
          {...configDragger}
        >
          <p className="ant-upload-drag-icon">
            <IconUpload />
          </p>
          <p className="upload-text">
            ここにドラッグ＆ドロップ
            <br />
            または<span>ファイルを選択</span>
          </p>
          <div className="flex-center">
            <div className="upload-hint">
              ※ PDF、JPEG、PNG、Excelのみアップロード可能
              <br />※ 一度に10個までアップロード可能です
            </div>
          </div>
        </Upload.Dragger>
      </Form.Item>
    </div>
  );
}

export default UploadDragger;
