import { Form } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import { baseApi } from "services/base";

import { setAlertAction } from "store/slices/alert";
import { authStore } from "store/slices/auth";

import FormBaseDetail from "../components/FormBaseDetail";

import Divider from "components/common/Divider";

function UpdateBase() {
  const dispatch = useDispatch();
  const { baseId: chart_cd } = useParams();
  const { company_cd } = useSelector(authStore);
  const [detailBase, setDetailBase] = useState();
  const [form] = Form.useForm();

  const fetchDetailBase = async () => {
    await baseApi
      .getDetailBase({
        company_cd,
        chart_cd
      })
      .then((res) => {
        setDetailBase(res);
        if (res === null)
          dispatch(
            setAlertAction({
              message: "お客様カルテコードが登録されていません",
              type: "error"
            })
          );
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    if (company_cd && chart_cd) {
      fetchDetailBase();
    }
  }, [company_cd, chart_cd]);

  return (
    <>
      {detailBase ? (
        <div className="bg-white py-32 px-24">
          <h1 className="fs-24 fw-700">{detailBase?.name}</h1>
          <Divider height="2px" />
          <FormBaseDetail refetch={fetchDetailBase} dataSource={detailBase} form={form} />
        </div>
      ) : null}
    </>
  );
}

export default UpdateBase;
