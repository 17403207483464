import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Col, Form, Checkbox, Row } from "antd";
import Tag from "components/common/Tag";

FormCheckboxGroup.propTypes = {
  groupName: PropTypes.string,
  label: PropTypes.string,
  listOptions: PropTypes.array,
  isInitValues: PropTypes.bool,
  optional: PropTypes.bool,
  form: PropTypes.any,
  noticeMsg: PropTypes.string,
  rules: PropTypes.array,
  fixedItems: PropTypes.array
};

function FormCheckboxGroup({
  groupName,
  label,
  listOptions,
  optional,
  form,
  isInitValues,
  noticeMsg,
  rules,
  fixedItems
}) {
  const [checked, setChecked] = useState({});

  useEffect(() => {
    let temp = {};
    listOptions?.forEach(({ name }) => {
      temp[name] = form?.getFieldValue(name);
      if (!form?.getFieldValue(name)) form.setFieldsValue({ [name]: "0" });
    });
    setChecked(temp);
  }, [listOptions, isInitValues]);

  const getFile = (e) => {
    form.validateFields([groupName]);
    if (e.target.value === "1") {
      setChecked({ ...checked, [e.target.id]: "0" });
      return "0";
    }
    setChecked({ ...checked, [e.target.id]: "1" });
    return "1";
  };

  return (
    <Row className="form-item form-radio-group">
      <Col span={7} className="label">
        <h1 className="label">{label}</h1>
      </Col>
      <Col>
        {noticeMsg && <p className="notice-msg">{noticeMsg}</p>}
        <Form.Item name={groupName} rules={rules}>
          <Row>
            {listOptions?.map(({ name, label }) => (
              <Form.Item key={name} name={name} getValueFromEvent={getFile}>
                <Checkbox
                  disabled={fixedItems?.includes(name)}
                  checked={checked[name] === "1" || form.getFieldValue(name) === "1"}
                >
                  {label}
                </Checkbox>
              </Form.Item>
            ))}
          </Row>
          {optional && <Tag type="optional" />}
        </Form.Item>
      </Col>
    </Row>
  );
}

export default FormCheckboxGroup;
