import React from "react";
import { bool, func } from "prop-types";
import { Button, Modal, Row } from "antd";

ModalValidateFail.propTypes = {
  setValidateFail: func,
  validateFail: bool
};

function ModalValidateFail({ setValidateFail, validateFail }) {
  const handleOk = () => {
    setValidateFail(false);
  };

  return (
    validateFail && (
      <Modal
        className="custom-modal"
        visible={validateFail}
        onOk={handleOk}
        onCancel={handleOk}
        footer={null}
      >
        <h1 style={{ marginBottom: "20px", color: "#C53F3F" }}>入力情報にエラーがあります</h1>
        <Row justify="center">
          <Button danger onClick={handleOk} className="btn-action" type="danger" size="large">
            はい
          </Button>
        </Row>
      </Modal>
    )
  );
}

export default ModalValidateFail;
