import { Col, Row } from "antd";
import { IconArrowRight } from "assets";
import React from "react";
import { useNavigate } from "react-router-dom";

import { PATH_NAME } from "common/constant";

import "./ListMaster.scss";

import Divider from "components/common/Divider";

function ListMaster() {
  const navigate = useNavigate();
  const listMaster = [{ name: "お客様カルテ", url: PATH_NAME.BASE_LIST }];
  return (
    <div className="h-full bg-white pa-32">
      <h1 className="fs-24 fw-700 mb-16">マスタ</h1>
      <Divider height="2px" />
      <Row gutter={[24]} className="mt-32">
        {listMaster.map((item) => (
          <Col
            span={6}
            key={item.name}
            onClick={() => navigate(item.url)}
            className="cursor-pointer"
          >
            <Row className="master pl-32" align="middle">
              <Col span={20} className="fs-18 fw-700 link">
                {item.name}
              </Col>
              <Col span={4} className="h-32">
                <IconArrowRight />
              </Col>
            </Row>
          </Col>
        ))}
      </Row>
    </div>
  );
}

export default ListMaster;
