import React from "react";
import PropTypes from "prop-types";
import { Button, Col } from "antd";
import { IconPhone } from "assets";
import TableContent from "./TableContent";
import "./RightOrder.scss";

RightCreateOrder.propTypes = {
  isConfirm: PropTypes.bool,
  loading: PropTypes.bool,
  toggleConfirmMode: PropTypes.func,
  handleSubmit: PropTypes.func,
  inforOffice: PropTypes.object
};

function RightCreateOrder({ isConfirm, loading, toggleConfirmMode, handleSubmit, inforOffice }) {
  return (
    <Col span={6} className="right-create-order">
      <TableContent />
      <div className="hint">
        {isConfirm
          ? "内容の確認後\n注文ボタンを押してください"
          : "内容の入力後\n確認ボタンを押してください"}
      </div>
      {isConfirm ? (
        <>
          <Button loading={loading} type="primary" size="large" onClick={handleSubmit}>
            決定
          </Button>
          <Button style={{ marginTop: "8px" }} size="large" onClick={toggleConfirmMode}>
            内容を修正
          </Button>
        </>
      ) : (
        <Button type="primary" size="large" onClick={toggleConfirmMode}>
          入力内容確認
        </Button>
      )}
      {inforOffice && (
        <div className="infor-office">
          <div className="phone">
            <IconPhone />
            {inforOffice?.tel}
          </div>
          <p>{inforOffice?.label}</p>
          <span>
            その他ご要望については、詳細情報を添付いただくか、弊社まで直接お電話ください。
          </span>
        </div>
      )}
    </Col>
  );
}

export default RightCreateOrder;
