import cache from "./cache";

export default function cacheInforDelivery(params) {
  const {
    delivery_addr1,
    delivery_addr2,
    delivery_addr3,
    delivery_addr_nm,
    delivery_addr_postcd,
    delivery_addr_tel
  } = params;
  const listInforDelivery = cache.getJson("listInforDelivery") || [];
  const listDeliveryName = listInforDelivery.map((e) => e.delivery_addr_nm);
  const inforDelivery = {
    delivery_addr1,
    delivery_addr2,
    delivery_addr3,
    delivery_addr_nm,
    delivery_addr_postcd,
    delivery_addr_tel
  };

  if (!listDeliveryName.includes(inforDelivery.delivery_addr_nm))
    listInforDelivery.push(inforDelivery);
  cache.setJson("listInforDelivery", listInforDelivery);
}
