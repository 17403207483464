import { Button } from "antd";
import PropTypes from "prop-types";
import React from "react";

import "./FooterOrder.scss";

FooterCreateOrder.propTypes = {
  isConfirm: PropTypes.bool,
  toggleConfirmMode: PropTypes.func,
  handleSubmit: PropTypes.func
};

function FooterCreateOrder({ isConfirm, toggleConfirmMode, handleSubmit }) {
  return (
    <div className="footer-order bg-white">
      {isConfirm ? (
        <>
          <div className="hint">内容の確認後、決定ボタンを押してください</div>
          <div>
            <Button onClick={toggleConfirmMode} className="btn-submit" size="large">
              内容を修正
            </Button>
            <Button onClick={handleSubmit} className="btn-submit" type="primary" size="large">
              決定
            </Button>
          </div>
        </>
      ) : (
        <>
          <div className="hint">内容の入力後、確認ボタンを押してください</div>
          <Button onClick={toggleConfirmMode} className="btn-submit" type="primary" size="large">
            入力内容確認
          </Button>
        </>
      )}
    </div>
  );
}

export default FooterCreateOrder;
