import { Form, Row } from "antd";
import { string } from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { PATH_NAME } from "common/constant";
import { genOrderFormData } from "common/utils/orderHelper";

import { orderApi } from "services/order";

import { pulldownStore } from "store/slices/pulldown";
import { getAllFlexPullDownAction } from "store/slices/pulldown";

import {
  CreateOrderSuccess,
  FooterCreateOrder,
  FormOrderDetail,
  HeaderCreateOrder,
  InforOrderDetail,
  ModalValidateFail,
  RightCreateOrder
} from "../components";
import "./CreateOrder.scss";

CloneOrder.propTypes = {
  orderId: string
};

function CloneOrder({ orderId }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [form] = Form.useForm();
  const [isConfirm, setIsConfirm] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [values, setValues] = useState();
  const [initialValues, setInitialValues] = useState();
  const [isInitValues, setIsInitValues] = useState(false);
  const [stateFileList, setStateFileList] = useState();
  const [inforOffice, setInforOffice] = useState();
  const [validateFail, setValidateFail] = useState(false);
  const [loading, setLoading] = useState(false);
  const [postCode, setPostCode] = useState({
    delivery: "",
    pickup: ""
  });
  const [typeOrder, setTypeOrder] = useState("tlot"); // tlot || chapter

  const canResetFlag = useRef(false);

  const { listOptOffices, listPickupAddr } = useSelector(pulldownStore);

  const handleSubmitForm = (params) => {
    const { delivery_addr1, pickup_addr1 } = params;
    const { office_cd, billing_addr_cd } = initialValues;
    setValues({
      office_cd,
      billing_addr_cd,
      ...params,
      delivery_addr1: `${postCode.delivery} ${delivery_addr1}`,
      pickup_addr1: `${postCode.pickup} ${pickup_addr1}`
    });
  };

  const toggleConfirmMode = async () => {
    await form
      .validateFields()
      .then(() => {
        if (!isConfirm) {
          form.submit();
          if (!form.getFieldValue("pickup_addr_cd")) {
            form.setFieldsValue({ pickup_addr_cd: "000000000" });
          }
        }
        setIsConfirm(!isConfirm);
      })
      .catch((e) => {
        console.log("error", e);
        setValidateFail(true);
      });
  };

  const handleSubmitConfirm = async () => {
    setLoading(true);
    const formData = genOrderFormData(values, listPickupAddr, typeOrder);
    await orderApi
      .create(formData)
      .then(() => {
        setIsSuccess(true);
      })
      .catch((e) => console.log(e));
    setLoading(false);
  };

  const fetchDataOrder = async () => {
    await orderApi
      .getDataById(orderId)
      .then((response) => {
        const { pickup_address, ...dataOrder } = response.order;
        const dataInitOrder = {
          ...dataOrder,
          ...pickup_address,
          delivery_ymd: null,
          pickup_ymd: null,
          order_state: ""
        };
        setValues(dataInitOrder);
        form.setFieldsValue(dataInitOrder);
        setIsInitValues(true);
        setInitialValues(dataInitOrder);
        
        if (response?.order?.order_div === "02") {
          setTypeOrder("chapter");
        }
        canResetFlag.current = true;
      })
      .catch(() => navigate(PATH_NAME.ORDER_LIST));
  };

  useEffect(async () => {
    if (listOptOffices && values) {
      setInforOffice(listOptOffices?.find((e) => e.value === values?.office_cd));
    }
  }, [listOptOffices, values]);

  useEffect(() => {
    if (values) {
      dispatch(getAllFlexPullDownAction(values));
    }
  }, [values]);

  useEffect(async () => {
    // call api
    fetchDataOrder();
  }, []);

  useEffect(() => {
    if (canResetFlag.current) {
      form.setFieldsValue({
        delivery_time_div: "01",
        delivery_vehicle_type: "01",
        stack_feasible_flg: "1",
        item_cd: null,
        quantity: null,
        quantity_div: null,
        volume: null,
        volume_div: null,
        weight: null,
        packing_sepa: null,
        packing_pl: null,
        packing_lo: null,
        packing_variant: null
      });
    }
  }, [typeOrder]);

  return (
    <div id="create-order-page">
      <ModalValidateFail setValidateFail={setValidateFail} validateFail={validateFail} />
      {isSuccess ? (
        <div className="content bg-white">
          <CreateOrderSuccess />
        </div>
      ) : (
        <>
          <div className="content bg-white">
            <HeaderCreateOrder
              isConfirm={isConfirm}
              typeOrder={typeOrder}
              setTypeOrder={setTypeOrder}
            />
            <Row justify={"space-between"}>
              {isConfirm ? (
                <InforOrderDetail values={values} typeOrder={typeOrder} />
              ) : (
                <FormOrderDetail
                  initialValues={values}
                  isInitValues={isInitValues}
                  setValues={setValues}
                  form={form}
                  handleSubmit={handleSubmitForm}
                  stateFileList={stateFileList}
                  setStateFileList={setStateFileList}
                  setInforOffice={setInforOffice}
                  setPostCode={setPostCode}
                  typeOrder={typeOrder}
                />
              )}
              <RightCreateOrder
                isConfirm={isConfirm}
                toggleConfirmMode={toggleConfirmMode}
                handleSubmit={handleSubmitConfirm}
                inforOffice={inforOffice}
                loading={loading}
              />
            </Row>
          </div>
          <FooterCreateOrder
            isConfirm={isConfirm}
            toggleConfirmMode={toggleConfirmMode}
            handleSubmit={handleSubmitConfirm}
          />
        </>
      )}
    </div>
  );
}

export default CloneOrder;
