import { Modal } from "antd";
import { IconDownload, IconExport } from "assets";
import { saveAs } from "file-saver";
import PropTypes from "prop-types";
import React, { useState } from "react";

import { generateBase64BlobURL, getBase64 } from "common/utils/base64Helper";

import "./LinkUpload.scss";

LinkUpload.propTypes = {
  file: PropTypes.any
};

function LinkUpload({ file }) {
  const [filePreview, setFilePreview] = useState({
    type: "",
    base64Blob: ""
  });
  const [isModalVisible, setIsModalVisible] = useState(false);
  const showModal = () => {
    setIsModalVisible(true);
  };

  const parseFileName = file?.name?.split(".");
  const type = parseFileName?.at(-1);

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setFilePreview({
      type: "",
      base64Blob: ""
    });
    setIsModalVisible(false);
  };

  const handlePreview = async () => {
    if (file?.type) {
      const srcBase64 = await getBase64(file);
      const base64Blob = generateBase64BlobURL(srcBase64.replace("data:", "").replace(/^.+,/, ""));
      setFilePreview({
        type,
        base64Blob
      });
      if (type === "xlsx" || type === "xls") {
        saveAs(base64Blob, file?.name);
      } else showModal();
    } else {
      setFilePreview({
        type,
        base64Blob: file?.url
      });
      if (type === "xlsx" || type === "xls") {
        saveAs(file?.url, file?.name);
      } else showModal();
    }
  };

  return (
    <>
      {isModalVisible && (
        <>
          <Modal
            width={976}
            visible={isModalVisible}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={null}
            className="preview-file"
          >
            {filePreview.type === "pdf" ? (
              <iframe title="TestPDF" width="100%" height="700px" src={filePreview.base64Blob} />
            ) : (
              <img width="100%" height="auto" src={filePreview.base64Blob} />
            )}
          </Modal>
        </>
      )}
      <div onClick={handlePreview} className="link-upload">
        <span className="file-name">{file?.name}</span>
        {type === "xlsx" || type === "xls" ? <IconDownload /> : <IconExport />}
      </div>
    </>
  );
}

export default LinkUpload;
