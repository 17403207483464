import React, { useState } from "react";
import { Typography, Modal, Form, Input, Button, Row, Col, Card } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { authStore } from "store/slices/auth";
import { resetPassword } from "store/slices/auth";
import { setFailResetPwd } from "store/slices/auth";

function ForgotPassword() {
  const [isMailModalVisible, setIsModalVisible] = useState(false);
  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
  const { Text, Title } = Typography;
  const { errorResetPwd, loadingResetPassword } = useSelector(authStore);
  const dispatch = useDispatch();
  const showModalMail = () => {
    setIsModalVisible(true);
    setIsConfirmModalVisible(false);
  };
  const handleMailOk = () => {
    setIsModalVisible(false);
  };
  const handleMailCancel = () => {
    setIsModalVisible(false);
    dispatch(setFailResetPwd(null));
  };
  const handleSendMail = async ({ mail }) => {
    // Call API send mail
    try {
      await dispatch(resetPassword({ mail_addr: mail }));
      setIsModalVisible(false);
      setIsConfirmModalVisible(true);
    } catch (error) {
      dispatch(setFailResetPwd(error?.response?.data?.detail));
    }
  };
  const handleConfirmOk = () => {
    setIsConfirmModalVisible(false);
  };
  const handleConfirmCancel = () => {
    setIsConfirmModalVisible(false);
  };
  return (
    <>
      <Text type="primary" className="forgot-pw-link" onClick={showModalMail}>
        パスワードをお忘れの場合
      </Text>
      {isMailModalVisible && (
        <Modal
          transitionName=""
          maskTransitionName=""
          className="send-mail-dialog"
          centered
          title={null}
          visible={isMailModalVisible}
          onOk={handleMailOk}
          onCancel={handleMailCancel}
          footer={null}
        >
          <div>
            <Title level={3}>パスワード再発行</Title>
            <p className="forgot-pw-des">
              メールアドレスを入力のうえ、送信ボタンを押してください。
            </p>
            <p className="forgot-pw-des2">パスワード再発行用URLを記載したメールをお送りします。</p>
            <Form onFinish={handleSendMail} autoComplete="off">
              {errorResetPwd && (
                <Title level={5} type="danger" style={{ textAlign: "left" }}>
                  このメールアドレスのアカウントは存在しません
                </Title>
              )}
              <Form.Item
                name="mail"
                rules={[
                  { required: true, message: "メールアドレスは必須入力です" },
                  { type: "email", message: "メールアドレスの入力が正しくありません" }
                ]}
                style={{ marginBottom: "24px" }}
              >
                <Input
                  placeholder="メールアドレスを入力してください"
                  className="login-input"
                  size="large"
                  variant="outlined"
                  status={errorResetPwd && "error"}
                />
              </Form.Item>
              <Row justify="center">
                <Col span={16}>
                  <Form.Item>
                    <Button
                      loading={loadingResetPassword}
                      type="primary"
                      htmlType="submit"
                      size="large"
                      block
                    >
                      送信
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </div>
        </Modal>
      )}
      {isConfirmModalVisible && (
        <Modal
          transitionName=""
          maskTransitionName=""
          className="confirm-dialog"
          centered
          title={null}
          visible={isConfirmModalVisible}
          onOk={handleConfirmOk}
          onCancel={handleConfirmCancel}
          footer={null}
        >
          <div className="confirm-dialog-body">
            <Title level={3}>パスワード再発行</Title>
            <p>パスワード変更用のメールを送信しました</p>
            <Card style={{ width: 440 }}>
              <Text strong>メールが届かない場合</Text>
              <ul>
                <li>
                  迷惑メールに振り分けられていたり、フィルターや転送設定によって受信ボックス以外の場所に保管されていないかご確認ください。
                </li>
                <li>
                  メール送信に時間がかかる場合がございます。数分待った上で、メールが届いているか再度ご確認ください。
                </li>
                <li>
                  ご使用のメールアドレスが正しいかどうか確認してください。正しくない場合はメールアドレスの再設定をお願いします。
                </li>
              </ul>
            </Card>
            <Row justify="center">
              <Col span={16}>
                <Button type="primary" onClick={handleConfirmCancel} size="large" block>
                  ログイン画面に戻る
                </Button>
                <Text type="primary" className="forgot-pw-link" onClick={showModalMail}>
                  メールを再送信
                </Text>
              </Col>
            </Row>
          </div>
        </Modal>
      )}
    </>
  );
}

export default ForgotPassword;
