import Icon from "@ant-design/icons/lib/components/Icon";
import { Button, Col, Form, Row, Space } from "antd";
import { IconFilterDown, IconPlus, IconTrash } from "assets";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { PATH_NAME, PER_ACCESS_PAGES, ROLE, ADMIN_ROLE } from "common/constant";
import { formatFilterDate } from "common/utils/dateHelper";

import { orderApi } from "services/order";

import { authStore } from "store/slices/auth";
import { setFilterOrderAction } from "store/slices/listDataOrder";
import { listFilterOrderStore } from "store/slices/listDataOrder";
import { getListOptOfficesAction, pulldownStore } from "store/slices/pulldown";
import { searchCardStore, setIsOrderCardExpanded } from "store/slices/searchCard";

import TableListOrder from "../components/TableListOrder";
import "./ListOrder.scss";

import Divider from "components/common/Divider";
import Modal from "components/common/Modal";
import DatepickerFilter from "components/filter/DatepickerFilter";
import InputFilter from "components/filter/InputFilter";
import SelectFilter from "components/filter/SelectFilter";

function ListOrder() {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { role, company_cd, per } = useSelector(authStore);
  const { listOptStatus, listOptDeliTime, listOptOffices } = useSelector(pulldownStore);
  const { isOrderCardExpanded } = useSelector(searchCardStore);

  const [showMoreFilter, setShowMoreFilter] = useState(false);
  const [listRecordSelected, setListRecordSelected] = useState([]);
  const [isShowModalDelete, setIsShowModalDelete] = useState(false);
  const [isExportSuccess, setIsExportSuccess] = useState(false);
  const [listOrders, setListOrders] = useState([]);
  const [totalOrders, setTotalOrders] = useState();
  const { filterOrderObject } = useSelector(listFilterOrderStore);
  const [page, setPage] = useState(1);
  const [sortParams, setSortParams] = useState([]);

  const initialValues = {
    ...filterOrderObject,
    ...(filterOrderObject.pi_from
      ? { pi_from: moment(filterOrderObject.pi_from) }
      : {}),
    ...(filterOrderObject.pi_to
      ? { pi_to: moment(filterOrderObject.pi_to) }
      : {}),
    ...(filterOrderObject.de_from
      ? { de_from: moment(filterOrderObject.de_from) }
      : {}),
    ...(filterOrderObject.de_to
      ? { de_to: moment(filterOrderObject.de_to) }
      : {}),
  };

  const handleShowMore = () => {
    setShowMoreFilter(!showMoreFilter);
    dispatch(setIsOrderCardExpanded(!showMoreFilter));
  };

  const handleClickBtnDelete = () => {
    setIsShowModalDelete(true);
  };
  const handleDeleteRecord = async () => {
    try {
      const listOrderToDelete = listRecordSelected.map((record) => record.order_no);
      await orderApi.delete(listOrderToDelete);
      setPage(1);
      setListRecordSelected([]);
    } catch (error) {
      console.log(error);
    }
  };

  const handleFilter = (changedFields) => {
    if (changedFields[0].value) {
      if (["de_from", "de_to", "pi_from", "pi_to"].includes(changedFields[0].name[0])) {
        dispatch(
          setFilterOrderAction({
            ...filterOrderObject,
            [changedFields[0].name[0]]: formatFilterDate(changedFields[0]?.value?._d),
          })
        );
      } else {
        dispatch(
          setFilterOrderAction({
            ...filterOrderObject,
            [changedFields[0].name[0]]: changedFields[0].value,
          })
        );
      }
    } else {
      const cloneFilter = { ...filterOrderObject };
      delete cloneFilter[changedFields[0].name[0]];
      dispatch(setFilterOrderAction({ ...cloneFilter }));
    }
  };

  const handleChangePage = (currentPage) => {
    setPage(currentPage);
  };

  const disabledDate = (current) =>
    current && (current.diff(moment(), "day") < -90 || current.diff(moment(), "day") > 90);

  const handleSortOrders = (pagination, _, sorter) => {
    if (pagination.current === page) {
      if (sorter.length > 1) {
        setSortParams(
          sorter.map((item) => (item.order === "descend" ? `-${item.field}` : item.field))
        );
      } else if (sorter.column) {
        setSortParams([sorter.order === "descend" ? `-${sorter.field}` : sorter.field]);
      } else {
        setSortParams([]);
      }
    }
  };

  const handleExportCSV = async () => {
    await orderApi
      .exportCSV()
      .then((res) => {
        if (res) setIsExportSuccess(true);
      })
      .catch((e) => console.log(e));
  };

  const fetchListOrder = async () => {
    const res = await orderApi.getList({
      ...filterOrderObject,
      page,
      company_cd,
      sort: sortParams.length > 0 ? sortParams : ["-delivery_ymd"]
    });
    setListOrders(res.items);
    setTotalOrders(res.total);
  };
  useEffect(() => {
    if (company_cd) {
      fetchListOrder();
    }
  }, [company_cd, filterOrderObject, sortParams, page]);

  useEffect(() => {
    if (role && role !== ADMIN_ROLE && listOptOffices.length > 0) {
      form.setFieldsValue({ office_cd: listOptOffices[0].label });
      dispatch(
        setFilterOrderAction({
          ...filterOrderObject,
          office_cd: listOptOffices[0].value,
        })
      );
    }
  }, [listOptOffices, role]);

  useEffect(() => {
    dispatch(getListOptOfficesAction());
  }, []);

  useEffect(() => {
    if (isOrderCardExpanded)
      setShowMoreFilter(true);
  }, []);

  return (
    <div className="list-order-page bg-white">
      <Row className="header-order" align="middle" justify="space-between">
        <h1>配送依頼一覧</h1>
        <div className="header-right">
          {per?.includes(PER_ACCESS_PAGES.CREATE_ORDER) && (
            <Button
              onClick={() => navigate(PATH_NAME.CREATE_ORDER)}
              type="primary"
              icon={<Icon component={IconPlus} />}
            >
              新規配送依頼
            </Button>
          )}
          {role && role !== ROLE.CUSTOMER && (
            <Button onClick={handleExportCSV}>運送システム連携</Button>
          )}
        </div>
      </Row>
      <Divider height="2px" />
      <Form
        form={form}
        onFieldsChange={handleFilter}
        name="filter-group"
        autoComplete="off"
        initialValues={initialValues}
      >
        <Row>
          <Col span={21}>
            <Row className="filter-group" gutter={[16, 12]}>
              <SelectFilter
                title="ステータス"
                name="status"
                placeholder=""
                listOptions={listOptStatus}
              />
              <InputFilter title="お客様管理No." placeholder="テキスト入力" name="customer_no" />
              <InputFilter title="お客様担当者名" placeholder="テキスト入力" name="customer_nm" />
              <DatepickerFilter
                title="配達日"
                preName="de_from"
                sufName="de_to"
                disabledDate={disabledDate}
              />
            </Row>
          </Col>
          <Col span={3}>
            <Space
              className={`show-more-filter ${showMoreFilter && "rotate"}`}
              onClick={handleShowMore}
            >
              <div className="font-bold">
                {showMoreFilter ? "詳細条件を閉じる" : "詳細条件をすべて表示"}
              </div>
              <IconFilterDown />
            </Space>
          </Col>
        </Row>
        {showMoreFilter && (
          <div className="more-filter">
            <Row className="filter-group" gutter={[16, 12]}>
              <DatepickerFilter
                title="集荷日"
                preName="pi_from"
                sufName="pi_to"
                disabledDate={disabledDate}
              />
              <InputFilter title="配達先" placeholder="テキスト入力" name="de_addr" />
              <SelectFilter
                title="配送時間帯"
                name="time_frame"
                placeholder=""
                listOptions={listOptDeliTime}
              />
              {role === ROLE.ADMIN ? (
                <SelectFilter
                  title="タカスエ営業所"
                  placeholder="プルダウンから選択"
                  name="office_cd"
                  listOptions={listOptOffices}
                  width={240}
                />
              ) : (
                <InputFilter
                  title="タカスエ営業所"
                  placeholder="テキスト入力"
                  name="office_cd"
                  disabled
                />
              )}
              <InputFilter title="お客様名称" placeholder="テキスト入力" name="mgr_cus_nm" />
            </Row>
          </div>
        )}
      </Form>
      <TableListOrder
        page={page}
        totalRow={totalOrders}
        onChangePage={handleChangePage}
        dataSource={listOrders}
        listRecordSelected={listRecordSelected}
        setListRecordSelected={setListRecordSelected}
        onSortOrders={handleSortOrders}
      />
      {listRecordSelected.length > 0 && (
        <div className="show-delete">
          <Button
            onClick={handleClickBtnDelete}
            danger
            size="large"
            icon={<Icon component={IconTrash} />}
          >
            選択した配送を削除
          </Button>
          {isShowModalDelete && (
            <Modal
              isShowModal={isShowModalDelete}
              setIsShowModal={setIsShowModalDelete}
              title={"選択した配送依頼を削除します\nよろしいですか？"}
              subTitle="削除すると元に戻せません"
              danger
              handleConfirmOk={handleDeleteRecord}
            />
          )}
        </div>
      )}
      {isExportSuccess && (
        <Modal
          isShowModal={isExportSuccess}
          setIsShowModal={setIsExportSuccess}
          title="ファイル作成完了"
          subTitle="サーバーに連携用CSVファイルが作成されました。"
          handleConfirmOk={handleDeleteRecord}
          btnSubmitTitle="配送依頼一覧に戻る"
        />
      )}
    </div>
  );
}

export default ListOrder;
