import { IconCheck } from "assets";
import PropTypes from "prop-types";
import React from "react";
import { useSelector } from "react-redux";

import { pulldownStore } from "store/slices/pulldown";

import Tag from "components/common/Tag";

function OptionStatus({ check, onChangeStatus }) {
  const { listOptDeliveryStatus } = useSelector(pulldownStore);
  const getTypeTag = (text) => {
    let type;
    switch (text) {
      case "01":
        type = "delivery-uncomplete";
        break;
      case "02":
        type = "delivery-complete";
        break;
      case "03":
        type = "delivery-return";
        break;
    }
    return type;
  };

  return (
    listOptDeliveryStatus && (
      <div className="w-176 option-status">
        {listOptDeliveryStatus.map((option) => (
          <div
            onClick={() => {
              if (check !== option.value) {
                onChangeStatus(option.value);
              }
            }}
            className={`dis-flex option px-10 py-8 jc-space-between ai-center ${
              check === option.value ? "" : "pointer"
            }`}
            key={option.value}
          >
            <Tag type={getTypeTag(option.value)} size="sm" />{" "}
            {check === option.value && <IconCheck />}
          </div>
        ))}
      </div>
    )
  );
}
OptionStatus.propTypes = {
  check: PropTypes.string,
  onChangeStatus: PropTypes.func
};
export default OptionStatus;
