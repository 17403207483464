import { Form, Row } from "antd";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { DATE_FORMAT, PATH_NAME, STATUS } from "common/constant";
import { arrayDiff } from "common/utils/arrayHelper";
import { genOrderFormData } from "common/utils/orderHelper";

import { orderApi } from "services/order";

import { getAllFlexPullDownAction, pulldownStore } from "store/slices/pulldown";
import { getListOptOfficesAction } from "store/slices/pulldown";

import {
  CreateOrderSuccess,
  FooterDetailOrder,
  FormOrderDetail,
  HeaderDetailOrder,
  InforOrderDetail,
  ModalValidateFail,
  RightDetailOrder
} from "../components";
import "./DetailOrder.scss";

import Modal from "components/common/Modal";

function DetailOrder() {
  const { orderId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const canResetFlag = useRef(false);

  const [form] = Form.useForm();
  const [values, setValues] = useState();
  const [initialValues, setInitialValues] = useState();
  const [editMode, setEditMode] = useState(false);
  const [typeOrder, setTypeOrder] = useState("tlot"); // tlot || chapter
  const [isSuccess, setIsSuccess] = useState(false);
  const [isShowModalRemove, setIsShowModalRemove] = useState(false);
  const [isDone, setIsDone] = useState();
  const [defaultFileList, setDefaultFileList] = useState();
  const [inforOffice, setInforOffice] = useState();
  const [validateFail, setValidateFail] = useState(false);
  const [loading, setLoading] = useState(false);
  const [postCode, setPostCode] = useState({
    delivery: "",
    pickup: ""
  });

  const { listOptOffices, listPickupAddr } = useSelector(pulldownStore);

  const toggleEditMode = () => {
    form.resetFields();
    setEditMode(!editMode);
  };

  const triggerBtnSubmit = async () => {
    await form
      .validateFields()
      .then(() => {
        form.submit();
      })
      .catch((e) => {
        console.log("error", e);
        setValidateFail(true);
      });
  };

  // form.submit() => trigger handleSubmit

  const handleSubmit = async (params) => {
    const { delivery_addr1, pickup_addr1 } = params;
    const { office_cd, billing_addr_cd } = initialValues;

    setLoading(true);
    const dataToConvert = {
      office_cd,
      billing_addr_cd,
      ...params,
      ...(postCode.delivery && { delivery_addr1: `${postCode.delivery} ${delivery_addr1}` }),
      ...(postCode.pickup && { pickup_addr1: `${postCode.pickup} ${pickup_addr1}` })
    };
    const formData = genOrderFormData(dataToConvert, listPickupAddr, typeOrder);
    const listFileNameInit = defaultFileList?.map((e) => String(e.uid));
    const listFile = params?.files;
    const oldFiles = [];
    const newFiles = [];

    listFile?.forEach((file) => {
      if (file?.type) newFiles.push(file);
      else oldFiles.push(String(file?.uid));
    });

    const deletedFiles = arrayDiff(oldFiles, listFileNameInit);

    for (let i = 0; i < deletedFiles.length; i++) {
      formData.append("deleted_files", deletedFiles[i]);
    }
    for (let i = 0; i < newFiles.length; i++) {
      formData.append("new_files", newFiles[i]);
    }

    formData.delete("files");
    // call api - todo
    await orderApi
      .update(formData, orderId)
      .then(() => {
        if (isDone) {
          navigate("/order_list");
        } else {
          setIsSuccess(true);
        }
      })
      .catch((e) => console.log(e));
    setLoading(false);
  };

  const handleShowModalRemove = () => {
    setIsShowModalRemove(true);
  };

  const handleRemove = async () => {
    await orderApi
      .delete([+orderId])
      .then(() => navigate(PATH_NAME.ORDER_LIST))
      .catch((e) => console.log(e));
  };

  const fetchDataOrder = async () => {
    await orderApi
      .getDataById(orderId)
      .then((response) => {
        const listFile = response.files?.map((file) => ({
          uid: file.file_id,
          name: file.file_name,
          status: "done",
          url: file.url
        }));
        const delivery_ymd = moment(response?.order?.delivery_ymd, DATE_FORMAT);
        const pickup_ymd = moment(response?.order?.pickup_ymd, DATE_FORMAT);
        const dataInitOrder = {
          ...response?.order,
          ...(response?.order?.pickup_addr_postcd && {
            pickup_addr_postcd: response?.order?.pickup_addr_postcd
          }),
          ...response?.order?.pickup_address,
          delivery_ymd,
          pickup_ymd,
          files: listFile
        };
        if (response?.order?.order_div === "02") {
          setTypeOrder("chapter");
        }
        setPostCode({
          delivery: response?.order?.delivery_addr_area_cd,
          pickup: response?.order?.pickup_addr_area_cd
        });
        setValues(dataInitOrder);
        setInitialValues(dataInitOrder);
        setDefaultFileList(listFile);
        setIsDone(response.order.order_state === STATUS.DONE);
        canResetFlag.current = true;
      })
      .catch(() => navigate(PATH_NAME.ORDER_LIST));
  };

  useEffect(async () => {
    // call api
    fetchDataOrder();
    dispatch(getListOptOfficesAction());
  }, []);

  useEffect(() => {
    if (values) {
      dispatch(getAllFlexPullDownAction(values));
    }
  }, [values]);

  useEffect(async () => {
    if (listOptOffices && initialValues) {
      setInforOffice(listOptOffices?.find((e) => e.value === initialValues?.office_cd));
    }
  }, [listOptOffices, initialValues, editMode]);

  useEffect(() => {
    if (canResetFlag.current) {
      form.setFieldsValue({
        delivery_time_div: "01",
        delivery_vehicle_type: "01",
        stack_feasible_flg: "1",
        item_cd: null,
        quantity: null,
        quantity_div: null,
        volume: null,
        volume_div: null,
        weight: null,
        packing_sepa: null,
        packing_pl: null,
        packing_lo: null,
        packing_variant: null
      });
    }
  }, [typeOrder]);

  return isSuccess ? (
    <div className="content bg-white">
      <CreateOrderSuccess isUpdate />
    </div>
  ) : (
    <div id="detail-order-page">
      <ModalValidateFail setValidateFail={setValidateFail} validateFail={validateFail} />
      {isShowModalRemove && (
        <Modal
          danger
          isShowModal={isShowModalRemove}
          setIsShowModal={setIsShowModalRemove}
          title={"配送依頼をキャンセルします\nよろしいですか？"}
          subTitle="キャンセルすると元に戻せません"
          handleConfirmOk={handleRemove}
        />
      )}

      <div className="content bg-white">
        <HeaderDetailOrder
          isDone={isDone}
          editMode={editMode}
          typeOrder={typeOrder}
          setTypeOrder={setTypeOrder}
        />
        <Row justify={"space-between"}>
          {editMode ? (
            <FormOrderDetail
              formData={values}
              initialValues={initialValues}
              form={form}
              handleSubmit={handleSubmit}
              defaultFileList={defaultFileList}
              setDefaultFileList={setDefaultFileList}
              setInforOffice={setInforOffice}
              setPostCode={setPostCode}
              typeOrder={typeOrder}
            />
          ) : (
            <InforOrderDetail
              form={form}
              values={initialValues}
              handleSubmit={handleSubmit}
              isDone={isDone}
              typeOrder={typeOrder}
            />
          )}
          <RightDetailOrder
            editMode={editMode}
            toggleEditMode={toggleEditMode}
            handleSubmit={triggerBtnSubmit}
            handleRemove={handleShowModalRemove}
            isDone={isDone}
            inforOffice={inforOffice}
            loading={loading}
          />
        </Row>
      </div>

      <FooterDetailOrder
        isDone={isDone}
        editMode={editMode}
        toggleEditMode={toggleEditMode}
        handleSubmit={triggerBtnSubmit}
        handleRemove={handleShowModalRemove}
      />
    </div>
  );
}

export default DetailOrder;
