import React from "react";
import ProtoTypes from "prop-types";
import { Link } from "react-router-dom";
import { ConfigProvider, Empty, Table } from "antd";
import Tag from "components/common/Tag";
import "./TableListAccount.scss";
import { formatDateOrder } from "common/utils/dateHelper";

const TableListAccount = ({ dataSource, totalRow, onPageChange, page, onSortAccounts }) => {
  const columns = [
    {
      title: "ログインID",
      width: 120,
      dataIndex: "id",
      render: (text) => <Link to={`/account/${text}`}>{text}</Link>,
      sorter: {
        multiple: 1
      }
    },
    {
      title: "登録状況",
      width: 110,
      dataIndex: "save_flg",
      render: (text) => <Tag type={text === "0" ? "complete" : "un-complete"} size="sm" />,
      sorter: {
        multiple: 1
      }
    },
    {
      title: "ユーザー名",
      width: 160,
      dataIndex: "user_nm",
      sorter: {
        multiple: 1
      }
    },
    {
      title: "利用ユーザー",
      width: 160,
      dataIndex: "user_role",
      sorter: {
        multiple: 1
      }
    },
    {
      title: "入力日",
      width: 150,
      dataIndex: "regi_datetime",
      sorter: {
        multiple: 1
      },
      render: (text) => formatDateOrder(text)
    },
    {
      title: "更新日",
      width: 200,
      dataIndex: "upd_datetime",
      sorter: {
        multiple: 1
      },
      render: (text) => formatDateOrder(text)
    }
  ];
  return (
    <div className="account-list">
      <ConfigProvider
        renderEmpty={() => (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="適当な検索結果がありません" />
        )}
      >
        <Table
          title={() => (
            <div className="title">
              {dataSource.length > 0 && `全${totalRow}件中${dataSource.length}件を表示`}
            </div>
          )}
          onChange={onSortAccounts}
          columns={columns}
          rowKey={(record) => record.id}
          dataSource={dataSource}
          pagination={{
            current: page,
            onChange: onPageChange,
            position: ["topRight"],
            defaultPageSize: 30,
            total: totalRow,
            showSizeChanger: false
          }}
          scroll={{ x: 400, y: 490 }}
        />
      </ConfigProvider>
    </div>
  );
};
TableListAccount.propTypes = {
  dataSource: ProtoTypes.array,
  totalRow: ProtoTypes.number,
  onPageChange: ProtoTypes.func,
  page: ProtoTypes.number,
  onSortAccounts: ProtoTypes.func
};
export default TableListAccount;
