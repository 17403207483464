import { Col, Form, Input, Row, Tooltip } from "antd";
import { IconHelp } from "assets";
import PropTypes from "prop-types";
import React from "react";

import { rulesOrder } from "common/constant/rules";

import Select from "components/common/Select";
import Tag from "components/common/Tag";

FormMultiInput.propTypes = {
  name: PropTypes.string,
  nameWrapper: PropTypes.string,
  subName: PropTypes.string,
  unit: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  helperText: PropTypes.string,
  optional: PropTypes.bool,
  listOptions: PropTypes.array,
  rules: PropTypes.array,
  form: PropTypes.any
};

function FormMultiInput({
  label,
  name,
  subName,
  unit,
  listOptions,
  placeholder,
  helperText,
  optional,
  rules,
  form,
  nameWrapper = "infor-item"
}) {
  const valueName = form && Form.useWatch(name, form);

  return (
    <Row className="form-item form-multi-input">
      <Col span={7} className="label">
        <h1 className="label">{label}</h1>
      </Col>
      <Col span={9}>
        <Form.Item name={nameWrapper} rules={!optional && rulesOrder.inforItem}>
          <Row>
            <Col span={9}>
              <Form.Item name={name} rules={rules && rules[0]}>
                <Input placeholder={placeholder} />
              </Form.Item>
              {unit && <div className="unit">{unit}</div>}
            </Col>
            {subName && (
              <>
                <Col span={6} className="sub-name-unit">
                  単位：
                </Col>
                <Col span={9}>
                  <Select
                    disabled={!valueName}
                    name={subName}
                    rules={rules && rules[1]}
                    placeholder={placeholder}
                    listOptions={listOptions}
                  />
                </Col>
              </>
            )}
          </Row>
          {optional && <Tag type="optional" />}
          {helperText && (
            <Tooltip
              placement="bottomLeft"
              title={helperText}
              overlayStyle={{ maxWidth: "max-content" }}
              className="icon-help"
            >
              <IconHelp />
            </Tooltip>
          )}
        </Form.Item>
      </Col>
    </Row>
  );
}

export default FormMultiInput;
