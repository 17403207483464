import { useSelector } from "react-redux";

import { authStore } from "store/slices/auth";

function useAuth() {
  const { per, role, token, authUser, authPer, branchOfficeCd } = useSelector(authStore);
  return { per, role, token, authUser, authPer, branchOfficeCd };
}

export default useAuth;
