import { Col, Form } from "antd";
import PropTypes from "prop-types";
import React from "react";
import { useSelector } from "react-redux";

import { DIV_BOOL, DIV_PACKING, ROLE } from "common/constant";
import { rulesOrder } from "common/constant/rules";
import useAuth from "common/hooks/useAuth";
import { getLabel } from "common/utils/stringHelper";

import { pulldownStore } from "store/slices/pulldown";

import { FormArea, FormDetail, TitleDetail } from "components/form";

InforOrderDetail.propTypes = {
  values: PropTypes.object,
  form: PropTypes.any,
  handleSubmit: PropTypes.func,
  isDone: PropTypes.bool,
  typeOrder: PropTypes.string
};
function InforOrderDetail({ values, form, handleSubmit, isDone, typeOrder = "tlot" }) {
  const {
    listBillingAddr,
    listPickupAddr,
    listOptItems,
    listOptOffices,
    listOptDeliTime,
    listOptDeliVehicleType,
    listOptUnitQuantity,
    listOptUnitVolume,
    listOptVehicleChapter
  } = useSelector(pulldownStore);

  const { role } = useAuth();
  const handleChangeNote = async (data) => {
    handleSubmit({ ...values, ...data });
  };

  return (
    <Col span={18}>
      <div id="basic_infor" className="form-order-group">
        <TitleDetail title="基本情報" />
        {values?.order_state && (
          <FormDetail type="status" value={values?.order_state} label="ステータス" />
        )}
        <FormDetail
          type="select"
          listOptions={listOptOffices}
          value={values?.office_cd}
          label="タカスエ営業所"
        />
        <FormDetail
          label="お客様"
          type="select"
          listOptions={listBillingAddr}
          value={values?.billing_addr_cd}
        />
        <FormDetail label="お客様管理ナンバー" value={values?.client_manage_no} />
      </div>
      <div id="collection" className="form-order-group">
        <TitleDetail title="荷積（集荷）情報" />
        <FormDetail
          label="集荷先名称"
          type={values?.pickup_addr_nm ? "text" : "select"}
          listOptions={listPickupAddr}
          value={values?.pickup_addr_nm || values?.pickup_addr_cd}
        />
        <FormDetail label="電話番号" value={values?.pickup_addr_tel} />
        <FormDetail label="郵便番号" type="postcd" value={values?.pickup_addr_postcd} />
        <FormDetail
          label="住所"
          value={[values?.pickup_addr1, values?.pickup_addr2, values?.pickup_addr3].join("")}
        />
        <FormDetail label="集荷日" type="date" value={values?.pickup_ymd} />
      </div>
      <div id="upload" className="form-order-group">
        <TitleDetail title="荷降（配達）情報" />
        <FormDetail label="配達先名称" value={values?.delivery_addr_nm} />
        <FormDetail label="電話番号" value={values?.delivery_addr_tel} />
        <FormDetail label="郵便番号" value={values?.delivery_addr_postcd} />
        <FormDetail
          label="住所"
          value={[values?.delivery_addr1, values?.delivery_addr2, values?.delivery_addr3].join("")}
        />
        <FormDetail label="配達日" type="date" value={values?.delivery_ymd} />
        <FormDetail
          label="配達時間指定"
          type="select"
          value={values?.delivery_time_div}
          listOptions={listOptDeliTime}
        />
        <FormDetail
          label="入場車種制限"
          type="select"
          value={values?.delivery_vehicle_type}
          listOptions={listOptDeliVehicleType}
        />
        <FormDetail label="添付資料" type="link" value={values?.files} />
      </div>
      <div id="item_infor" className="form-order-group">
        <TitleDetail title="荷物情報" />
        {typeOrder === "chapter" ? (
          <FormDetail
            label="車両"
            value={values?.quantity_div}
            type="select"
            listOptions={listOptVehicleChapter}
          />
        ) : (
          <>
            <FormDetail
              label="品名"
              value={values?.item_cd}
              type="select"
              listOptions={listOptItems}
            />
            <FormDetail
              label="数量"
              value={`${values?.quantity || ""} ${getLabel(
                values?.quantity_div,
                listOptUnitQuantity
              )}`}
            />
          </>
        )}
        <FormDetail
          label="容積"
          value={`${values?.volume || ""} ${getLabel(values?.volume_div, listOptUnitVolume)}`}
        />
        <FormDetail label="重量" value={values?.weight && `${values?.weight} kg`} />
        {typeOrder === "tlot" && (
          <>
            <FormDetail
              label="段積"
              value={values?.stack_feasible_flg}
              type="select"
              listOptions={DIV_BOOL}
            />
            <FormDetail
              label="荷姿"
              value={values}
              type="checkbox-group"
              listOptions={DIV_PACKING}
            />
          </>
        )}
      </div>
      <div id="note" className="form-order-group">
        <TitleDetail title="コメント" />
        {isDone ? (
          <Form form={form} initialValues={values} onFinish={handleChangeNote}>
            <FormArea
              disabled={role !== ROLE.CUSTOMER}
              label="お客様用"
              name="note_client"
              rules={rulesOrder.note_client}
              placeholder={"荷降先が複数の場合など特記事項がある場合に\n記載してください"}
              optional
            />
            <FormArea
              disabled={role === ROLE.CUSTOMER}
              label="タカスエ用"
              name="note_takasue"
              rules={rulesOrder.note_takasue}
              placeholder={"荷降先が複数の場合など特記事項がある場合に\n記載してください"}
              optional
            />
          </Form>
        ) : (
          <>
            <FormDetail label="お客様用" value={values?.note_client} />
            <FormDetail label="タカスエ用" value={values?.note_takasue} />
          </>
        )}
      </div>
    </Col>
  );
}

export default InforOrderDetail;
