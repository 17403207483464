import React from "react";

import CreateCustomerAccount from "pages/account/create/CreateCustomerAccount";
import CreateHonshaAccount from "pages/account/create/CreateHonshaAccount";
import CreateUnsoAccount from "pages/account/create/CreateUnsoAccount";
import DetailAccount from "pages/account/detail/DetailAccount";
import ListAccount from "pages/account/list/ListAccount";
import UpdateCustomerAcc from "pages/account/update/UpdateCustomerAcc";
import UpdateHonshaAccount from "pages/account/update/UpdateHonshaAcc";
import UpdateUnsoAccount from "pages/account/update/UpdateUnsoAcc";
import ListArrival from "pages/arrival/list/ListArrival";
import ListBase from "pages/base/list/ListBase";
import UpdateBase from "pages/base/update/UpdateBase";
import ListInvoice from "pages/invoice/list/ListInvoice";
import LoginContainer from "pages/login/LoginContainer";
import ListMaster from "pages/master/ListMaster";
import Operation from "pages/operation/Operation";
import CaptureOrderCSV from "pages/order/captureCSV/CaptureOrderCSV";
import CreateOrderContainer from "pages/order/create/CreateOrderContainer";
import DetailOrder from "pages/order/detail/DetailOrder";
import ListOrder from "pages/order/list/ListOrder";
import DetailService from "pages/service/detail/DetailService";
import ListService from "pages/service/list/ListService";
import UserDetail from "pages/user/detail/UserDetail";
import UserUpdate from "pages/user/update/UserUpdate";
import PrivacyPolicy from "pages/policy/PrivacyPolicy";

import { PATH_NAME, PER_ACCESS_PAGES, SCREEN_TEXT_BACK } from "./constant";

const routes = [
  {
    path: PATH_NAME.LOGIN,
    element: <LoginContainer />
  },
  {
    path: PATH_NAME.HOME,
    element: <></>,
    withAuth: true
  },
  {
    path: PATH_NAME.ORDER_LIST,
    element: <ListOrder />,
    withAuth: true,
    per: PER_ACCESS_PAGES.ORDER_LIST
  },
  {
    path: PATH_NAME.CREATE_ORDER,
    element: <CreateOrderContainer />,
    withAuth: true,
    haveBtnBack: true,
    per: PER_ACCESS_PAGES.CREATE_ORDER
  },
  {
    path: PATH_NAME.CSV_CAPTURE,
    element: <CaptureOrderCSV />,
    withAuth: true,
    haveBtnBack: true,
    per: PER_ACCESS_PAGES.CREATE_ORDER
  },
  {
    path: PATH_NAME.UPDATE_ORDER,
    element: <DetailOrder />,
    withAuth: true,
    haveBtnBack: true
  },
  {
    path: PATH_NAME.INVOICE_LIST,
    element: <ListInvoice />,
    withAuth: true,
    per: PER_ACCESS_PAGES.INVOICE_LIST
  },
  {
    path: PATH_NAME.ACCOUNT_LIST,
    element: <ListAccount />,
    withAuth: true,
    per: PER_ACCESS_PAGES.ACCOUNT_LIST
  },
  {
    path: PATH_NAME.ACC_REG_MAIN,
    element: <CreateCustomerAccount />,
    withAuth: true,
    haveBtnBack: true,
    per: PER_ACCESS_PAGES.CREATE_ACCOUNT
  },
  {
    path: PATH_NAME.ACC_UPD_MAIN,
    element: <UpdateCustomerAcc />,
    withAuth: true,
    haveBtnBack: true,
    per: PER_ACCESS_PAGES.CREATE_ACCOUNT
  },
  {
    path: PATH_NAME.ACC_REG_HONSHA,
    element: <CreateHonshaAccount />,
    withAuth: true,
    haveBtnBack: true,
    per: PER_ACCESS_PAGES.CREATE_ACCOUNT
  },
  {
    path: PATH_NAME.ACC_UPD_HONSHA,
    element: <UpdateHonshaAccount />,
    withAuth: true,
    haveBtnBack: true,
    per: PER_ACCESS_PAGES.CREATE_ACCOUNT
  },
  {
    path: PATH_NAME.ACC_REG_UNSO,
    element: <CreateUnsoAccount />,
    withAuth: true,
    haveBtnBack: true,
    per: PER_ACCESS_PAGES.CREATE_ACCOUNT
  },
  {
    path: PATH_NAME.ACC_UPD_UNSO,
    element: <UpdateUnsoAccount />,
    withAuth: true,
    haveBtnBack: true,
    per: PER_ACCESS_PAGES.CREATE_ACCOUNT
  },
  {
    path: PATH_NAME.ACCOUNT_DETAIL,
    element: <DetailAccount />,
    withAuth: true,
    haveBtnBack: true,
    per: PER_ACCESS_PAGES.CREATE_ACCOUNT
  },
  {
    path: PATH_NAME.USER_DETAIL,
    element: <UserDetail />,
    withAuth: true,
    haveBtnBack: true
  },
  {
    path: PATH_NAME.USER_UPDATE,
    element: <UserUpdate />,
    withAuth: true,
    haveBtnBack: true
  },
  {
    path: PATH_NAME.MASTER_LIST,
    element: <ListMaster />,
    withAuth: true,
    per: PER_ACCESS_PAGES.LIST_MASTER
  },
  {
    path: PATH_NAME.BASE_LIST,
    haveBtnBack: true,
    element: <ListBase />,
    withAuth: true,
    per: PER_ACCESS_PAGES.LIST_MASTER_BASE,
    textBack: SCREEN_TEXT_BACK.UPDATE_BASE
  },
  {
    path: PATH_NAME.BASE_UPDATE,
    haveBtnBack: true,
    element: <UpdateBase />,
    withAuth: true,
    per: PER_ACCESS_PAGES.UPDATE_MASTER_BASE
  },
  {
    path: PATH_NAME.ARRIVAL_LIST,
    haveBtnBack: false,
    element: <ListArrival />,
    withAuth: true,
    per: PER_ACCESS_PAGES.ARRIVAL_LIST
  },
  {
    path: PATH_NAME.SERVICE_LIST,
    haveBtnBack: false,
    element: <ListService />,
    withAuth: true,
    per: PER_ACCESS_PAGES.SERVICE_LIST
  },
  {
    path: PATH_NAME.SERVICE_DETAIL,
    haveBtnBack: true,
    element: <DetailService />,
    withAuth: true,
    per: PER_ACCESS_PAGES.SERVICE_DETAIL
  },
  {
    path: PATH_NAME.OPERATION,
    haveBtnBack: false,
    element: <Operation />,
    withAuth: true,
    per: PER_ACCESS_PAGES.OPERATION,
    noPadding: true
  },
  {
    path: PATH_NAME.PRIVACY_POLICY,
    haveBtnBack: false,
    element: <PrivacyPolicy />,
    noPadding: true
  }
];

export default routes;
