import { createSlice } from "@reduxjs/toolkit";

export const slice = createSlice({
  name: "searchCard",

  initialState: {
    isOrderCardExpanded: false,
    isServiceCardExpanded: false,
    isArrivalCardExpanded: false
  },

  reducers: {
    setIsOrderCardExpanded: (state, { payload }) => {
      state.isOrderCardExpanded = payload;
    },
    setIsServiceCardExpanded: (state, { payload }) => {
      state.isServiceCardExpanded = payload;
    },
    setIsArrivalCardExpanded: (state, { payload }) => {
      state.isArrivalCardExpanded = payload;
    }
  }
});

export const { setIsOrderCardExpanded, setIsServiceCardExpanded, setIsArrivalCardExpanded } =
  slice.actions;

export const searchCardStore = (state) => state.searchCard;

export default slice.reducer;
