import { IconFilterDown } from "assets";
import scrollToAnchor from "common/utils/scrollAnchor";
import React from "react";

function TableContent() {
  const listAnchor = [
    {
      href: "basic_infor",
      content: "基本情報"
    },
    {
      href: "collection",
      content: "荷積（集荷）情報"
    },
    {
      href: "upload",
      content: "荷降（配達）情報"
    },
    {
      href: "item_infor",
      content: "荷物情報"
    },
    {
      href: "note",
      content: "コメント"
    }
  ];
  return (
    <div className="table-content">
      <h1>目次</h1>
      {listAnchor.map(({ href, content }) => (
        <a key={href} href={`#${href}`} onClick={() => scrollToAnchor(href)}>
          <IconFilterDown />
          {content}
        </a>
      ))}
    </div>
  );
}

export default TableContent;
