import { Col, Form, Row, Space } from "antd";
import { useForm } from "antd/lib/form/Form";
import { IconFilterDown } from "assets";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ROLE } from "common/constant";
import { rulesListService } from "common/constant/rules";
import { SERVICE_YMD_DIV } from "common/constant/service";
import { saveFileWithExactName } from "common/utils/base64Helper";
import { formatFilterDateSlash } from "common/utils/dateHelper";

import { serviceApi } from "services/service";

import { authStore } from "store/slices/auth";
import { setFilterServiceAction } from "store/slices/listData";
import { listFilterStore } from "store/slices/listData";
import { getListServiceOpt, getServiceDriverList, pulldownStore } from "store/slices/pulldown";
import { searchCardStore, setIsServiceCardExpanded } from "store/slices/searchCard";

import "../../order/list/ListOrder.scss";
import TableListService from "../components/TableListService";

import Divider from "components/common/Divider";
import DatepickerFilter from "components/filter/DatepickerFilter";
import InputFilter from "components/filter/InputFilter";
import RadioCheckFilter from "components/filter/RadioCheckFilter";
import SelectFilter from "components/filter/SelectFilter";

function ListService() {
  const [form] = useForm();
  const dispatch = useDispatch();
  const [listService, setListService] = useState([]);
  const [totalService, setTotalService] = useState();
  const { filterServiceObject } = useSelector(listFilterStore);
  const [page, setPage] = useState(1);
  const [sortParams, setSortParams] = useState([]);
  const [showMoreFilter, setShowMoreFilter] = useState(false);
  const { company_cd, role } = useSelector(authStore);
  const { listOptServiceStatus, listOptOffices, listOptServiceYmd, listOptServiceDriver } =
    useSelector(pulldownStore);
  const { isServiceCardExpanded } = useSelector(searchCardStore);

  const service_ymd_div = Form.useWatch("service_ymd_div", form);
  const office_cd = Form.useWatch("office_cd", form);

  const initialValues = {
    ...filterServiceObject,
    ...(filterServiceObject.service_ymd_start
      ? { service_ymd_start: moment(filterServiceObject.service_ymd_start) }
      : {}),
    ...(filterServiceObject.service_ymd_end
      ? { service_ymd_end: moment(filterServiceObject.service_ymd_end) }
      : {})
  };
  const radioCheckOpt = [
    { label: "すべて", value: null },
    { label: "あり", value: "1" },
    { label: "なし", value: "0" }
  ];

  const checkDate = (service_ymd_div) => {
    switch (service_ymd_div) {
      case SERVICE_YMD_DIV.YESTERDAY:
        form.setFieldValue("service_ymd_start", moment().subtract(1, "days"));
        form.setFieldValue("service_ymd_end", moment().subtract(1, "days"));
        dispatch(
          setFilterServiceAction({
            ...filterServiceObject,
            service_ymd_start: formatFilterDateSlash(moment().subtract(1, "days")),
            service_ymd_end: formatFilterDateSlash(moment().subtract(1, "days")),
            service_ymd_div
          })
        );
        break;
      case SERVICE_YMD_DIV.TODAY:
        form.setFieldValue("service_ymd_start", moment());
        form.setFieldValue("service_ymd_end", moment());
        dispatch(
          setFilterServiceAction({
            ...filterServiceObject,
            service_ymd_start: formatFilterDateSlash(moment()),
            service_ymd_end: formatFilterDateSlash(moment()),
            service_ymd_div
          })
        );
        break;
      case SERVICE_YMD_DIV.TOMORROW:
        form.setFieldValue("service_ymd_start", moment().add(1, "days"));
        form.setFieldValue("service_ymd_end", null);
        dispatch(
          setFilterServiceAction({
            ...filterServiceObject,
            service_ymd_start: formatFilterDateSlash(moment().add(1, "days")),
            service_ymd_end: "",
            service_ymd_div
          })
        );
        break;
      case SERVICE_YMD_DIV.FROM_T0:
        form.setFieldValue("service_ymd_start", null);
        form.setFieldValue("service_ymd_end", null);
        dispatch(
          setFilterServiceAction({
            ...filterServiceObject,
            service_ymd_start: "",
            service_ymd_end: "",
            service_ymd_div
          })
        );
        break;
    }
  };

  const fetchListService = async () => {
    const res = await serviceApi.getListService({
      ...filterServiceObject,
      page,
      company_cd,
      sort: sortParams.length > 0 ? sortParams : []
    });
    setListService(res?.items);
    setTotalService(res?.total);
  };

  const handleDownloadSignImage = (service_no) => {
    serviceApi.downloadSignImage(service_no).then((data) => saveFileWithExactName(data));
  };

  const handleDownloadReceiptImage = (service_no) => {
    serviceApi.downloadReceiptImage(service_no).then((data) => saveFileWithExactName(data));
  };

  const handleShowMore = () => {
    setShowMoreFilter(!showMoreFilter);
    dispatch(setIsServiceCardExpanded(!showMoreFilter));
  };

  const handleSortOrders = (pagination, _, sorter) => {
    if (pagination.current === page) {
      if (sorter.length > 1) {
        setSortParams(
          sorter.map((item) => (item.order === "descend" ? `-${item.field}` : item.field))
        );
      } else if (sorter.column) {
        setSortParams([sorter.order === "descend" ? `-${sorter.field}` : sorter.field]);
      } else {
        setSortParams([]);
      }
    }
  };

  const handleFilter = (changedFields) => {
    if (changedFields[0].value) {
      if (["office_cd"].includes(changedFields[0].name[0])) {
        dispatch(
          setFilterServiceAction({
            ...filterServiceObject,
            [changedFields[0].name[0]]: changedFields[0].value
          })
        );
      } else if (["service_ymd_div"].includes(changedFields[0].name[0])) {
        checkDate(changedFields[0].value);
      } else if (["service_ymd_start", "service_ymd_end"].includes(changedFields[0].name[0])) {
        dispatch(
          setFilterServiceAction({
            ...filterServiceObject,
            [changedFields[0].name[0]]: formatFilterDateSlash(changedFields[0].value)
          })
        );
      } else {
        dispatch(
          setFilterServiceAction({
            ...filterServiceObject,
            [changedFields[0].name[0]]: changedFields[0].value
          })
        );
      }
    } else {
      const cloneFilter = { ...filterServiceObject };
      delete cloneFilter[changedFields[0].name[0]];
      dispatch(setFilterServiceAction({ ...cloneFilter }));
    }
    setPage(1);
  };

  const handleChangePage = (currentPage) => {
    setPage(currentPage);
  };

  useEffect(() => {
    if (company_cd) {
      fetchListService();
    }
  }, [company_cd, filterServiceObject, sortParams, page]);

  useEffect(() => {
    dispatch(getListServiceOpt());
  }, []);

  useEffect(() => {
    dispatch(getServiceDriverList({ office_cd: office_cd }));
  }, [office_cd]);

  useEffect(() => {
    if (role && role !== ROLE.ADMIN) {
      form.setFieldValue("office_cd", listOptOffices[0]?.value);
      dispatch(
        setFilterServiceAction({
          ...filterServiceObject,
          office_cd: listOptOffices[0]?.value
        })
      );
    }
  }, [listOptOffices, role]);

  useEffect(() => {
    if (listOptServiceYmd.length > 0 && !filterServiceObject.service_ymd_div) {
      form.setFieldValue("service_ymd_div", SERVICE_YMD_DIV.TODAY);
      dispatch(
        setFilterServiceAction({
          ...filterServiceObject,
          service_ymd_div: SERVICE_YMD_DIV.TODAY
        })
      );
    }
  }, [listOptServiceYmd]);

  useEffect(() => {
    if (isServiceCardExpanded)
      setShowMoreFilter(true);
  }, []);

  return (
    <div className="list-order-page bg-white">
      <Row className="header-order" align="middle" justify="space-between">
        <h1 className="fs-24 fw-700">運行一覧</h1>
      </Row>
      <Divider height="2px" />
      <Form
        autoComplete="off"
        onFieldsChange={handleFilter}
        form={form}
        initialValues={initialValues}
      >
        <Row>
          <Col span={21}>
            <Row className="filter-group" gutter={[16, 12]}>
              <SelectFilter
                title="運行日区分"
                name="service_ymd_div"
                placeholder="お選びください"
                listOptions={listOptServiceYmd}
                width={160}
                allowClear={false}
              />
              <DatepickerFilter
                title="運行日開始・終了"
                placeholder="yy年m月d日(曜日)"
                name="name"
                preName="service_ymd_start"
                sufName="service_ymd_end"
                disabled={service_ymd_div !== SERVICE_YMD_DIV.FROM_T0}
              />
              <RadioCheckFilter
                title="受領書"
                name="receip_flag"
                options={radioCheckOpt}
                defaultValue={null}
              />
              <RadioCheckFilter
                title="電子サイン"
                name="sign_flag"
                options={radioCheckOpt}
                defaultValue={null}
              />
            </Row>
          </Col>
          <Col span={3}>
            <Space
              className={`show-more-filter ${showMoreFilter && "rotate"}`}
              onClick={handleShowMore}
            >
              <div className="font-bold">
                {showMoreFilter ? "詳細条件を閉じる" : "詳細条件をすべて表示"}
              </div>
              <IconFilterDown />
            </Space>
          </Col>
        </Row>
        {showMoreFilter ? (
          <>
            <Row className="filter-group" gutter={[16, 12]}>
              <RadioCheckFilter
                title="メモ"
                name="memo_flag"
                options={radioCheckOpt}
                defaultValue={null}
              />
              <SelectFilter
                title="ステータス"
                name="service_status"
                placeholder="お選びください"
                listOptions={listOptServiceStatus}
                width={260}
              />
              <SelectFilter
                title="営業所"
                name="office_cd"
                placeholder="お選びください"
                listOptions={listOptOffices}
                width={260}
                allowClear
                disabled={role !== ROLE.ADMIN}
              />
              <SelectFilter
                title="ドライバー"
                name="driver_cd"
                placeholder="お選びください"
                listOptions={listOptServiceDriver}
                width={260}
              />
            </Row>
            <Row className="filter-group" gutter={[16, 12]}>
              <InputFilter
                title="集荷先名"
                placeholder=""
                name="pickup_addr_nm"
                width={250}
                rules={rulesListService.max_length_30}
              />
              <InputFilter
                title="荷受人名"
                placeholder=""
                name="consignee_nm"
                width={260}
                rules={rulesListService.max_length_30}
              />
            </Row>
          </>
        ) : (
          <Row className="filter-group" gutter={[16, 12]}>
            <RadioCheckFilter
              title="メモ"
              name="memo_flag"
              options={radioCheckOpt}
              defaultValue={null}
            />
            <SelectFilter
              title="営業所"
              name="office_cd"
              placeholder="お選びください"
              listOptions={listOptOffices}
              disabled={role !== ROLE.ADMIN}
              width={260}
            />
            <SelectFilter
              title="ドライバー"
              name="driver_cd"
              placeholder="お選びください"
              listOptions={listOptServiceDriver}
              width={260}
            />
            <InputFilter
              title="集荷先名"
              placeholder=""
              name="pickup_addr_nm"
              width={250}
              rules={rulesListService.max_length_30}
            />
          </Row>
        )}
      </Form>
      <TableListService
        page={page}
        dataSource={listService}
        totalRow={totalService}
        onSortOrders={handleSortOrders}
        onPageChange={handleChangePage}
        handleDownloadSignImage={handleDownloadSignImage}
        handleDownloadReceiptImage={handleDownloadReceiptImage}
      />
    </div>
  );
}

export default ListService;
