import React from "react";
import PropTypes from "prop-types";
import "./ValidateTextBox.scss";

const ValidateTextBox = ({ listMsg }) => {
  return (
    <div className="validate-textbox">
      {listMsg.map((msg, i) => (
        <p key={i}>※ {msg}</p>
      ))}
    </div>
  );
};

ValidateTextBox.propTypes = {
  listMsg: PropTypes.array
};

export default ValidateTextBox;
