import PropTypes from "prop-types";
import React from "react";

import "./SpotMarker.scss";

function SpotMarker({ round, text, highlighted, setHighlighted }) {
  return (
    <div
      onClick={() => {
        setHighlighted(round*100+text);
      }}
      className={`${highlighted ? "map-marker-wrapper-highlighted" : "map-marker-wrapper"} pointer`}
    >
      <div className="map-marker">{text}</div>
      <div className="map-marker-con" />
      <div className="map-marker-pointer" />
    </div>
  );
}
export default SpotMarker;

SpotMarker.propTypes = {
  round: PropTypes.number,
  text: PropTypes.number,
  highlighted: PropTypes.bool,
  setHighlighted: PropTypes.func
};
