import api from "./baseApi";

export const arrivalApi = {
  getDeliveryStatus() {
    return api.get("/div/delivery-status");
  },
  getListArrival(params) {
    return api.get("/service-instructs/arrival-status", { params });
  },
  downloadSignImage(params) {
    return api.get(`/service-instructs/${params}/download-sign-images/pdf`, {
      responseType: "blob"
    });
  },
  updateDeliStatus(id, params) {
    return api.patch(`/service-instructs/${id}`, params);
  }
};
