import { Col, Radio, Row, Tabs } from "antd";
import { IconWarning } from "assets";
import PropTypes from "prop-types";
import React from "react";

import Divider from "components/common/Divider";
import Tag from "components/common/Tag";

HeaderDetailOrder.propTypes = {
  editMode: PropTypes.bool,
  isDone: PropTypes.bool,
  typeOrder: PropTypes.string,
  setTypeOrder: PropTypes.func
};

function HeaderDetailOrder({ editMode, isDone, typeOrder, setTypeOrder }) {
  const switchType = () => {
    if (typeOrder === "tlot") {
      setTypeOrder("chapter");
    } else setTypeOrder("tlot");
  };
  return (
    <div className="header-order">
      {isDone ? (
        <>
          <div className="header-title">
            <Row align="middle">
              <h1>配送依頼詳細</h1>
              <Tag type="fix" size="lg" />
            </Row>
          </div>
          <Divider height="2px" />
          <Row justify="center" align="middle" className="message">
            <IconWarning />
            本注文は確定済です。
          </Row>
        </>
      ) : (
        <>
          <div className="header-title">
            {editMode ? (
              <h1>配送依頼内容の変更</h1>
            ) : (
              <Row align="middle">
                <h1>配送依頼詳細</h1>
                <Tag type="un-fix" size="lg" />
              </Row>
            )}
          </div>
          <Divider height="2px" />
          <Row justify="center" align="middle" className="message">
            <IconWarning />
            集荷前日、昼12:00締め切りで配送依頼内容を確定致します。
          </Row>
          {editMode && (
            <Row>
              <Col span={8}>
                <div>※Tロット/チャーターの選択を行って下さい。</div>
                <Tabs
                  activeKey={typeOrder}
                  type="card"
                  className="type-order"
                  onChange={switchType}
                >
                  <Tabs.TabPane
                    tab={
                      <div className="pos-rel flex-center">
                        <Radio checked={typeOrder === "tlot"} className="radio-left" />
                        Tロット
                      </div>
                    }
                    key="tlot"
                  />
                  <Tabs.TabPane
                    tab={
                      <div className="pos-rel flex-center">
                        <Radio checked={typeOrder === "chapter"} className="radio-right" />
                        チャーター
                      </div>
                    }
                    key="chapter"
                  />
                </Tabs>
              </Col>
            </Row>
          )}
        </>
      )}
    </div>
  );
}

export default HeaderDetailOrder;
