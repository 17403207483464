import { IconTruckGrey, IconTruckOpe } from "assets";
import PropTypes from "prop-types";
import React from "react";

import "./SpotMarker.scss";

function TruckMarker({ status, data, onChangeActive }) {
  return (
    <a href={`#${data?.service_no}`}>
      <div className={`truck-marker${status === "unactive" ? "-unactive" : ""}`}>
        <div
          className="map-marker dis-flex ai-center jc-center"
          onClick={() => onChangeActive(data)}
        >
          {status === "unactive" ? <IconTruckGrey /> : <IconTruckOpe />}
        </div>
        <div className="map-marker-con" />
        <div className="map-marker-pointer" />
      </div>
    </a>
  );
}

export default TruckMarker;

TruckMarker.propTypes = {
  status: PropTypes.string,
  data: PropTypes.object,
  onChangeActive: PropTypes.func
};
