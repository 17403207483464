import PropTypes from "prop-types";
import React from "react";

import OperationServiceCard from "./OperationServiceCard";

const OperationServiceList = ({
  listOperation,
  activeService,
  onChangeActive,
  onToggleCheck,
  check
}) => {
  return (
    <div id="operation-list" className="h-full-screen">
      {listOperation.map((item) => (
        <OperationServiceCard
          key={item.service_no}
          data={item}
          isActive={item.service_no === activeService.service_no}
          onChangeActive={onChangeActive}
          onToggleCheck={onToggleCheck}
          check={check}
        />
      ))}
    </div>
  );
};

OperationServiceList.propTypes = {
  listOperation: PropTypes.array,
  activeService: PropTypes.object,
  onChangeActive: PropTypes.func,
  onToggleCheck: PropTypes.func,
  check: PropTypes.bool
};

export default OperationServiceList;
