import { createSlice } from "@reduxjs/toolkit";

import cache from "common/utils/cache";
import sessionCache from "common/utils/sessionCache";

import { authApi } from "services/auth";
import { userApi } from "services/user";

export const slice = createSlice({
  name: "auth",
  initialState: {
    authUser: null,
    token: cache.get("token") || sessionCache.get("token"),
    role: null,
    per: null,
    authPer: null,
    company_cd: null,
    branchOfficeCd: null,
    loading: false,
    loadingResetPassword: false,
    error: null,
    errorResetPwd: null
  },
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    loginSuccess: (state, action) => {
      state.token = action.payload && action.payload.token;
      state.refreshToken = action.payload && action.payload.refreshToken;
      state.error = null;
      state.loading = false;
      if (action.payload.remember) {
        cache.set("token", state.token);
        cache.set("refreshToken", state.refreshToken);
      } else {
        sessionCache.set("token", state.token);
        sessionCache.set("refreshToken", state.refreshToken);
      }
    },
    logoutSuccess: (state) => {
      state.authUser = null;
      state.token = null;
      state.loading = false;
      state.error = null;
      state.role = null;
      if (cache.get("token")) {
        cache.remove("token");
        cache.remove("refreshToken");
      } else {
        sessionCache.remove("token");
        sessionCache.remove("refreshToken");
      }
    },
    setMe: (state, action) => {
      state.role = action.payload && action.payload.role;
      state.per = action.payload && action.payload.per;
      state.authPer = action.payload && action.payload.authPer;
      state.company_cd = action.payload && action.payload.company_cd;
      state.branchOfficeCd = action.payload && action.payload.branchOfficeCd;
    },
    setFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    setFailResetPwd: (state, action) => {
      state.errorResetPwd = action.payload;
      state.loadingResetPassword = false;
    },
    setLoadingResetPwd: (state, action) => {
      state.loadingResetPassword = action.payload;
    }
  }
});

// Action creators are generated for each case reducer function
export const {
  setLoading,
  loginSuccess,
  logoutSuccess,
  setFail,
  setMe,
  setFailResetPwd,
  setLoadingResetPwd
} = slice.actions;

// Async action

export const loginAction = ({ id, login_password, remember }) =>
  async function (dispatch) {
    try {
      dispatch(setLoading(true));
      const response = await authApi.login({ id, login_password });
      dispatch(
        loginSuccess({
          token: response.access_token,
          refreshToken: response.refresh_token,
          remember
        })
      );
    } catch (error) {
      dispatch(setFail(error?.response?.data?.detail));
    }
  };

export const logoutAction = () => async (dispatch) => {
  dispatch(logoutSuccess());
};

export const setMeAction = () =>
  async function (dispatch) {
    try {
      dispatch(setLoading(true));
      const response = await userApi.getMe();
      dispatch(
        setMe({
          role: response.role,
          per: Object.keys(response.perms),
          authPer: response.perms,
          company_cd: response.company_cd,
          branchOfficeCd: response.branch_office_cd
        })
      );
    } catch (error) {
      dispatch(setFail(error?.response?.data?.detail));
    }
  };

export const resetPassword = (params) =>
  async function (dispatch) {
    dispatch(setLoadingResetPwd(true));
    await authApi.resetPass(params);
    dispatch(setFailResetPwd(null));
  };
// Get value from state
export const authStore = (state) => state.auth;

export default slice.reducer;
