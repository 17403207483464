import React from "react";
import PropTypes from "prop-types";

Divider.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string
};

function Divider({ height, width }) {
  return <div className="custom-divider" style={{ width: `${width}`, height: `${height}` }} />;
}

export default Divider;
