import { Col, Form, Row, Space } from "antd";
import { useForm } from "antd/lib/form/Form";
import { IconFilterDown } from "assets";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ROLE } from "common/constant";
import { saveFileWithExactName } from "common/utils/base64Helper";
import { formatFilterDateSlash } from "common/utils/dateHelper";

import { arrivalApi } from "services/arrival";

import { authStore } from "store/slices/auth";
import { setFilterArrivalAction } from "store/slices/listDataArrival";
import { listFilterArrivalStore } from "store/slices/listDataArrival";
import { getListArrivalStatusOpt, pulldownStore } from "store/slices/pulldown";
import { searchCardStore, setIsArrivalCardExpanded } from "store/slices/searchCard";

import "../../order/list/ListOrder.scss";
import TableListArrival from "../components/TableListArrival";

import Divider from "components/common/Divider";
import DatepickerFilter from "components/filter/DatepickerFilter";
import InputFilter from "components/filter/InputFilter";
import SelectFilter from "components/filter/SelectFilter";

function ListArrival() {
  const [form] = useForm();
  const dispatch = useDispatch();

  const [listArrival, setListArrival] = useState([]);
  const [totalArrival, setTotalArrival] = useState();
  const { filterArrivalObject } = useSelector(listFilterArrivalStore);
  const [page, setPage] = useState(1);
  const [sortParams, setSortParams] = useState([
    "last_deli_status",
    "-service_ymd",
    "last_customer_nm"
  ]);
  const { company_cd, role } = useSelector(authStore);
  const { isArrivalCardExpanded } = useSelector(searchCardStore);

  const initialValues = {
    ...filterArrivalObject,
    ...(filterArrivalObject.service_ymd_from
      ? { service_ymd_from: moment(filterArrivalObject.service_ymd_from) }
      : {}),
    ...(filterArrivalObject.service_ymd_to
      ? { service_ymd_to: moment(filterArrivalObject.service_ymd_to) }
      : {}),
    ...(filterArrivalObject.work_time_from
      ? { work_time_from: moment(filterArrivalObject.work_time_from) }
      : {}),
    ...(filterArrivalObject.work_time_to
      ? { work_time_to: moment(filterArrivalObject.work_time_to) }
      : {}),
  };

  const fetchlistArrival = async () => {
    const res = await arrivalApi.getListArrival({
      ...filterArrivalObject,
      page,
      company_cd,
      sort: sortParams.length > 0 ? sortParams : []
    });
    setListArrival(res?.items);
    setTotalArrival(res?.total);
  };

  const [showMoreFilter, setShowMoreFilter] = useState(false);
  const { listOptDeliveryStatus, listOptOffices } = useSelector(pulldownStore);

  const handleShowMore = () => {
    setShowMoreFilter(!showMoreFilter);
    dispatch(setIsArrivalCardExpanded(!showMoreFilter));
  };
  const disabledDate = (current) =>
    current && (current.diff(moment(), "day") < -90 || current.diff(moment(), "day") > 90);

  const handleDownloadSignImage = (service_instruct_no) => {
    arrivalApi.downloadSignImage(service_instruct_no).then((data) => saveFileWithExactName(data));
  };

  const handleFilter = (changedFields) => {
    if (changedFields[0].value) {
      if (["office_cd", "customer_div"].includes(changedFields[0].name[0])) {
        dispatch(
          setFilterArrivalAction({
            ...filterArrivalObject,
            [changedFields[0].name[0]]: changedFields[0].value,
          })
        );
      } else if (
        ["service_ymd_from", "service_ymd_to", "work_time_from", "work_time_to"].includes(
          changedFields[0].name[0]
        )
      ) {
        dispatch(
          setFilterArrivalAction({
            ...filterArrivalObject,
            [changedFields[0].name[0]]: formatFilterDateSlash(changedFields[0].value),
          })
        );
      } else {
        dispatch(
          setFilterArrivalAction({
            ...filterArrivalObject,
            [changedFields[0].name[0]]: changedFields[0].value,
          })
        );
      }
    } else {
      const cloneFilter = { ...filterArrivalObject };
      delete cloneFilter[changedFields[0].name[0]];
      dispatch(setFilterArrivalAction({ ...cloneFilter }));
    }
  };

  const handleSortOrders = (pagination, _, sorter) => {
    if (pagination.current === page) {
      if (sorter.length > 1) {
        setSortParams(
          sorter.map((item) => (item.order === "descend" ? `-${item.field}` : item.field))
        );
      } else if (sorter.column) {
        setSortParams([sorter.order === "descend" ? `-${sorter.field}` : sorter.field]);
      } else {
        setSortParams([]);
      }
    }
  };

  const handleChangePage = (currentPage) => {
    setPage(currentPage);
  };

  useEffect(() => {
    if (company_cd) {
      fetchlistArrival();
    }
  }, [company_cd, filterArrivalObject, sortParams, page]);

  useEffect(() => {
    dispatch(getListArrivalStatusOpt());
  }, []);

  useEffect(() => {
    if (role && role !== ROLE.ADMIN && listOptOffices.length > 0) {
      form.setFieldValue("office_cd", listOptOffices[0]?.value);
      dispatch(
        setFilterArrivalAction({
          ...filterArrivalObject,
          office_cd: listOptOffices[0].value,
        })
      );
    }
  }, [listOptOffices, role]);

  useEffect(() => {
    if (isArrivalCardExpanded)
      setShowMoreFilter(true);
  }, []);

  return (
    <div className="list-order-page bg-white">
      <Row className="header-order" align="middle" justify="space-between">
        <h1 className="fs-24 fw-700">納品状況一覧</h1>
      </Row>
      <Divider height="2px" />
      <Form
        autoComplete="off"
        className="filter-group"
        form={form}
        onFieldsChange={handleFilter}
        initialValues={initialValues}
      >
        <Row>
          <Col span={21}>
            <Row className="filter-group" gutter={[16, 12]}>
              <SelectFilter
                title="配達状況"
                name="last_deli_status"
                placeholder="お選びください"
                listOptions={listOptDeliveryStatus}
                width={220}
              />
              <InputFilter
                title="配達先"
                placeholder=""
                name="last_customer_nm"
                width={220}
                rules={[{ max: 400, message: "400文字以下で入力してください" }]}
              />
              <DatepickerFilter
                title="配達日"
                disabledDate={disabledDate}
                preName="service_ymd_from"
                sufName="service_ymd_to"
              />
              <InputFilter
                title="受注No."
                placeholder=""
                name="tls_order_no"
                width={220}
                rules={[{ max: 10, message: "10文字以下で入力してください" }]}
              />
            </Row>
          </Col>
          <Col span={3}>
            <Space
              className={`show-more-filter ${showMoreFilter && "rotate"}`}
              onClick={handleShowMore}
            >
              <div className="font-bold">
                {showMoreFilter ? "詳細条件を閉じる" : "詳細条件をすべて表示"}
              </div>
              <IconFilterDown />
            </Space>
          </Col>
        </Row>
        {showMoreFilter ? (
          <Row gutter={[16, 12]}>
            <InputFilter
              title="お客様管理No."
              placeholder=""
              name="client_manage_no"
              width={220}
              rules={[{ max: 10, message: "10文字以下で入力してください" }]}
            />
            <DatepickerFilter
              title="受領日"
              disabledDate={disabledDate}
              preName="work_time_from"
              sufName="work_time_to"
            />
            <InputFilter
              title="お客様"
              placeholder=""
              name="billing_addr_nm"
              width={220}
              rules={[{ max: 11, message: "11文字以下で入力してください" }]}
            />
            <SelectFilter
              title="タカスエ営業所"
              name="office_cd"
              placeholder="お選びください"
              listOptions={listOptOffices}
              width={220}
              disabled={role !== ROLE.ADMIN}
            />
          </Row>
        ) : null}
      </Form>
      <TableListArrival
        role={role}
        refetch={fetchlistArrival}
        page={page}
        dataSource={listArrival}
        totalRow={totalArrival}
        onSortOrders={handleSortOrders}
        onPageChange={handleChangePage}
        handleDownloadSignImage={handleDownloadSignImage}
      />
    </div>
  );
}

export default ListArrival;
