import { Button } from "antd";
import PropTypes from "prop-types";
import React from "react";

function FooterUpdateBase({ handleSubmitChanged }) {
  return (
    <div className="pa-40 bg-white flex-center">
      <div className="text-center">
        <div className="fs-20 fw-700 mb-24">内容の確認後、更新ボタンを押してください</div>
        <Button className="w-288" type="primary" size="large" onClick={handleSubmitChanged}>
          更新
        </Button>
      </div>
    </div>
  );
}

export default FooterUpdateBase;
FooterUpdateBase.propTypes = {
  handleSubmitChanged: PropTypes.func.isRequired
};
