const sessionCache = {
  set(key, value) {
    return sessionStorage.setItem(key, value);
  },
  get(key) {
    return sessionStorage.getItem(key);
  },

  setJson(key, valueJson) {
    return sessionStorage.setItem(key, JSON.stringify(valueJson));
  },
  getJson(key) {
    return sessionStorage.getItem(key) ? JSON.parse(sessionStorage.getItem(key) || "") : null;
  },

  remove(key) {
    return sessionStorage.removeItem(key);
  }
};

export default sessionCache;
