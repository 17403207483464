import Icon from "@ant-design/icons/lib/components/Icon";
import { Button } from "antd";
import { IconTrash } from "assets";
import PropTypes from "prop-types";
import React from "react";

import "./FooterOrder.scss";

FooterDetailOrder.propTypes = {
  editMode: PropTypes.bool,
  toggleEditMode: PropTypes.func,
  handleSubmit: PropTypes.func,
  handleRemove: PropTypes.func,
  isDone: PropTypes.bool
};

function FooterDetailOrder({ isDone, editMode, toggleEditMode, handleSubmit, handleRemove }) {
  return (
    <div className="footer-order bg-white">
      {isDone ? (
        <>
          <div className="hint">内容の確認後、変更ボタンを押してください</div>
          <Button onClick={handleSubmit} type="primary" className="btn-submit" size="large">
            変更
          </Button>
        </>
      ) : editMode ? (
        <>
          <div className="hint">内容の確認後、変更を押してください</div>
          <div>
            <Button onClick={toggleEditMode} className="btn-submit" size="large">
              キャンセル
            </Button>
            <Button onClick={handleSubmit} className="btn-submit" type="primary" size="large">
              変更
            </Button>
          </div>
        </>
      ) : (
        <>
          <div className="hint">内容を変更する場合は注文内容の変更を押してください</div>
          <div>
            <Button
              className="btn-submit"
              style={{ marginTop: "8px" }}
              size="large"
              danger
              icon={<Icon style={{ fontSize: "20px" }} component={IconTrash} />}
              onClick={handleRemove}
            >
              注文キャンセル
            </Button>
            <Button onClick={toggleEditMode} className="btn-submit" size="large">
              注文内容の変更
            </Button>
          </div>
        </>
      )}
    </div>
  );
}

export default FooterDetailOrder;
