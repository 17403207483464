import { Col, Space } from "antd";
import PropTypes from "prop-types";
import React from "react";

import DatePicker from "components/common/DatePicker";

DatepickerFilter.propTypes = {
  title: PropTypes.string,
  preName: PropTypes.string,
  sufName: PropTypes.string,
  disabledDate: PropTypes.func,
  disabled: PropTypes.bool,
  defaultValue: PropTypes.any
};

function DatepickerFilter({ title, preName, sufName, disabledDate, disabled, defaultValue }) {
  return (
    <Col className="filter-item">
      <Space align>
        <div>
          <div className="mb-5">{title}</div>
          <DatePicker
            name={preName}
            disabledDate={disabledDate}
            disabled={disabled}
            defaultValue={defaultValue}
          />
        </div>
        <div>
          <div className="mb-10">&#8203;</div>
          <Space>〜</Space>
        </div>
        <div>
          <div className="mb-5">&#8203;</div>
          <DatePicker
            name={sufName}
            disabledDate={disabledDate}
            disabled={disabled}
            defaultValue={defaultValue}
          />
        </div>
      </Space>
    </Col>
  );
}

export default DatepickerFilter;
