import React, { useEffect, useState } from "react";
import PropTypes, { func } from "prop-types";
import { Col, Row, Tooltip } from "antd";
import { IconHelp } from "assets";
import Tag from "components/common/Tag";
import InputSearch from "components/common/InputSearch";

FormInputSearch.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  helperText: PropTypes.string,
  optional: PropTypes.bool,
  fetchOptions: PropTypes.func,
  form: PropTypes.any,
  handleAfterSelect: func,
  rules: PropTypes.array,
  isInitValues: PropTypes.bool,
};

function FormInputSearch({
  label,
  name,
  form,
  placeholder,
  helperText,
  optional,
  fetchOptions,
  handleAfterSelect,
  rules,
  isInitValues
}) {
  const [value, setValue] = useState(form.getFieldValue(name));
  useEffect(() => {
    if (isInitValues) {
      setValue(form.getFieldValue(name));
    }
  }, [isInitValues]);

  return (
    <Row className="form-item form-input">
      <Col span={7} className="label">
        <h1 className="label">{label}</h1>
      </Col>
      <Col span={9}>
        <InputSearch
          form={form}
          name={name}
          setValue={setValue}
          value={value}
          placeholder={placeholder}
          fetchOptions={fetchOptions}
          handleAfterSelect={handleAfterSelect}
          rules={rules}
        />
        {optional && <Tag type="optional" />}
        {helperText && (
          <Tooltip
            placement="bottomLeft"
            title={helperText}
            overlayStyle={{ maxWidth: "max-content" }}
            className="icon-help"
          >
            <IconHelp />
          </Tooltip>
        )}
      </Col>
    </Row>
  );
}

export default FormInputSearch;
