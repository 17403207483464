import { Form, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { PATH_NAME, ROLE } from "common/constant";
import { DIV_AUTH_GROUP_CODE } from "common/constant/account";
import { trimObj } from "common/utils/objectHelper";

import { userApi } from "services/user";

import { clearDataAction, flowAccountStore, setFormDataAction } from "store/slices/flowAccount";

import {
  CreateAccountSuccess,
  FooterUpdate,
  FormHonshaAccount,
  InforAccount,
  RightUpdate
} from "../components";

import Divider from "components/common/Divider";

const UpdateHonshaAccount = () => {
  const { userId } = useParams();

  const [isFormConfirm, setIsFormConfirm] = useState(false);
  const [isRegisterSuccess, setIsRegisterSuccess] = useState(false);
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const { formData } = useSelector(flowAccountStore);
  const dispatch = useDispatch();

  const toggleConfirmForm = async () => {
    try {
      if (!isFormConfirm) {
        await form.validateFields();
        form.submit();
        setIsFormConfirm(true);
      } else {
        const { invoice_send_flg, delivery_req_send_flg, account_info_upd_send_flg, ...rest } =
          formData;
        const dataPost = {
          user: { ...rest, company_cd: 10, id: userId, use_user_div: ROLE.ADMIN, save_flg: "0" },
          user_mail_manage: {
            user_id: userId,
            account_info_upd_send_flg,
            invoice_send_flg,
            delivery_req_send_flg
          },
          auth_group_ids: [DIV_AUTH_GROUP_CODE.ADMIN]
        };
        await userApi
          .updateUser(dataPost, userId)
          .then((res) => {
            if (res) setIsRegisterSuccess(true);
          })
          .catch((e) => console.log(e));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSaveDraft = async () => {
    try {
      await form.validateFields();
      const { invoice_send_flg, delivery_req_send_flg, account_info_upd_send_flg, ...rest } =
        formData;
      const dataPost = {
        user: { ...rest, company_cd: 10, id: userId, use_user_div: ROLE.ADMIN },
        user_mail_manage: {
          user_id: userId,
          account_info_upd_send_flg,
          invoice_send_flg,
          delivery_req_send_flg
        },
        auth_group_ids: [DIV_AUTH_GROUP_CODE.ADMIN]
      };
      await userApi
        .updateUser(dataPost, userId)
        .then((res) => {
          if (res) {
            navigate(PATH_NAME.ACCOUNT_LIST);
          }
        })
        .catch((e) => console.log(e));
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmitForm = (params) => {
    const paramsTrimed = trimObj({ ...formData, ...params, use_user_div: ROLE.ADMIN });
    dispatch(setFormDataAction(paramsTrimed));
  };

  const fetchInforUser = async () => {
    let formData = {};
    await userApi
      .getUserById(userId)
      .then((res) => {
        const { id, ...rest } = res;
        formData = {
          user_id: id,
          ...rest
        };
      })
      .catch((e) => console.log(e));
    dispatch(setFormDataAction(formData));
    form.setFieldsValue(formData);
  };

  useEffect(() => {
    fetchInforUser();
    dispatch(clearDataAction());
    form.resetFields();
  }, []);
  return (
    <div className="create-customer-account-page">
      {isRegisterSuccess ? (
        <div className="content bg-white">
          <CreateAccountSuccess />
        </div>
      ) : (
        <>
          <div className="main-content bg-white">
            <h1 className="title">{isFormConfirm ? "登録内容確認" : "アカウント登録"}</h1>
            <Divider height="2px" />
            <Row justify="space-between" gutter={[100]} className="form-customer-account">
              {isFormConfirm ? (
                <InforAccount isDetail />
              ) : (
                <FormHonshaAccount
                  form={form}
                  handleSubmitForm={handleSubmitForm}
                  formData={formData}
                  editMode
                />
              )}
              <RightUpdate
                setIsFormConfirm={setIsFormConfirm}
                toggleFormDone={toggleConfirmForm}
                isFormConfirm={isFormConfirm}
                handleSaveDraft={handleSaveDraft}
              />
            </Row>
          </div>
          <FooterUpdate
            toggleFormDone={toggleConfirmForm}
            isFormConfirm={isFormConfirm}
            setIsFormConfirm={setIsFormConfirm}
            handleSaveDraft={handleSaveDraft}
          />
        </>
      )}
    </div>
  );
};

export default UpdateHonshaAccount;
