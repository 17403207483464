import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "antd";
import { useNavigate } from "react-router";
import { PWD_INPUT_VALIDATE_MSG, INCOME_MAIL_SETTING } from "common/constant/account";
import { trimObj } from "common/utils/objectHelper";
import { rulesCreateAccount, validateMessages } from "common/constant/rules";
import { userApi } from "services/user";
import { PATH_NAME } from "common/constant";
import Divider from "components/common/Divider";
import {
  FormInput,
  TitleDetail,
  FormCheckboxGroup,
  FormDetail,
  FormInputWithValidate
} from "components/form";
import "./UserUpdate.scss";

function UserUpdate() {
  const [inforUser, setInforUser] = useState();
  const [error, setError] = useState("");

  const [form] = Form.useForm();
  const navigate = useNavigate();

  const handleSubmitForm = async (params) => {
    const paramsTrimed = trimObj(params);
    const { invoice_send_flg, delivery_req_send_flg, account_info_upd_send_flg, ...rest } =
      paramsTrimed;
    const bodyPatch = {
      user: { ...rest, company_cd: 10, id: inforUser.id },
      user_mail_manage: {
        user_id: inforUser.id,
        account_info_upd_send_flg,
        invoice_send_flg,
        delivery_req_send_flg
      }
    };
    await userApi
      .patchMe(bodyPatch)
      .then((res) => {
        if (res) {
          navigate(PATH_NAME.USER_DETAIL);
        }
      })
      .catch((e) => {
        if (e.response.data.detail === "Incorrect old password") {
          setError("現在のパスワードが間違っています");
        }
      });
  };

  const triggerBtnSubmit = () => {
    form.submit();
  };

  useEffect(async () => {
    const response = await userApi.getMeSettings();
    setInforUser(response);
  }, []);

  useEffect(() => {
    form.resetFields();
  }, [inforUser]);

  const handleChangeValue = (changedValues) => {
    if ("old_login_password" in changedValues) {
      setError(false);
    }
  };

  return (
    <div className="user-update">
      <div className="account-page bg-white">
        <h1 className="big-title">アカウント登録</h1>
        <Divider height="2px" />
        <Row gutter={40} className="infor">
          <Col flex="auto">
            <Form
              validateMessages={validateMessages}
              initialValues={inforUser}
              form={form}
              onFinish={handleSubmitForm}
              onValuesChange={handleChangeValue}
            >
              <div>
                <TitleDetail title="アカウント情報" />
                <FormDetail label="ログインID" value={inforUser?.id} />
                <FormInput
                  width={320}
                  label="お名前"
                  name="user_nm"
                  placeholder="テキスト入力"
                  rules={rulesCreateAccount.user_nm}
                />
                <FormInput
                  width={320}
                  label="お名前（カナ）"
                  name="user_nm_kana"
                  placeholder="テキスト入力"
                  rules={rulesCreateAccount.user_nm_kana}
                />
                <FormInput
                  width={320}
                  label="電話番号"
                  name="tel"
                  placeholder="08011112222"
                  rules={rulesCreateAccount.tel}
                />
                <FormInput
                  width={320}
                  label="メールアドレス"
                  name="mail_addr"
                  placeholder="sample.gmail.com"
                  rules={rulesCreateAccount.mail_addr}
                />
                <FormInput
                  error={error}
                  password
                  width={320}
                  label="現在のパスワード"
                  name="old_login_password"
                  placeholder="英数字・記号の組み合わせ"
                  rules={rulesCreateAccount.old_login_password}
                />
                <FormInput
                  password
                  width={320}
                  label="新しいパスワード"
                  name="login_password"
                  placeholder="英数字・記号の組み合わせ"
                  rules={rulesCreateAccount.upt_login_password}
                />
                <FormInputWithValidate
                  password
                  validateMsg={PWD_INPUT_VALIDATE_MSG}
                  width={320}
                  label="新しいパスワード再確認"
                  name="login_password_confirm"
                  placeholder="英数字・記号の組み合わせ"
                  rules={rulesCreateAccount.upt_login_password_confirm}
                />
                <FormCheckboxGroup
                  label="受信メール設定"
                  groupName="email_setting"
                  listOptions={INCOME_MAIL_SETTING}
                  form={form}
                  isInitValues={inforUser}
                  noticeMsg="更新時にメールを受け取る場合はチェックを入れてください"
                />
              </div>
            </Form>
          </Col>
          <Col flex="280px">
            <div className="text-center">内容の確認後、</div>
            <div className="text-center">更新ボタンを押してください</div>
            <Row justify="center">
              <Button onClick={triggerBtnSubmit} size="large" type="primary" className="btn-act">
                更新
              </Button>
            </Row>
          </Col>
        </Row>
      </div>
      <div className="footer bg-white">
        <h1>内容の確認後、更新ボタンを押してください</h1>
        <Button onClick={triggerBtnSubmit} size="large" type="primary" className="btn-act">
          更新
        </Button>
      </div>
    </div>
  );
}

export default UserUpdate;
