import { Form, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { PATH_NAME, ROLE } from "common/constant";
import { DIV_AUTH_GROUP_CODE } from "common/constant/account";
import { isEmptyObj, trimObj } from "common/utils/objectHelper";

import { masterDataApi } from "services/masterData";
import { userApi } from "services/user";

import {
  flowAccountStore,
  setFormDataAction,
  setListOptOfficeOrderAction
} from "store/slices/flowAccount";

import {
  CreateAccountSuccess,
  FooterCreate,
  FormUnsoAccount,
  InforAccount,
  RightCreate
} from "../components";
import "./CreateCustomerAccount.scss";

import Divider from "components/common/Divider";

const CreateUnsoAccount = () => {
  const [isFormConfirm, setIsFormConfirm] = useState(false);
  const [isRegisterSuccess, setIsRegisterSuccess] = useState(false);
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const { formData, listOptOfficeOrder } = useSelector(flowAccountStore);
  const dispatch = useDispatch();

  const fetchListOffice = async () => {
    await masterDataApi
      .getOfficesByFlag("1")
      .then((response) => {
        const listTemp = response?.map((item) => ({
          label: item.office_nm,
          value: item?.office_cd
        }));
        dispatch(setListOptOfficeOrderAction(listTemp));
      })
      .catch((e) => console.log(e));
  };

  const toggleConfirmForm = async () => {
    try {
      if (!isFormConfirm) {
        await form.validateFields();
        form.submit();
        setIsFormConfirm(true);
      } else {
        const {
          user_id,
          invoice_send_flg,
          delivery_req_send_flg,
          account_info_upd_send_flg,
          office_order_cd,
          login_password,
          ...rest
        } = formData;
        const dataPost = {
          user: {
            ...rest,
            login_password: login_password || "",
            company_cd: 10,
            id: user_id,
            use_user_div: ROLE.SUB_ADMIN
          },
          user_mail_manage: {
            user_id,
            account_info_upd_send_flg,
            invoice_send_flg,
            delivery_req_send_flg
          },
          billing_addr_ele: {
            office_to_billing_cds: [office_order_cd],
            billing_addr_cds: ["00000000000"],
            billing_addr_nms: [null]
          },
          auth_group_ids: [DIV_AUTH_GROUP_CODE.SUB_ADMIN]
        };
        await userApi
          .createUser(dataPost)
          .then((res) => {
            if (res) setIsRegisterSuccess(true);
          })
          .catch((e) => console.log(e));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSaveDraft = async () => {
    const valueObj = isEmptyObj(form.getFieldsValue()) ? formData : form.getFieldsValue();
    const paramsTrimed = trimObj({ ...valueObj, use_user_div: ROLE.SUB_ADMIN });
    const {
      user_id,
      invoice_send_flg,
      delivery_req_send_flg,
      account_info_upd_send_flg,
      office_order_cd,
      login_password,
      ...rest
    } = paramsTrimed;
    const dataPost = {
      user: {
        ...rest,
        company_cd: 10,
        id: user_id,
        use_user_div: ROLE.ADMIN,
        save_flg: "1",
        login_password: login_password || ""
      },
      user_mail_manage: {
        user_id,
        account_info_upd_send_flg,
        invoice_send_flg,
        delivery_req_send_flg
      },
      billing_addr_ele: {
        office_to_billing_cds: ["0000"],
        billing_addr_cds: [office_order_cd],
        billing_addr_nms: [null]
      },
      auth_group_ids: [DIV_AUTH_GROUP_CODE.SUB_ADMIN]
    };
    await form
      .validateFields()
      .then(async () => {
        await userApi
          .createUser(dataPost)
          .then((res) => {
            if (res) {
              navigate(PATH_NAME.ACCOUNT_LIST);
            }
          })
          .catch((e) => console.log(e));
      })
      .catch((e) => {
        console.log("error", e);
      });
  };

  const handleSubmitForm = (params) => {
    const paramsTrimed = trimObj({ ...params, use_user_div: ROLE.SUB_ADMIN });
    dispatch(setFormDataAction(paramsTrimed));
  };

  useEffect(() => {
    form.setFieldsValue({
      account_info_upd_send_flg: "1",
      invoice_send_flg: "1",
      delivery_req_send_flg: "1"
    });
    fetchListOffice();
  }, []);

  return (
    <div className="create-customer-account-page">
      {isRegisterSuccess ? (
        <div className="content bg-white">
          <CreateAccountSuccess />
        </div>
      ) : (
        <>
          <div className="main-content bg-white">
            <h1 className="title">{isFormConfirm ? "登録内容確認" : "アカウント登録"}</h1>
            <Divider height="2px" />
            <Row justify="space-between" gutter={[100]} className="form-customer-account">
              {isFormConfirm ? (
                <InforAccount />
              ) : (
                <FormUnsoAccount
                  listOptOfficeOrder={listOptOfficeOrder}
                  form={form}
                  handleSubmitForm={handleSubmitForm}
                />
              )}
              <RightCreate
                toggleFormDone={toggleConfirmForm}
                isFormConfirm={isFormConfirm}
                setIsFormConfirm={setIsFormConfirm}
                handleSaveDraft={handleSaveDraft}
              />
            </Row>
          </div>
          <FooterCreate
            toggleFormDone={toggleConfirmForm}
            isFormConfirm={isFormConfirm}
            setIsFormConfirm={setIsFormConfirm}
            handleSaveDraft={handleSaveDraft}
          />
        </>
      )}
    </div>
  );
};

export default CreateUnsoAccount;
