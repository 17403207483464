import { DATE_FORMAT } from "common/constant";

import { splitAddress } from "./stringHelper";

export const genOrderFormData = (values, listPickupAddr, typeOrder) => {
  const formData = new FormData();
  for (const key in values) {
    switch (key) {
      case "files":
        if (values.files) {
          const ins = values.files?.length;
          for (let x = 0; x < ins; x++) {
            formData.append("files", values.files[x]);
          }
        }
        break;
      case "pickup_ymd":
      case "delivery_ymd":
        formData.append(key, values[key].format(DATE_FORMAT));
        break;
      case "delivery_addr1": {
        const [addrCode, addrName] = splitAddress(values[key]);
        formData.append("delivery_addr_area_cd", addrCode);
        formData.append("delivery_addr1", addrName);
        break;
      }
      case "pickup_addr1": {
        const [addrCode, addrName] = splitAddress(values[key]);
        formData.append("pickup_addr_area_cd", addrCode);
        formData.append("pickup_addr1", addrName);
        break;
      }
      case "quantity_div":
        values[key] && formData.append(key, values[key]);
        break;
      case "volume_div":
        values?.volume && formData.append(key, values[key]);
        break;
      case "packing_sepa":
      case "packing_pl":
      case "packing_lo":
      case "packing_variant":
        formData.append(key, values[key] || "0");
        break;
      default:
        if (values[key] != null) formData.append(key, String(values[key])?.trim());
        break;
    }
  }
  if (values?.pickup_addr_cd && values?.pickup_addr_cd !== "000000000") {
    formData.delete("pickup_addr1");
    formData.delete("pickup_addr2");
    formData.delete("pickup_addr3");
    formData.delete("pickup_addr_postcd");
    formData.delete("pickup_addr_tel");
    formData.delete("pickup_addr_nm");
    formData.delete("pickup_addr_area_cd");
    const inforPickup = listPickupAddr?.find(
      (item) => item.pickup_addr_cd === values.pickup_addr_cd
    );
    formData.append("pickup_addr_nm", inforPickup?.pickup_addr_nm);
  } else {
    formData.append("pickup_addr_cd", "000000000");
  }
  if (typeOrder === "chapter") {
    formData.append("quantity", "1");
    formData.append("order_div", "02");
    formData.delete("packing_sepa");
    formData.delete("packing_pl");
    formData.delete("packing_lo");
    formData.delete("packing_variant");
    formData.delete("stack_feasible_flg");
  } else {
    formData.append("order_div", "01");
  }
  return formData;
};
