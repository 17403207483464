import { ConfigProvider, Empty, Table } from "antd";
import PropTypes from "prop-types";
import React from "react";
import { Link, useNavigate } from "react-router-dom";

import { PATH_NAME } from "common/constant";
import { formatDate, formatDateOrder } from "common/utils/dateHelper";
import "common/utils/dateHelper";

import "./TableListOrder.scss";

import Tag from "components/common/Tag";

TableListOrder.propTypes = {
  dataSource: PropTypes.array,
  totalRow: PropTypes.number,
  numRow: PropTypes.number,
  setListRecordSelected: PropTypes.func,
  page: PropTypes.number,
  onChangePage: PropTypes.func,
  onSortOrders: PropTypes.func
};

function TableListOrder({
  dataSource,
  setListRecordSelected,
  onChangePage,
  totalRow,
  page,
  onSortOrders
}) {
  const navigate = useNavigate();

  // rowSelection object indicates the need for row selection
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setListRecordSelected(selectedRows);
      console.log(`selectedRowKeys: ${selectedRowKeys}`, "selectedRows: ", selectedRows);
    },
    getCheckboxProps: (record) => ({
      disabled: record?.order_state === "02", // Column configuration not to be checked
      name: record.name
    }),
    columnWidth: "40px"
  };
  const columns = [
    {
      title: "",
      width: 80,
      fixed: "left",
      render: (_, record) => (
        <a
          onClick={() => navigate(PATH_NAME.CREATE_ORDER, { state: { orderId: record?.order_no } })}
        >
          引用登録
        </a>
      )
    },
    {
      title: "ステータス",
      dataIndex: "order_state",
      width: 110,
      fixed: "left",
      render: (text) => <Tag type={text === "01" ? "un-fix" : "fix"} size="sm" />,
      sorter: { multiple: 1 }
    },
    {
      title: "受注No.",
      dataIndex: "order_no",
      width: 120,
      fixed: "left",
      render: (text, record) => <Link to={`/order/upd/${record?.order_no}`}>{text}</Link>,
      sorter: { multiple: 1 }
    },
    {
      title: "お客様",
      dataIndex: "billing_addr_nm",
      width: 160,
      sorter: { multiple: 1 }
    },
    {
      title: "お客様管理No.",
      dataIndex: "client_manage_no",
      width: 140,
      sorter: { multiple: 1 }
    },
    {
      title: "お客様担当者名",
      dataIndex: "user_nm",
      width: 140,
      sorter: { multiple: 1 }
    },
    {
      title: "集荷日",
      dataIndex: "pickup_ymd",
      width: 160,
      sorter: { multiple: 1 },
      render: (text) => formatDate(text)
    },
    {
      title: "集荷先",
      dataIndex: "pickup_addr_nm",
      width: 140,
      render: (text) => <div className="text-ellip">{text}</div>,
      sorter: { multiple: 1 }
    },
    {
      title: "配達日",
      dataIndex: "delivery_ymd",
      width: 160,
      sorter: { multiple: 1 },
      render: (text) => formatDate(text)
    },
    {
      title: "配達先",
      dataIndex: "delivery_addr_nm",
      width: 140,
      render: (text) => <div className="text-ellip">{text}</div>,
      sorter: { multiple: 1 }
    },
    {
      title: "重量",
      dataIndex: "weight",
      width: 110,
      sorter: { multiple: 1 },
      render: (text) => text && `${text}kg`
    },
    {
      title: "容積",
      dataIndex: "volume",
      width: 110,
      sorter: { multiple: 1 },
      render: (text, record) => text && `${text}${record?.volume_unit_nm}`
    },
    {
      title: "配送時間指定",
      dataIndex: "delivery_time_nm",
      width: 130,
      sorter: { multiple: 1 }
    },
    {
      title: "タカスエ営業所",
      dataIndex: "office_nm",
      width: 160,
      sorter: { multiple: 1 }
    },
    {
      title: "入力日時",
      dataIndex: "regi_datetime",
      width: 190,
      sorter: { multiple: 1 },
      render: (text) => formatDateOrder(text)
    },
    {
      title: "更新日時",
      dataIndex: "upd_datetime",
      width: 190,
      sorter: { multiple: 1 },
      render: (text) => formatDateOrder(text)
    }
  ];

  return (
    <div>
      <ConfigProvider
        renderEmpty={() => (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="適当な検索結果がありません" />
        )}
      >
        <Table
          className="table-list-order"
          title={() => (
            <div className="title">
              {dataSource.length > 0 && `全${totalRow}件中${dataSource.length}件を表示`}
            </div>
          )}
          rowSelection={{
            hideSelectAll: true,
            ...rowSelection
          }}
          columns={columns}
          dataSource={dataSource}
          rowKey={(record) => record.order_no}
          onChange={onSortOrders}
          pagination={{
            current: page,
            onChange: onChangePage,
            position: ["topRight"],
            defaultPageSize: 30,
            total: totalRow,
            showSizeChanger: false
          }}
          scroll={{ x: 400, y: "60vh" }}
        />
      </ConfigProvider>
    </div>
  );
}

export default TableListOrder;
