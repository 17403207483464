export const DATA_FLAG_ALL = {
  OFFICE_CD: ["0000"],
  BILLING_ADDR_CD: ["00000000000"],
  BILLING_ADDR_NM: null
};
export const INCOME_MAIL_SETTING = [
  {
    name: "account_info_upd_send_flg",
    label: "アカウント情報更新時"
  },
  {
    name: "invoice_send_flg",
    label: "請求書発行時"
  },
  {
    name: "delivery_req_send_flg",
    label: "配送依頼更新時"
  }
];
export const DIV_OFFICE_LOGIC = [
  {
    value: "all",
    label: "全営業所"
  },
  {
    value: "some",
    label: "営業所を選択"
  }
];
export const DIV_SERVICE = [
  {
    name: "main_office_div",
    label: "本社取引先コード"
  },
  {
    name: "transport_div",
    label: "運送システム請求先コード"
  }
];
export const DIV_AUTH_GROUP = [
  {
    name: "auth_group_invoice",
    label: "請求書PDF照会"
  },
  {
    name: "auth_group_order",
    label: "Tロットオーダー"
  }
];
export const DIV_AUTH_GROUP_CODE = {
  INVOICE: "030",
  ORDER: "020",
  ADMIN: "010",
  SUB_ADMIN: "011"
};
export const ID_INPUT_VALIDATE_MSG = [
  "5文字以上50文字以内で設定してください。",
  "未設定の場合はメールアドレスを入力して下さい。"
];
export const PWD_INPUT_VALIDATE_MSG = [
  "5文字以上50文字以内で設定してください。",
  "利用できる文字は半角英文字、半角数字、半角記号です。",
  "アルファベットの大文字、小文字、数字を必ず1文字以上使用してください。"
];
