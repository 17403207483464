import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Button, Col, Row, Space } from "antd";
import { IconFilterDown } from "assets";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { Link } from "react-router-dom";

import { TIME_FORMAT } from "common/constant";
import { ORDER_TYPE } from "common/constant/service";
import { formatDateTime } from "common/utils/dateHelper";

import ItemImgView from "./ItemImageView";
import ItemView from "./ItemView";
import "./ServiceCard.scss";

import Tag from "components/common/Tag";

const ServiceCard = ({ card, highlighted, setHighlighted }) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: card.round_num * 100 + card.order
  });

  const style = {
    transition,
    transform: CSS.Translate.toString(transform),
    opacity: isDragging ? 0.5 : 1,
    zIndex: isDragging ? 1000 : 1
  };

  const [showMore, setShowMore] = useState(false);

  const handleShowMore = () => {
    setShowMore(!showMore);
  };

  const handleClick = () => {
    setHighlighted(card.round_num * 100 + card.order);
  };

  const getTypeTag = (text) => {
    let type;
    switch (text) {
      case "01":
        type = "delivery-uncomplete";
        break;
      case "02":
        type = "delivery-complete";
        break;
      case "03":
        type = "delivery-return";
        break;
    }
    return type;
  };

  const genQuantityUnitString = (quantity, unit) => {
    if (quantity) {
      if (unit) return `${quantity} ${unit}`;
      else return quantity;
    } else {
      if (unit) return unit;
      else return null;
    }
  };

  const checkStackingFlg = (stacking_flg) => {
    if (stacking_flg === "1") return "可";
    if (stacking_flg === "0") return "不可";
    return null;
  };

  const isShowCustomer = card.isLoad
    ? [ORDER_TYPE.STEM, ORDER_TYPE.DISTRIBUTION, ORDER_TYPE.MANAGER].includes(card.order_type) &&
      card.org_pickup_addr_nm
    : [ORDER_TYPE.STEM, ORDER_TYPE.COLECTION, ORDER_TYPE.STEM_COLECT].includes(card.order_type) &&
      card.last_customer_nm;

  return (
    <div
      onClick={handleClick}
      {...listeners}
      {...attributes}
      style={style}
      ref={setNodeRef}
      className={`${
        highlighted ? "service-card-hightlight " : ""
      } pa-20 rounded-4 service-card mb-16`}
    >
      <Row className="fw-700">
        <Col span={12}>
          <Row>
            <div
              className={`num-icon dis-flex jc-center ai-center fz-14 mt-6 ${
                highlighted ? "white bg-primary" : "primary"
              }`}
            >
              {card.order}
            </div>
            <Space className="mx-10">
              {card.address} <span>({card.car_number}) </span>
            </Space>
          </Row>
        </Col>
        <Col span={12}>
          <Row className="jc-flex-end">
            <Tag
              size="sm"
              type={`${
                (card.isLoad && card.has_pickup_receipt_img) ||
                (!card.isLoad && card.has_consignee_receipt_img)
                  ? "receipt-active"
                  : "receipt"
              }`}
            />
            <Tag
              size="sm"
              type={`${
                (card.isLoad && card.has_pickup_sign_img) ||
                (!card.isLoad && card.has_consignee_sign_img)
                  ? "sign-active"
                  : "sign"
              }`}
            />
            <Tag
              size="sm"
              type={`${
                (card.isLoad && card.has_upd_pickup_base) ||
                (!card.isLoad && card.has_upd_consignee_base)
                  ? "updBase-active"
                  : "updBase"
              }`}
            />
          </Row>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={8}>
          <ItemView label="原票No." childrens={card?.slip_no} />
        </Col>
        <Col span={8}>
          <ItemView label="作業内容" childrens={card.isLoad ? "積み" : "降し"} />
        </Col>

        <Col span={8}>
          <ItemView
            label="配送状況"
            subLabel={card.updateTime ? `更新時刻 \u00A0${card.updateTime}` : ""}
            childrens={<Tag type={getTypeTag(card.deliveryStatus)} size="sm" />}
          />
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={16}>
          <ItemView
            label="拠点"
            childrens={
              <div>
                <Link to={`/base_upd/${card.base_cd}`}>{card.baseNm}</Link>
                {isShowCustomer && <div>({isShowCustomer})</div>}
              </div>
            }
          />
        </Col>
        <Col span={8}>
          <ItemView label="電話番号" childrens={card.delivery_tel} />
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={card.specifiedTime ? 18 : 24}>
          <ItemView label="運行指示" childrens={card.deliveryInstruct} />
        </Col>
        {card.specifiedTime && (
          <Col span={6}>
            <ItemView label="時間指定" childrens={card.specifiedTime} />
          </Col>
        )}
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <ItemView label="乗務員メモ" childrens={card.memo} />
        </Col>
      </Row>
      {showMore && (
        <>
          <Row gutter={[16, 16]}>
            <Col span={8}>
              <ItemView
                label="到着時間"
                childrens={formatDateTime(card.arrivalTime, TIME_FORMAT)}
              />
            </Col>
            <Col span={8}>
              <ItemView
                label="作業開始"
                childrens={formatDateTime(card.workStartTime, TIME_FORMAT)}
              />
            </Col>
            <Col span={8}>
              <ItemView
                label="作業終了"
                childrens={formatDateTime(card.workEndTime, TIME_FORMAT)}
              />
            </Col>
          </Row>
          <Row className="mt-10 fs-12 black-050 item-label infor_label">商品情報</Row>
          <Row gutter={[16, 16]}>
            <Col span={8}>
              <ItemView
                label="数量"
                childrens={genQuantityUnitString(card.quantity, card.quantity_unit_nm)}
              />
            </Col>
            <Col span={8}>
              <ItemView
                label="重量"
                childrens={genQuantityUnitString(card.weight, card.weight_unit_nm)}
              />
            </Col>
            <Col span={8}>
              <ItemView
                label="容積"
                childrens={genQuantityUnitString(card.volume, card.volume_unit_nm)}
              />
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={8}>
              <ItemView label="段積み" childrens={checkStackingFlg(card.stacking_flg)} />
            </Col>
            <Col span={8}>
              <ItemView
                label="荷姿"
                childrens={
                  <div className="dis-inline-flex">
                    <div>{card.pack_style_assorted === "1" ? "バラ" : ""}</div>
                    <div>
                      {card.pack_style_assorted === "1" &&
                      [card.pack_style_pl, card.pack_style_long, card.pack_style_variant].includes(
                        "1"
                      )
                        ? ", "
                        : ""}
                    </div>
                    <div>{card.pack_style_pl === "1" ? "PL" : ""}</div>
                    <div>
                      {card.pack_style_pl === "1" &&
                      [card.pack_style_long, card.pack_style_variant].includes("1")
                        ? ", "
                        : ""}
                    </div>
                    <div>{card.pack_style_long === "1" ? "長尺物" : ""}</div>
                    <div>
                      {card.pack_style_long === "1" && card.pack_style_variant === "1" ? ", " : ""}
                    </div>
                    <div>{card.pack_style_variant === "1" ? "異形物" : ""}</div>
                    {![
                      card.pack_style_assorted,
                      card.pack_style_pl,
                      card.pack_style_long,
                      card.pack_style_variant
                    ].includes("1") && <div>-</div>}
                  </div>
                }
              />
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <ItemView label="地点情報" childrens={card?.information} />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <ItemView
                label="電子サイン・受領書"
                childrens={
                  <Row gutter={[16, 16]}>
                    {card.isLoad
                      ? card.digital_sign_recept
                        ?.sort((a, b) => a.cert_div - b.cert_div)
                        .map(
                          (img) =>
                            img.img_path &&
                              img.load_unload_div === "01" && (
                              <ItemImgView
                                key={img.service_cert_id}
                                url={img.img_path}
                                id={img.service_cert_id}
                              />
                            )
                        )
                      : card.digital_sign_recept
                        ?.sort((a, b) => a.cert_div - b.cert_div)
                        .map(
                          (img) =>
                            img.img_path &&
                              img.load_unload_div === "02" && (
                              <ItemImgView
                                key={img.service_cert_id}
                                url={img.img_path}
                                id={img.service_cert_id}
                              />
                            )
                        )}
                  </Row>
                }
              />
            </Col>
          </Row>
        </>
      )}
      <Button type="text" className="w-full primary bg-safe-fill mt-12">
        <Space
          className={`show-more-detail ${showMore && "rotate"} cursor-pointer`}
          onClick={handleShowMore}
        >
          <div className="fw-500">{showMore ? "閉じる" : "さらに表示"}</div>
          <IconFilterDown />
        </Space>
      </Button>
    </div>
  );
};

export default ServiceCard;

ServiceCard.propTypes = {
  card: PropTypes.object,
  highlighted: PropTypes.bool,
  setHighlighted: PropTypes.func
};
