import { createSlice } from "@reduxjs/toolkit";

export const slice = createSlice({
  name: "listFilterBase",
  initialState: {
    filterBaseObject: {}
  },
  reducers: {
    setFilterBase: (state, action) => {
      state.filterBaseObject = action.payload;
    }
  }
});

// Action creators are generated for each case reducer function
export const { setFilterBase } = slice.actions;

// Async action

export const setFilterBaseAction = (object) =>
  function (dispatch) {
    dispatch(setFilterBase(object));
  };

// Get value from state

export const listFilterBaseStore = (state) => state.listFilterBase;

export default slice.reducer;
