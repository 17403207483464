import React from "react";
import { Button, Col } from "antd";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { flowAccountStore } from "store/slices/flowAccount";
import "./RightCreate.scss";

const RightCreate = ({
  currentPageFlag,
  toggleFormDone,
  isFormConfirm,
  setIsFormConfirm,
  setCurrentPageFlag,
  handleSaveDraft
}) => {
  const { formData } = useSelector(flowAccountStore);

  const redirectEdit = () => {
    setIsFormConfirm && setIsFormConfirm(false);
    setCurrentPageFlag && setCurrentPageFlag("form");
  };

  const handleBack = () => {
    const haveClientPage = formData["main_office_div"] === "1";
    switch (currentPageFlag) {
      case "trans": {
        if (haveClientPage) setCurrentPageFlag("client");
        else setCurrentPageFlag("form");
        break;
      }
      case "client": {
        setCurrentPageFlag("form");
        break;
      }
      default:
        break;
    }
  };

  return (
    <Col span={6}>
      <div className="right-customer-account">
        <p>{isFormConfirm ? "内容の確認後" : "内容の入力後"}</p>
        <p>{isFormConfirm ? "登録ボタンを押してください" : "次へ進んでください。"}</p>
        <div>
          <Button onClick={toggleFormDone} className="btn-submit" type="primary" size="large">
            {isFormConfirm ? "登録" : "次へ"}
          </Button>
        </div>

        <Button onClick={handleSaveDraft} className="btn-submit" size="large">
          下書き保存
        </Button>
        {isFormConfirm ? (
          <Button onClick={redirectEdit} type="link">
            内容を修正する
          </Button>
        ) : (
          currentPageFlag !== "form" &&
          currentPageFlag && (
            <Button onClick={handleBack} type="link">
              前のページへ移動する
            </Button>
          )
        )}
      </div>
    </Col>
  );
};
RightCreate.propTypes = {
  currentPageFlag: PropTypes.string,
  toggleFormDone: PropTypes.func,
  isFormConfirm: PropTypes.bool,
  setIsFormConfirm: PropTypes.func,
  setCurrentPageFlag: PropTypes.func,
  handleSaveDraft: PropTypes.func
};
export default RightCreate;
