import { IconNameLogo } from "assets";
import React, { useEffect } from "react";
import { useNavigate } from "react-router";

import { userApi } from "services/user";

import "./Maintenance.scss";

const Maintenance = () => {
  const navigate = useNavigate();
  const fetchInfo = async () => {
    try {
      await userApi.getMe();
      navigate("/");
    } catch (error) {
      if (error?.response?.status !== 503) {
        navigate("/");
      }
    }
  };
  useEffect(() => {
    fetchInfo();
  }, []);
  return (
    <div className="maintain-page">
      <IconNameLogo />
      <h2 className="name-logo">Takasue Logistics Service</h2>
      <h1 className="title">ただいまメンテナンス中です</h1>
      <h2 className="sub-title">【通常開局時間】</h2>
      <h3 className="maintain-time">8:00～21:00</h3>
      <p>いつもご利用いただき、誠にありがとうございます。</p>
      <p>メンテナンス終了までしばらくお待ち下さい。</p>
      <div className="bottom-bar">
        <div className="policy">プライバシーポリシー</div>
        <div className="handle-info">個人情報取扱</div>
      </div>
    </div>
  );
};

export default Maintenance;
