import moment from "moment";

import { getByteLength } from "common/utils/stringHelper";

import { masterDataApi } from "services/masterData";
import { userApi } from "services/user";

import { DATE_FORMAT } from ".";

export const REGEX = {
  NUMBER: new RegExp(/^[0-9]+$/),
  TEL: new RegExp(/^[0-9-]+$/),
  TEXT_WITHOUT_JAPANESE: new RegExp(/^[a-zA-Z0-9!@#\\$%\\^\\&*\\)\\(+=._-]+$/g),
  LAT: new RegExp(/^(-?[1-8]?\d(?:\.\d{1,14})?|90(?:\.0{1,14})?)$/),
  LNG: new RegExp(/^(-?(?:1[0-7]|[1-9])?\d(?:\.\d{1,14})?|180(?:\.0{1,14})?)$/),
  SIMPLE_EMAIL: new RegExp(/\S+@\S+\.\S+/)
};

const MSG_REQUIRE = {
  office_cd: "タカスエ営業所",
  billing_addr_cd: "お客様",
  pickup_addr_cd: "集荷先名称",
  pickup_addr_nm: "集荷先名称",
  pickup_addr1: "住所1",
  delivery_addr_nm: "配達先名称",
  delivery_addr1: "住所1",
  delivery_addr2: "住所2",
  delivery_time_div: "配達時間指定",
  delivery_vehicle_type: "入場車種制限",
  stack_feasible_flg: "段積",
  pickup_ymd: "集荷日",
  delivery_addr_tel: "電話番号",
  delivery_addr_postcd: "郵便番号",
  delivery_ymd: "配達日",
  user_id: "ログインID",
  user_nm: "ユーザー名",
  user_nm_kana: "ユーザー名（フリガナ）",
  tel: "電話番号",
  mail_addr: "メールアドレス",
  login_password: "パスワード",
  login_password_confirm: "パスワード（確認）",
  sale_office_1: "営業所1",
  office_order_cd: "運送取引営業所",
  quantity_div_chapter: "車両"
};

export const rulesOrder = {
  office_cd: [{ required: true, message: `${MSG_REQUIRE.office_cd}は必須入力です` }],
  billing_addr_cd: [{ required: true, message: `${MSG_REQUIRE.billing_addr_cd}は必須入力です` }],
  pickup_addr_cd: [
    {
      required: true,
      message: "集荷先情報をプルダウンから選択、もしくは住所の入力により設定して下さい"
    }
  ],
  pickup_addr_nm: [
    { required: true, message: `${MSG_REQUIRE.delivery_addr_nm}は必須入力です` },
    () => ({
      validator(_, value) {
        if (value && getByteLength(value) > 30) {
          return Promise.reject(new Error("配達先名称は30バイト以内で入力して下さい。"));
        }
        return Promise.resolve();
      }
    })
  ],
  pickup_addr1: [
    { required: true, message: `${MSG_REQUIRE.delivery_addr1}は必須入力です` },
    () => ({
      validator(_, value) {
        if (value && getByteLength(value) > 30) {
          return Promise.reject(new Error("住所は30バイト以内で入力して下さい。"));
        }
        return Promise.resolve();
      }
    })
  ],
  pickup_ymd: [
    { required: true, message: `${MSG_REQUIRE.pickup_ymd}は必須入力です` },
    ({ getFieldValue }) => ({
      async validator(_, value) {
        if (value) {
          const pickup_ymd = value.format(DATE_FORMAT);
          const office_cd = getFieldValue("office_cd");
          await masterDataApi.checkHoliday({ pickup_ymd, office_cd }).then((res) => {
            if (!res.success) {
              return Promise.reject(new Error(res.msg));
            }
          });

          const today = moment();
          if (
            -90 > moment(pickup_ymd).diff(today, "days", true) ||
            moment(pickup_ymd).diff(today, "days", true) > 90
          ) {
            return Promise.reject(new Error("集荷日付は本日から前後90日以内で指定してください。"));
          }
        }
        return Promise.resolve();
      }
    })
  ],
  delivery_addr_nm: [
    { required: true, message: `${MSG_REQUIRE.delivery_addr_nm}は必須入力です` },
    () => ({
      validator(_, value) {
        if (value && getByteLength(value) > 30) {
          return Promise.reject(new Error("配達先名称は30バイト以内で入力して下さい。"));
        }
        return Promise.resolve();
      }
    })
  ],
  delivery_addr_tel: [
    { required: true, message: `${MSG_REQUIRE.delivery_addr_tel}は必須入力です` },
    { message: "利用できない文字が含まれています", pattern: REGEX.TEL },
    { max: 13 }
  ],
  delivery_addr_postcd: [
    { required: true, message: `${MSG_REQUIRE.delivery_addr_postcd}は必須入力です` },
    { message: "利用できない文字が含まれています", pattern: REGEX.NUMBER }
  ],
  delivery_addr1: [
    { required: true, message: `${MSG_REQUIRE.delivery_addr1}は必須入力です` },
    () => ({
      validator(_, value) {
        if (value && getByteLength(value) > 30) {
          return Promise.reject(new Error("住所は30バイト以内で入力して下さい。"));
        }
        return Promise.resolve();
      }
    })
  ],
  delivery_addr2: [
    () => ({
      validator(_, value) {
        if (value && getByteLength(value) > 30) {
          return Promise.reject(new Error("住所は30バイト以内で入力して下さい。"));
        }
        return Promise.resolve();
      }
    })
  ],
  delivery_addr3: [
    () => ({
      validator(_, value) {
        if (value && getByteLength(value) > 30) {
          return Promise.reject(new Error("住所は30バイト以内で入力して下さい。"));
        }
        return Promise.resolve();
      }
    })
  ],
  delivery_ymd: [
    { required: true, message: `${MSG_REQUIRE.delivery_ymd}は必須入力です` },
    ({ getFieldValue }) => ({
      validator(_, value) {
        const pickup_ymd =
          getFieldValue("pickup_ymd") && moment(getFieldValue("pickup_ymd")).format("YYYY/MM/DD");
        const deliver_ymd = value && moment(value).format("YYYY/MM/DD");
        const diffDate =
          pickup_ymd && deliver_ymd && moment(pickup_ymd).diff(moment(deliver_ymd), "days", true);
        if (diffDate > 0) {
          return Promise.reject(new Error("配達日付は集荷日付以降の日付を入力して下さい"));
        }

        const today = moment();
        if (
          -90 > moment(deliver_ymd).diff(today, "days", true) ||
          moment(deliver_ymd).diff(today, "days", true) > 90
        ) {
          return Promise.reject(new Error("配達日付は本日から前後90日以内で指定してください。"));
        }

        return Promise.resolve();
      }
    })
  ],
  delivery_time_div: [
    { required: true, message: `${MSG_REQUIRE.delivery_time_div}は必須入力です` }
  ],
  delivery_vehicle_type: [
    { required: true, message: `${MSG_REQUIRE.delivery_vehicle_type}は必須入力です` }
  ],
  quantity_div_chapter: [
    { required: true, message: `${MSG_REQUIRE.quantity_div_chapter}は必須入力です` }
  ],
  quantity_div: [
    ({ getFieldValue }) => ({
      validator(_, value) {
        if (getFieldValue("quantity") && !value) {
          return Promise.reject(new Error("単位を入力して下さい"));
        }
        return Promise.resolve();
      }
    })
  ],
  quantity: [
    ({ getFieldValue }) => ({
      validator(_, value) {
        if (getFieldValue("volume") || getFieldValue("weight") || value) {
          return Promise.resolve();
        }
        return Promise.reject(new Error(""));
      }
    }),
    {
      pattern: /^[\d]{0,5}$/,
      message: "5 文字以下で入力してください"
    },
    { message: "数量の入力が正しくありません", pattern: REGEX.NUMBER }
  ],
  volume_div: [
    ({ getFieldValue }) => ({
      validator(_, value) {
        if (getFieldValue("volume") && !value) {
          return Promise.reject(new Error("単位を入力して下さい"));
        }
        return Promise.resolve();
      }
    })
  ],
  volume: [
    ({ getFieldValue }) => ({
      validator(_, value) {
        if (getFieldValue("quantity") || getFieldValue("weight") || value) {
          return Promise.resolve();
        }
        return Promise.reject(new Error(""));
      }
    }),
    { message: "容積の入力が正しくありません", pattern: new RegExp(/^\d{1,5}(\.\d{1,3})?$/) }
  ],
  volumeChapter: [
    { message: "容積の入力が正しくありません", pattern: new RegExp(/^\d{1,5}(\.\d{1,3})?$/) }
  ],
  weight: [
    ({ getFieldValue }) => ({
      validator(_, value) {
        if (getFieldValue("quantity") || getFieldValue("volume") || value) {
          return Promise.resolve();
        }
        return Promise.reject(new Error(""));
      }
    }),
    { message: "重量の入力が正しくありません", pattern: new RegExp(/^\d{1,5}(\.\d{1,3})?$/) }
  ],
  weightChapter: [
    { message: "重量の入力が正しくありません", pattern: new RegExp(/^\d{1,5}(\.\d{1,3})?$/) }
  ],
  stack_feasible_flg: [
    { required: true, message: `${MSG_REQUIRE.stack_feasible_flg}は必須入力です` }
  ],
  packing: [
    ({ getFieldValue }) => ({
      validator() {
        if (
          getFieldValue("packing_sepa") === "1" ||
          getFieldValue("packing_pl") === "1" ||
          getFieldValue("packing_lo") === "1" ||
          getFieldValue("packing_variant") === "1"
        ) {
          return Promise.resolve();
        }
        return Promise.reject(new Error("荷姿を最低1つ選択して下さい"));
      }
    })
  ],
  inforItem: [
    ({ getFieldValue }) => ({
      validator() {
        if (getFieldValue("quantity") || getFieldValue("volume") || getFieldValue("weight")) {
          return Promise.resolve();
        }
        return Promise.reject(new Error("数量、容積、重量　のいずれかの入力は必須です"));
      }
    })
  ],
  client_manage_no: [
    { max: 12 },
    { message: "利用できない文字が含まれています", pattern: REGEX.TEXT_WITHOUT_JAPANESE }
  ],
  note_takasue: [{ max: 1000 }],
  note_client: [{ max: 1000 }]
};

export const rulesCreateAccount = {
  user_id: [
    { required: true, message: `${MSG_REQUIRE.user_id}は必須入力です` },
    { min: 5, message: "5文字以上50文字以内で入力してください" },
    { max: 50, message: "5文字以上50文字以内で入力してください" },
    { message: "利用できない文字が含まれています", pattern: REGEX.TEXT_WITHOUT_JAPANESE },
    () => ({
      async validator(_, value) {
        const res = await userApi.checkExistUser(value);
        if (!res.success) return Promise.reject(new Error("このIDは既に登録されています"));
        else return Promise.resolve();
      },
      validateTrigger: "onBlur"
    })
  ],
  user_nm: [{ max: 200 }, { required: true, message: `${MSG_REQUIRE.user_nm}は必須入力です` }],
  user_nm_kana: [
    { max: 200 },
    { required: true, message: `${MSG_REQUIRE.user_nm_kana}は必須入力です` }
  ],
  tel: [
    { max: 13 },
    { required: true, message: `${MSG_REQUIRE.tel}は必須入力です` },
    { pattern: REGEX.TEL, message: "利用できない文字が含まれています" }
  ],
  mail_addr: [
    { max: 256 },
    { required: true, message: `${MSG_REQUIRE.mail_addr}は必須入力です` },
    { type: "email", message: "利用できない文字が含まれています" }
  ],
  login_password: [
    { min: 5, message: "5文字以上50文字以内で入力してください" },
    { max: 50, message: "5文字以上50文字以内で入力してください" }
  ],
  login_password_confirm: [
    ({ getFieldValue }) => ({
      validator(_, value) {
        const loginPass = getFieldValue("login_password");
        if (loginPass === value || (!loginPass && !value)) {
          return Promise.resolve();
        }
        return Promise.reject(new Error("再確認パスワードが一致しません"));
      }
    })
  ],
  sale_office_1: [{ required: true, message: `${MSG_REQUIRE.sale_office_1}は必須入力です` }],
  office_order_cd: [{ required: true, message: `${MSG_REQUIRE.office_order_cd}は必須入力です` }],
  auth_group: [
    ({ getFieldValue }) => ({
      validator() {
        if (
          getFieldValue("auth_group_invoice") === "1" ||
          getFieldValue("auth_group_order") === "1"
        ) {
          return Promise.resolve();
        }
        return Promise.reject(new Error("利用サービスを選択して下さい"));
      }
    })
  ],
  service_div: [
    ({ getFieldValue }) => ({
      validator() {
        const authOrder = Number(getFieldValue("auth_group_order"));
        const trans = Number(getFieldValue("transport_div"));
        if (authOrder === 1 && trans === 0) {
          return Promise.reject(
            new Error("請求先コード識別の「運送システム請求先」はチェックが必須です")
          );
        }
        if (getFieldValue("main_office_div") === "1" || getFieldValue("transport_div") === "1") {
          return Promise.resolve();
        }
        return Promise.reject(new Error("請求先コード識別を選択して下さい"));
      }
    })
  ],
  old_login_password: [
    ({ getFieldValue }) => ({
      validator(_, value) {
        if (
          (getFieldValue("login_password") || getFieldValue("login_password_confirm")) &&
          !value
        ) {
          return Promise.reject(new Error(`${MSG_REQUIRE.login_password}は必須入力です`));
        }
        return Promise.resolve();
      }
    })
  ],
  upt_login_password: [
    ({ getFieldValue }) => ({
      validator(_, value) {
        if (
          (getFieldValue("old_login_password") || getFieldValue("login_password_confirm")) &&
          !value
        ) {
          return Promise.reject(new Error(`${MSG_REQUIRE.login_password}は必須入力です`));
        }
        return Promise.resolve();
      }
    })
  ],
  upt_login_password_confirm: [
    ({ getFieldValue }) => ({
      validator(_, value) {
        if ((getFieldValue("login_password") || getFieldValue("old_login_password")) && !value) {
          return Promise.reject(new Error(`${MSG_REQUIRE.login_password_confirm}は必須入力です`));
        } else if (getFieldValue("login_password") !== value) {
          return Promise.reject(new Error("再確認パスワードが一致しません"));
        }
        return Promise.resolve();
      }
    })
  ]
};

export const validateMessages = {
  required: "この項目は必須入力です",
  string: {
    min: "${min} 文字以上で入力してください",
    max: "${max} 文字以下で入力してください",
    range: "${min} 文字以上${max} 文字以下で入力してください"
  }
};

export const rulesDetailBase = {
  car_limit: [
    {
      max: 50,
      message: "50文字以下で入力してください"
    }
  ],
  information: [
    {
      max: 1000,
      message: "1000文字以下で入力してください"
    }
  ]
};

export const rulesListService = {
  max_length_30: [
    {
      max: 30,
      message: "30文字以下で入力してください"
    }
  ]
};
