import { IconPaper, IconPaperActive, IconSign, IconSignActive } from "assets";
import PropTypes from "prop-types";
import React from "react";

Tag.propTypes = {
  size: PropTypes.oneOf(["sm", "lg"]),
  type: PropTypes.oneOf([
    "require",
    "optional",
    "fix",
    "un-fix",
    "complete",
    "un-complete",
    "invoice-pending",
    "invoice-complete",
    "delivery-complete",
    "delivery-uncomplete",
    "delivery-return",
    "service-complete",
    "service-running",
    "service-pending",
    "sign-active",
    "sign",
    "receipt-active",
    "receipt",
    "updBase-active",
    "updBase"
  ]),
  onClick: PropTypes.func
};

const labelTag = {
  require: "必須",
  optional: "任意",
  fix: "確定",
  "un-fix": "仮受注",
  "un-complete": "途中保存",
  complete: "登録済",
  "invoice-pending": "未DL",
  "invoice-complete": "DL済",
  "delivery-complete": "完了",
  "delivery-uncomplete": "未完了",
  "delivery-return": "キャンセル",
  "service-complete": "運行完了",
  "service-running": "運行中",
  "service-pending": "運行前",
  "sign-active": (
    <>
      <IconSignActive /> 電子サイン
    </>
  ),
  sign: (
    <>
      <IconSign /> 電子サイン
    </>
  ),
  "receipt-active": (
    <>
      <IconPaperActive /> 受領書
    </>
  ),
  receipt: (
    <>
      <IconPaper /> 受領書
    </>
  ),
  "updBase-active": (
    <>
      <IconPaperActive /> カルテ更新
    </>
  ),
  updBase: (
    <>
      <IconPaper /> カルテ更新
    </>
  )
};

function Tag({ size, type, onClick = () => {} }) {
  return (
    <div onClick={onClick} className={`custom-tag tag-${type} tag-${size}`}>
      {labelTag[type]}
    </div>
  );
}

export default Tag;
