export const DIV_ID = {
  STATUS: "004",
  DELIVERY_TIME: "005",
  DELIVERY_VEHICLE_TYPE: "006",
  UNIT_QUANTITY: "007",
  UNIT_VOLUME: "008"
};

export const SERVICE_YMD_DIV = {
  YESTERDAY: "00",
  TODAY: "01",
  TOMORROW: "02",
  FROM_T0: "03"
};

export const WORK_DIV = {
  BUSSINESS_START: "01",
  BUSSINESS_END: "02",
  OPE_START: "11",
  OPE_END: "12",
  ARRIVAL: "20",
  COL_ARRIVAL: "21",
  DELI_ARRIVAL: "22",
  LOAD_START: "31",
  LOAD_COMPLETE: "32",
  UNLOAD_START: "41",
  UNLOAD_COMPLETE: "42",
  START_STOP: "51",
  END_STOP: "52",
  TAKE_BACK: "60"
};

export const ORDER_TYPE = {
  COLECTION: "集荷",
  STEM: "幹線",
  DISTRIBUTION: "配達",
  STEM_COLECT: "集幹",
  MANAGER: "幹配",
  COLECT_AND_DELI: "集配",
  COLECT_AND_DIS: "集幹配"
};
