import api from "./baseApi";

export const authApi = {
  login(params) {
    return api.post("/login", params);
  },
  resetPass(params) {
    return api.post("/reset_password", params);
  }
};
