import { Col, Row, Switch } from "antd";
import { IconTruckRed } from "assets";
import { IconOperationUser } from "assets";
import { IconTruckBlack } from "assets";
import PropTypes from "prop-types";
import React from "react";
import { useNavigate } from "react-router-dom";

import ItemView from "pages/service/components/ItemView";

import Divider from "components/common/Divider";

const OperationServiceCard = ({ data, isActive, onChangeActive, onToggleCheck, check }) => {
  const navigate = useNavigate();

  const changeActive = () => {
    onChangeActive(data);
  };

  const toggleCheck = (e) => {
    e.stopPropagation();
    onToggleCheck(data);
  };

  return (
    <div
      id={data.service_no}
      onClick={changeActive}
      className={`pa-16 service-operation-card  ${
        isActive ? "service-operation-card-active" : "pointer"
      }`}
    >
      <Row justify="space-between">
        <Col>
          <Row
            className={`fw-700 fs-24 flex-align mr-10 ${isActive ? "error" : "primary"}  pointer`}
          >
            <div className="mt-4 mr-6">
              <IconTruckRed className={`${isActive ? "fill-error" : "fill-primary"} `} />
            </div>
            <div onClick={() => { navigate(`/service_detail/${data.service_no}`); }}>
              {data.service_no}
            </div>
          </Row>
        </Col>
        <Col className="dis-flex flex-column">
          <span className="text-sw fw-400 fs-11 text-label">ルート表示</span>
          <Switch
            checked={check && isActive}
            onChange={(_, e) => {
              toggleCheck(e);
            }}
            className="w-40"
          />
        </Col>
      </Row>
      <Row className="mb-8">
        <Col span={12}>{data.company_nm}</Col>
        <Col span={12}>{data.office_nm}</Col>
      </Row>
      <Divider height="2px"></Divider>
      <Row className="py-6">
        <Col span={12}><IconOperationUser className="mr-6" /> {data.driver_nm}</Col>
        <Col span={12}><IconTruckBlack className="mr-6" /> {data.car_number}</Col>
      </Row>
      <Col className={`my-6 ${isActive ? "bg-white" : "bg-safe-fill"}   rounded-4 pa-16`}>
        <ItemView
          label="残り集配件数"
          childrens={`${data.unfinished_trip}/${data.total_trip}件`}
          hideBorder
        />
        <ItemView
          label="次の配送先"
          childrens={data.next_delivery_spot?.next_delivery_nm}
          hideBorder
        />
      </Col>
    </div>
  );
};

OperationServiceCard.propTypes = {
  data: PropTypes.object,
  isActive: PropTypes.bool,
  onChangeActive: PropTypes.func,
  onToggleCheck: PropTypes.func,
  check: PropTypes.bool
};

export default OperationServiceCard;
