import { Button, Table } from "antd";
import React from "react";
import PropTypes from "prop-types";
const TableListPartners = ({
  dataSource,
  totalRow,
  partners,
  setPartners,
  setListPartners,
  isShipPartner,
  onSortOrders,
  onChangePage
}) => {
  const handleAddPartner = (record) => {
    setPartners([...partners, record]);
    setListPartners(dataSource.filter((partner) => partner.id !== record.id));
  };
  const columns = [
    {
      title: "",
      width: 120,
      dataIndex: "add",
      render: (text, record) => (
        <Button
          onClick={() => {
            handleAddPartner(record);
          }}
          type="link"
        >
          請求先に追加
        </Button>
      )
    },
    {
      title: isShipPartner ? "請求先コード" : "取引先コード",
      width: 120,
      dataIndex: "id",
      sorter: {
        multiple: 1
      }
    },
    {
      title: isShipPartner ? "請求先名称" : "取引先名称",
      width: 160,
      dataIndex: "name",
      sorter: {
        multiple: 1
      }
    }
  ];
  return (
    <div className="table-list-partners">
      <Table
        columns={columns}
        dataSource={dataSource}
        pagination={{
          position: ["botRight"],
          defaultPageSize: 30,
          total: totalRow,
          showSizeChanger: false,
          onChange: onChangePage
        }}
        scroll={{ x: 400, y: 250 }}
        onChange={onSortOrders}
      />
    </div>
  );
};
TableListPartners.propTypes = {
  dataSource: PropTypes.array,
  totalRow: PropTypes.number,
  partners: PropTypes.array,
  setPartners: PropTypes.func,
  listPartners: PropTypes.array,
  setListPartners: PropTypes.func,
  isShipPartner: PropTypes.bool,
  onSortOrders: PropTypes.func,
  onChangePage: PropTypes.func,
  dataIndexes: PropTypes.object
};
export default TableListPartners;
