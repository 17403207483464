import Icon from "@ant-design/icons/lib/components/Icon";
import { Form, Select as SelectAntd } from "antd";
import { IconArrowDown } from "assets";
import PropTypes from "prop-types";
import React from "react";

Select.propTypes = {
  listOptions: PropTypes.array,
  placeholder: PropTypes.string,
  showSearch: PropTypes.bool,
  style: PropTypes.object,
  optionFilterProp: PropTypes.string,
  value: PropTypes.any,
  setValue: PropTypes.func,
  disabled: PropTypes.bool,
  handleSearch: PropTypes.func,
  name: PropTypes.string,
  rules: PropTypes.any,
  allowClear: PropTypes.bool,
  defaultValue: PropTypes.any
};

function Select({
  listOptions,
  placeholder,
  showSearch = false,
  style,
  optionFilterProp,
  value,
  setValue,
  disabled,
  handleSearch,
  name,
  rules,
  allowClear = true,
  defaultValue
}) {
  const handleOnChange = (valueSelected) => {
    if (setValue) setValue(valueSelected);
  };

  const handleOnSearch = (valueSearch) => {
    if (handleSearch) handleSearch(valueSearch);
  };

  return (
    <Form.Item rules={rules} name={name}>
      <SelectAntd
        allowClear={allowClear}
        suffixIcon={<Icon component={IconArrowDown} />}
        disabled={disabled}
        style={style}
        placeholder={placeholder}
        optionFilterProp={optionFilterProp}
        value={value}
        onChange={handleOnChange}
        // search
        showSearch={showSearch}
        onSearch={showSearch && handleOnSearch}
        filterOption={(input, option) =>
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
          option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        defaultValue={defaultValue}
      >
        {listOptions?.map((item) => (
          <SelectAntd.Option key={item?.value} value={item?.value}>
            {item?.label}
          </SelectAntd.Option>
        ))}
      </SelectAntd>
    </Form.Item>
  );
}

export default Select;
