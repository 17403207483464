import api from "./baseApi";

export const serviceApi = {
  getServiceStatus() {
    return api.get("/div/service-status");
  },
  getListService(params) {
    return api.get("/services/", { params });
  },
  getDetailService(serviceNo) {
    return api.get(`/services/${serviceNo}`);
  },
  downloadSignImage(params) {
    return api.get(`/services/${params}/download-sign-images/zip`, {
      responseType: "blob"
    });
  },
  downloadReceiptImage(params) {
    return api.get(`/services/${params}/download-receipt-images`, {
      responseType: "blob"
    });
  },

  downloadSignReceiptImage(params) {
    return api.get(`/sign_receipts/${params}/download-sign-receipt`, {
      responseType: "blob"
    });
  },

  getListOperation(params) {
    return api.get("/services/list-operation", { params });
  },

  getListActOperation(id) {
    return api.get(`/services/${id}/list-act-operation`);
  },

  getListCarNum(params) {
    return api.get("/services/car-number-pulldown", { params });
  },

  updateOrderInstruct(params, service_no) {
    return api.patch(`/service-instructs/${service_no}/update_order_instruct`, params);
  }
};
