import { Button } from "antd";
import PropTypes from "prop-types";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { PATH_NAME, ROLE } from "common/constant";

import { flowAccountStore } from "store/slices/flowAccount";

const FooterUpdate = ({
  currentPageFlag,
  isFormConfirm,
  setIsFormConfirm,
  setCurrentPageFlag,
  toggleFormDone,
  handleSaveDraft
}) => {
  const navigate = useNavigate();

  const { formData } = useSelector(flowAccountStore);
  const isDraft = formData?.save_flg === "1";
  const isAdmin = formData?.use_user_div === ROLE.ADMIN;

  const redirectEdit = () => {
    setIsFormConfirm && setIsFormConfirm(false);
    setCurrentPageFlag && setCurrentPageFlag("form");
  };

  const handleBack = () => {
    const haveClientPage = formData["main_office_div"] === "1" && isAdmin;
    switch (currentPageFlag) {
      case "trans": {
        if (haveClientPage) setCurrentPageFlag("client");
        else setCurrentPageFlag("form");
        break;
      }
      case "client": {
        setCurrentPageFlag("form");
        break;
      }
      default:
        break;
    }
  };

  const handleBtnSecondary = () => {
    if (isDraft) {
      handleSaveDraft();
    } else {
      navigate(PATH_NAME.ACCOUNT_LIST);
    }
  };

  const textBtnPri = isDraft ? "登録" : "変更";

  return (
    <div className="footer-create-customer bg-white">
      <div className="hint">
        {isFormConfirm
          ? `内容を変更する場合は${textBtnPri}を押してください。`
          : "内容の入力後、次へ進んでください。"}
      </div>
      <div>
        <Button onClick={handleBtnSecondary} className="btn-submit" size="large">
          {isDraft ? "下書き保存" : "キャンセル"}
        </Button>
        <Button onClick={toggleFormDone} className="btn-submit" type="primary" size="large">
          {isFormConfirm ? textBtnPri : "次へ"}
        </Button>
      </div>
      {isFormConfirm ? (
        <Button onClick={redirectEdit} style={{ marginTop: "10px" }} type="link">
          内容を修正する
        </Button>
      ) : (
        currentPageFlag !== "form" &&
        currentPageFlag && (
          <Button onClick={handleBack} type="link">
            前のページへ移動する
          </Button>
        )
      )}
    </div>
  );
};
FooterUpdate.propTypes = {
  currentPageFlag: PropTypes.string,
  isFormConfirm: PropTypes.bool,
  setIsFormConfirm: PropTypes.func,
  setCurrentPageFlag: PropTypes.func,
  toggleFormDone: PropTypes.func,
  handleSaveDraft: PropTypes.func
};
export default FooterUpdate;
