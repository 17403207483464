import { DownOutlined } from "@ant-design/icons";
import { Button, Dropdown, Form, Menu, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { PATH_NAME, PER_ACCESS_PAGES, PER_AUTH_DIV, REGI_STATUS, ROLE } from "common/constant";
import useAuth from "common/hooks/useAuth";

import { userApi } from "services/user";

import { pulldownStore } from "store/slices/pulldown";
import { getListOptOfficesAction } from "store/slices/pulldown";

import TableListAccount from "../components/TableListAccount";
import "./ListAccount.scss";

import Divider from "components/common/Divider";
import InputFilter from "components/filter/InputFilter";
import SelectFilter from "components/filter/SelectFilter";

const ListAccount = () => {
  const { role, authPer } = useAuth();
  const [form] = Form.useForm();
  const { listOptAuthGroups, listOptUserTypes, listOptOffices } = useSelector(pulldownStore);
  const [listAccounts, setListAccounts] = useState([]);
  const [params, setParams] = useState({ page: 1 });
  const [totalAccounts, setTotalAccounts] = useState();
  const [sortParams, setSortParams] = useState([]);
  const dispatch = useDispatch();
  const fetchListAccounts = async () => {
    const res = await userApi.getListUsers({ ...params, sort: sortParams });
    setListAccounts(res.items);
    setTotalAccounts(res.total);
  };
  useEffect(() => {
    fetchListAccounts();
  }, [params, sortParams]);

  const menuItems = [
    { key: 0, label: <Link to={PATH_NAME.ACC_REG_MAIN}>お客様</Link> },
    { key: 1, label: <Link to={PATH_NAME.ACC_REG_HONSHA}>本社担当社</Link> },
    { key: 2, label: <Link to={PATH_NAME.ACC_REG_UNSO}>営業担当者</Link> }
  ];

  const handleFilter = (changedFields) => {
    if (changedFields[0].value) {
      setParams({
        ...params,
        [changedFields[0].name[0]]: changedFields[0].value,
        page: 1
      });
    } else {
      delete params[changedFields[0].name[0]];
      setParams({ ...params, page: 1 });
    }
  };

  const handleChangePage = (page) => {
    setParams({ ...params, page });
  };

  const handleSortAccounts = (pagination, _, sorter) => {
    if (pagination.current === params.page) {
      if (sorter.length > 1) {
        setSortParams(
          sorter.map((item) => (item.order === "descend" ? `-${item.field}` : item.field))
        );
      } else if (sorter.column) {
        setSortParams([sorter.order === "descend" ? `-${sorter.field}` : sorter.field]);
      } else {
        setSortParams([]);
      }
    }
  };

  useEffect(() => {
    if (role && role !== ROLE.ADMIN && listOptOffices.length > 0) {
      form.setFieldsValue({ office: listOptOffices[0].label });
    }
  }, [listOptOffices, role]);

  useEffect(() => {
    dispatch(getListOptOfficesAction());
  }, []);

  return (
    <div className="list-account-page bg-white">
      <Row className="header-account" justify="space-between">
        <h1>アカウント一覧</h1>
        {authPer && authPer[PER_ACCESS_PAGES.CREATE_ACCOUNT] === PER_AUTH_DIV.CRUD && (
          <Dropdown overlay={<Menu items={menuItems} />} trigger={["click"]}>
            <Button type="primary">
              新規登録
              <DownOutlined />
            </Button>
          </Dropdown>
        )}
      </Row>
      <Divider height="2px" />
      <Form
        form={form}
        onFieldsChange={handleFilter}
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        autoComplete="off"
        className="account-filter-form"
      >
        <Row gutter={[16, 12]}>
          <SelectFilter
            title="登録状況"
            name="status"
            placeholder="途中保存"
            listOptions={REGI_STATUS}
            width={100}
          />
          <InputFilter title="ログインID" placeholder="" name="login_id" />
          <InputFilter title="ユーザー名" placeholder="" name="user_nm" />
          <SelectFilter
            title="種別"
            name="role"
            placeholder="プルダウンから選択"
            listOptions={listOptUserTypes}
            width={200}
          />
          <SelectFilter
            title="利用サービス"
            name="auth_group"
            placeholder="プルダウンから選択"
            listOptions={listOptAuthGroups}
            width={230}
          />
          {role === ROLE.ADMIN ? (
            <SelectFilter
              title="タカスエ営業所"
              placeholder="プルダウンから選択"
              name="office"
              listOptions={listOptOffices}
              width={240}
            />
          ) : (
            <InputFilter title="タカスエ営業所" placeholder="テキスト入力" name="office" disabled />
          )}
        </Row>
      </Form>
      <TableListAccount
        onSortAccounts={handleSortAccounts}
        page={params.page}
        totalAccounts={totalAccounts}
        onPageChange={handleChangePage}
        dataSource={listAccounts}
        totalRow={totalAccounts}
      />
    </div>
  );
};

export default ListAccount;
