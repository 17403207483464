import {
  DndContext,
  DragOverlay,
  PointerSensor,
  closestCorners,
  useSensor,
  useSensors
} from "@dnd-kit/core";
import { SortableContext, arrayMove, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { Row } from "antd";
import { IconCardArrow, IconRefresh } from "assets";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useParams } from "react-router-dom";

import { serviceApi } from "services/service";

import ServiceCard from "./ServiceCard";

import Select from "components/common/Select";

const ServiceDetailContent = ({
  listCard,
  roundNumOption,
  roundNum,
  setRoundNum,
  highlighted,
  listInstructNo,
  setDragData,
  setLoading,
  setHighlighted
}) => {
  const [activeId, setActiveId] = useState();
  const { serviceNo } = useParams();

  const handleDragStart = (event) => {
    setActiveId(event.active.id);
  };
  const handleDragOver = () => {
    document.body.style.cursor = "grabbing";
  };
  const handleDragEnd = async (event) => {
    document.body.style.cursor = "default";
    const { active, over } = event;
    const activeIndex = listCard.findIndex(
      (card) => card.round_num * 100 + card.order === active.id
    );
    const overIndex = listCard.findIndex((card) => card.round_num * 100 + card.order === over.id);
    if (activeIndex !== overIndex) {
      if (Math.floor(active.id / 100) !== Math.floor(over.id / 100)) {
        alert("別の回転への変更はできません。");
        return;
      }
      setLoading(true);
      const newListCard = arrayMove(listCard, activeIndex, overIndex).filter(
        (card) => card.round_num === Math.floor(active.id / 100)
      );
      const listNo = listInstructNo.filter(
        (instruct) => instruct.round_num === Math.floor(active.id / 100)
      );
      const newDragData = {
        round_num: Math.floor(active.id / 100),
        list_instruct: listNo.map((instruct) => ({
          service_instruct_no: instruct.service_instruct_no,
          pickup_order:
            newListCard.findIndex(
              (card) =>
                card.service_instruct_no === instruct.service_instruct_no && card.isLoad === true
            ) + 1,
          delivery_order:
            newListCard.findIndex(
              (card) =>
                card.service_instruct_no === instruct.service_instruct_no && card.isLoad === false
            ) + 1
        }))
      };
      await serviceApi.updateOrderInstruct(newDragData, serviceNo).then(() => {
        setDragData(newDragData);
        setLoading(false);
      });
    }
  };
  const sensors = useSensors(
    useSensor(PointerSensor, {
      // Require the mouse to move by 8 pixels before activating
      activationConstraint: {
        distance: 8
      }
    })
  );

  return (
    <div>
      <Row className="header-order" align="middle" justify="space-between">
        <h1 className="fs-20 fw-700">ルート</h1>
      </Row>
      <Row
        className="px-20 py-14 rounded-4 bg-safe-fill mb-16"
        align="middle"
        justify="space-between"
      >
        <Row>
          <IconRefresh className="mt-6 mr-8" />
          <h2 className="fs-18 fw-700">周回切り替え</h2>
        </Row>
        <Select
          setValue={setRoundNum}
          listOptions={roundNumOption}
          style={{ width: "160px" }}
          value={roundNum}
          allowClear={false}
        />
      </Row>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCorners}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        onDragOver={handleDragOver}
      >
        <SortableContext id="drag-zone" items={listCard} strategy={verticalListSortingStrategy}>
          {listCard.map((card, i, arr) => {
            const isRoundFirst = i === 0 || arr[i - 1].round_num !== card.round_num;
            const isCardArrow =
              arr[i + 1] &&
              card.isLoad !== arr[i + 1].isLoad &&
              card.base_cd !== arr[i + 1].base_cd &&
              card.round_num === arr[i + 1].round_num;
            return (
              <React.Fragment key={card.round_num * 100 + card.order}>
                {isRoundFirst ? (
                  <Row className="fs-18 fw-400 mb-5">
                    <IconRefresh className="mt-6 mr-8" />
                    {card.round_num}回戦
                  </Row>
                ) : null}
                <ServiceCard
                  card={card}
                  highlighted={card.round_num * 100 + card.order === highlighted}
                  setHighlighted={setHighlighted}
                />
                {isCardArrow && (
                  <div className="text-center mb-10">
                    <IconCardArrow />
                  </div>
                )}
              </React.Fragment>
            );
          })}
        </SortableContext>
        <DragOverlay>
          {activeId ? (
            <ServiceCard
              card={listCard.find((card) => card.round_num * 100 + card.order === activeId)}
            />
          ) : null}
        </DragOverlay>
      </DndContext>
    </div>
  );
};

ServiceDetailContent.propTypes = {
  listCard: PropTypes.array,
  roundNumOption: PropTypes.array,
  roundNum: PropTypes.number,
  setRoundNum: PropTypes.func,
  highlighted: PropTypes.number,
  listInstructNo: PropTypes.array,
  setDragData: PropTypes.func,
  setLoading: PropTypes.func,
  setHighlighted: PropTypes.func
};

export default ServiceDetailContent;
