/* eslint-disable no-unused-vars */
import { Form, Button, Input, Image, Typography, Tag, Checkbox, Row, Col } from "antd";
import React, { useEffect, useState } from "react";
import companyTitle from "assets/img/company_title.png";
import { useDispatch, useSelector } from "react-redux";
import { authStore } from "store/slices/auth";
import { loginAction } from "store/slices/auth";
import { useNavigate } from "react-router-dom";
import { PATH_NAME } from "common/constant";
import { IconNameLogo } from "assets";

function LoginForm() {
  const { Text, Title } = Typography;
  const dispatch = useDispatch();
  const { loading, error, token } = useSelector(authStore);
  const navigate = useNavigate();
  const handleLogin = async ({ id, login_password, remember }) => {
    const trimmingId = id.trim();
    dispatch(loginAction({ id: trimmingId, login_password, remember }));
  };
  const [checked, setChecked] = useState(true);
  useEffect(() => {
    if (token) navigate(PATH_NAME.ORDER_LIST);
  }, [token]);
  return (
    <>
      <IconNameLogo />
      <h3 className="name-logo">Takasue Logistics Service</h3>
      <Form initialValues={{ remember: true }} onFinish={handleLogin} autoComplete="off">
        {error && (
          <Title type="danger" level={5} style={{ textAlign: "left" }}>
            {error === "Incorrect user_id, password"
              ? "ログインID、またはパスワードが間違っています"
              : "アカウントが存在しません、IDとパスワードを確認して下さい"}
          </Title>
        )}
        <div className="login-label">
          <Text>
            ログインID <Tag color="#f50">必須</Tag>
          </Text>
          <Form.Item name="id" rules={[{ required: true, message: "ログインIDは必須入力です" }]}>
            <Input
              placeholder="IDを入力してください"
              className="login-input"
              size="large"
              variant="outlined"
              status={error && "error"}
            />
          </Form.Item>
        </div>
        <div className="login-label login-pass">
          <Text>
            パスワード
            <Tag color="#f50">必須</Tag>
          </Text>
          <Form.Item
            name="login_password"
            rules={[{ required: true, message: "パスワードは必須入力です" }]}
          >
            <Input.Password
              placeholder="パスワードを入力"
              className="login-input"
              size="large"
              variant="outlined"
              iconRender={(visible) => (visible ? "パスワードを非表示" : "パスワードを表示")}
              status={error && "error"}
            />
          </Form.Item>
        </div>
        <div className="login-submit">
          <Row justify="center">
            <Col span={20}>
              <Form.Item>
                <Button loading={loading} type="primary" htmlType="submit" size="large" block>
                  ログイン
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </div>
        <div className="login-remember">
          <Form.Item name="remember" valuePropName="checked">
            <Checkbox
              onChange={() => {
                setChecked(!checked);
              }}
              checked={checked}
            >
              次回以降ログイン状態を保持する。
            </Checkbox>
          </Form.Item>
        </div>
      </Form>
    </>
  );
}
export default LoginForm;
