import { createSlice } from "@reduxjs/toolkit";

export const slice = createSlice({
  name: "listFilterArrival",
  initialState: {
    filterArrivalObject: {
      service_ymd_from: "",
      service_ymd_to: "",
      work_time_from: "",
      work_time_to: ""
    }
  },
  reducers: {
    setFilterArrival: (state, action) => {
      state.filterArrivalObject = action.payload;
    }
  }
});

// Action creators are generated for each case reducer function
export const { setFilterArrival } = slice.actions;

// Async action

export const setFilterArrivalAction = (object) =>
  function (dispatch) {
    dispatch(setFilterArrival(object));
  };

// Get value from state

export const listFilterArrivalStore = (state) => state.listFilterArrival;

export default slice.reducer;
