import api from "./baseApi";

export const baseApi = {
  getListBase(params) {
    return api.get("/bases/", { params });
  },
  getDetailBase({ company_cd, chart_cd }) {
    return api.get(`/bases/company_cd/${company_cd}/chart_cd/${chart_cd}`);
  },
  uploadChartPdf(chart_cd, pdf) {
    return api.post(`/bases/${chart_cd}/upload-chart-pdf`, pdf);
  },
  deleteChartPdf(chart_cd) {
    return api.delete(`/bases/${chart_cd}/delete-chart-pdf`);
  },
  uploadBasePhoto(params) {
    return api.post("/base-photos/", params);
  },
  deleteBasePhoto(base_photo_no) {
    return api.delete(`/base-photos/${base_photo_no}`);
  },
  updateBase(chart_cd, params) {
    return api.patch(`/bases/${chart_cd}`, params);
  }
};
