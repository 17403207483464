import { Dropdown, Layout, Menu } from "antd";
import {
  IconBox,
  IconBoxActive,
  IconBtnBack,
  IconDropdown,
  IconListUser,
  IconListUserActive,
  IconLogoSider,
  IconMaster,
  IconMasterActive,
  IconReceipt,
  IconReceiptActive,
  IconRoute,
  IconRouteActive,
  IconTruck,
  IconTruckActive,
  IconUser
} from "assets";
import { IconOperationActive } from "assets";
import { IconOperation } from "assets";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { PATH_NAME, PER_ACCESS_PAGES } from "common/constant";
import { ROLE } from "common/constant";
import useAuth from "common/hooks/useAuth";

import { logoutAction, setMeAction } from "store/slices/auth";
import { getListOptionStatic } from "store/slices/pulldown";

import "./LayoutWrapper.scss";

LayoutWrapper.propTypes = {
  enablePage: PropTypes.bool,
  haveBtnBack: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  textBack: PropTypes.string,
  noPadding: PropTypes.bool
};

const { Content, Header, Sider } = Layout;

function LayoutWrapper({ haveBtnBack, children, enablePage = true, textBack, noPadding = false }) {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { per, role } = useAuth();

  const handleLogOut = () => {
    dispatch(logoutAction());
    navigate(PATH_NAME.LOGIN);
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    dispatch(getListOptionStatic());
  }, []);

  useEffect(() => {
    if (!enablePage && role && per) {
      if (role === ROLE.CUSTOMER && !per.includes(PER_ACCESS_PAGES.ORDER_LIST)) {
        navigate(PATH_NAME.INVOICE_LIST);
      } else {
        navigate(PATH_NAME.ORDER_LIST);
      }
    }
  }, [enablePage, role, per]);

  const isOrderMenuActive = () =>
    [
      PATH_NAME.ORDER_LIST,
      PATH_NAME.CREATE_ORDER,
      PATH_NAME.UPDATE_ORDER,
      PATH_NAME.CSV_CAPTURE
    ].includes(pathname) || pathname.includes("order");

  const isAccountMenuActive = () =>
    [
      PATH_NAME.ACCOUNT_LIST,
      PATH_NAME.ACC_REG_MAIN,
      PATH_NAME.ACC_REG_HONSHA,
      PATH_NAME.ACC_REG_UNSO,
      PATH_NAME.ACC_UPD_MAIN,
      PATH_NAME.ACC_UPD_HONSHA,
      PATH_NAME.ACC_UPD_UNSO
    ].includes(pathname) || pathname.includes("account");

  const isMasterMenuActive = () =>
    [PATH_NAME.MASTER_LIST, PATH_NAME.BASE_LIST].includes(pathname) ||
    pathname.includes("base_upd");

  const isArrivalMenuActive = () =>
    [PATH_NAME.ARRIVAL_LIST].includes(pathname) || pathname.includes("arrival_list");

  const isServiceMenuActive = () =>
    [PATH_NAME.SERVICE_LIST, PATH_NAME.SERVICE_DETAIL].includes(pathname) ||
    pathname.includes("service_detail");

  const isOperationMenuActive = () =>
    [PATH_NAME.OPERATION].includes(pathname) || pathname.includes("operation");

  /* eslint-disable */
  const sidebarMenuItems = [
    {
      label: (
        <div className="sidebar-logo">
          <IconLogoSider />
        </div>
      ),
      key: 0
    },
    ...(per?.includes(PER_ACCESS_PAGES.ORDER_LIST)
      ? [
          {
            label: (
              <Link to={PATH_NAME.ORDER_LIST}>
                {isOrderMenuActive() ? <IconTruckActive /> : <IconTruck />}
                <span className="nav-title">配送</span>
              </Link>
            ),
            key: 1,
            className: `${isOrderMenuActive() && "ant-menu-item-selected"}`
          }
        ]
      : []),
    ...(per?.includes(PER_ACCESS_PAGES.SERVICE_LIST)
      ? [
          {
            label: (
              <Link to={PATH_NAME.SERVICE_LIST}>
                {isServiceMenuActive() ? <IconRouteActive /> : <IconRoute />}
                <span className="nav-title">運行</span>
              </Link>
            ),
            key: 6,
            className: `${isServiceMenuActive() && "ant-menu-item-selected"}`
          }
        ]
      : []),
    ...(per?.includes(PER_ACCESS_PAGES.OPERATION)
      ? [
          {
            label: (
              <Link to={PATH_NAME.OPERATION}>
                {isOperationMenuActive() ? <IconOperationActive /> : <IconOperation />}
                <span className="nav-title">稼働車両</span>
              </Link>
            ),
            key: 7,
            className: `${isOperationMenuActive() && "ant-menu-item-selected"}`
          }
        ]
      : []),
    ...(per?.includes(PER_ACCESS_PAGES.ARRIVAL_LIST)
      ? [
          {
            label: (
              <Link to={PATH_NAME.ARRIVAL_LIST}>
                {isArrivalMenuActive() ? <IconBoxActive /> : <IconBox />}
                <span className="nav-title">納品状況</span>
              </Link>
            ),
            key: 5,
            className: `${isArrivalMenuActive() && "ant-menu-item-selected"}`
          }
        ]
      : []),
    ...(per?.includes(PER_ACCESS_PAGES.INVOICE_LIST)
      ? [
          {
            label: (
              <Link to={PATH_NAME.INVOICE_LIST}>
                {PATH_NAME.INVOICE_LIST === pathname ? <IconReceiptActive /> : <IconReceipt />}
                <span className="nav-title">請求書</span>
              </Link>
            ),
            key: 2,
            className: `${PATH_NAME.INVOICE_LIST === pathname && "ant-menu-item-selected"}`
          }
        ]
      : []),
    ...(per?.includes(PER_ACCESS_PAGES.ACCOUNT_LIST)
      ? [
          {
            label: (
              <Link to={PATH_NAME.ACCOUNT_LIST}>
                {isAccountMenuActive() ? <IconListUserActive /> : <IconListUser />}
                <span className="nav-title">アカウント</span>
              </Link>
            ),
            key: 3,
            className: `${isAccountMenuActive() && "ant-menu-item-selected"}`
          }
        ]
      : []),
    ...(per?.includes(PER_ACCESS_PAGES.LIST_MASTER)
      ? [
          {
            label: (
              <Link to={PATH_NAME.MASTER_LIST}>
                {isMasterMenuActive() ? <IconMasterActive /> : <IconMaster />}
                <span className="nav-title">マスタ</span>
              </Link>
            ),
            key: 4,
            className: `${isMasterMenuActive() && "ant-menu-item-selected"}`
          }
        ]
      : [])
  ];

  const menuItems = [
    {
      label: <Link to={PATH_NAME.USER_DETAIL}>アカウント設定</Link>,
      key: "0"
    },
    { label: <div onClick={handleLogOut}>ログアウト</div>, key: "1" }
  ];

  useEffect(() => {
    if (!(localStorage.token || sessionStorage.token)) navigate(PATH_NAME.LOGIN);
    else dispatch(setMeAction());
  }, []);

  return (
    <Layout id="layout-wraper">
      <Sider width={110} className="layout-wraper-sider">
        <Menu selectedKeys={[pathname]} items={sidebarMenuItems}></Menu>
        <Link to={PATH_NAME.PRIVACY_POLICY} className="sider-footer">
          <p>プライバシーポリシー</p>
          <p>個人情報の取り扱い</p>
        </Link>
      </Sider>
      <Layout>
        <Header className="layout-wraper-header">
          {haveBtnBack ? (
            <div className="left-header" onClick={handleGoBack}>
              <IconBtnBack />
              {textBack || "戻る"}
            </div>
          ) : (
            <div />
          )}
          <Dropdown
            overlayClassName="dropdown-user"
            overlay={<Menu items={menuItems} />}
            trigger={["click"]}
            placement="bottomRight"
          >
            <div className="right-header">
              <IconUser />
              <IconDropdown />
            </div>
          </Dropdown>
        </Header>
        <Content className={`${noPadding ? "" : "content"}`}>{children}</Content>
      </Layout>
    </Layout>
  );
}

export default LayoutWrapper;
