import React from "react";
import PropTypes from "prop-types";
import { Col, Row } from "antd";
import Tag from "components/common/Tag";
import DatePicker from "components/common/DatePicker";

FormDatePicker.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  optional: PropTypes.bool,
  form: PropTypes.any,
  rules: PropTypes.array,
};

function FormDatePicker({ label, name, optional, rules }) {
  return (
    <Row className="form-item form-datepicker">
      <Col span={7} className="label">
        <h1 className="label">{label}</h1>
      </Col>
      <Col span={9}>
        <DatePicker rules={rules} name={name} />
        {optional && <Tag type="optional" />}
      </Col>
    </Row>
  );
}

export default FormDatePicker;
