import { Col, Row } from "antd";
import Form from "antd/lib/form/Form";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { PER_ACCESS_PAGES, PER_AUTH_DIV } from "common/constant";
import useAuth from "common/hooks/useAuth";

import { masterDataApi } from "services/masterData";

import {
  flowAccountStore,
  setIsEmptyClientAction,
  setListOfficeClientAction
} from "store/slices/flowAccount";

import "./HeadOfficePartners.scss";
import ListChosePartner from "./ListChosePartner";
import TableListPartners from "./TableListPartners";

import InputFilter from "components/filter/InputFilter";
import TitleDetail from "components/form/TitleDetail";

const HeadOfficePartners = ({ editMode }) => {
  const { listClientCd, isEmptyClient } = useSelector(flowAccountStore);
  const { authPer } = useAuth();
  const dispatch = useDispatch();

  const [listPartners, setListPartners] = useState([]);
  const [params, setParams] = useState({ page: 1 });
  const [totalRecords, setTotalRecords] = useState();
  const [sortParams, setSortParams] = useState([]);
  const [canEdit, setCanEdit] = useState();

  const initFlag = useRef(false);

  const setListOfficeClient = (params) => {
    dispatch(setListOfficeClientAction(params));
  };

  const handleFilter = (changedFields) => {
    setParams({
      ...params,
      [changedFields[0].name[0]]: changedFields[0].value,
      page: 1
    });
  };

  const handleChangePage = (currentPage) => {
    setParams({ ...params, page: currentPage });
  };

  const fetchListMainOfficeClient = async () => {
    const res = await masterDataApi.getListMainOfficeClient({
      ...params,
      sort: sortParams
    });
    const listIdExist = listClientCd?.map((e) => e.id);
    const listPartnersTemp = res.items?.map((item) => ({
      id: item?.client_cd,
      name: item?.client_nm
    }));
    setListPartners(listPartnersTemp?.filter((item) => !listIdExist?.includes(item.id)));
    setTotalRecords(res.total);
  };

  const handleRemoveItem = async (idRemove) => {
    const res = await masterDataApi.getListMainOfficeClient({
      ...params,
      sort: sortParams
    });
    const listClientCdTemp = listClientCd.filter((item) => item.id !== idRemove);
    setListOfficeClient(listClientCdTemp);
    const listIdExist = listClientCdTemp?.map((e) => e.id);
    const listPartnersTemp = res.items?.map((item) => ({
      id: item?.client_cd,
      name: item?.client_nm
    }));
    setListPartners(listPartnersTemp?.filter((item) => !listIdExist?.includes(item.id)));
    setTotalRecords(res.total);
  };

  const handleSortOrders = (pagination, _, sorter) => {
    const paramSort = sorter.field === "name" ? "client_nm" : "client_cd";
    if (pagination.current === params.page) {
      if (sorter.length > 1) {
        setSortParams(
          sorter.map((item) => (item.order === "descend" ? `-${paramSort}` : paramSort))
        );
      } else if (sorter.column) {
        setSortParams([sorter.order === "descend" ? `-${paramSort}` : paramSort]);
      } else {
        setSortParams([]);
      }
    }
  };

  useEffect(() => {
    setCanEdit(authPer && authPer[PER_ACCESS_PAGES.ADD_CLIENT] === PER_AUTH_DIV.CRUD);
  }, [authPer]);

  useEffect(async () => {
    fetchListMainOfficeClient();
  }, [params, sortParams]);

  useEffect(() => {
    if (initFlag.current && listClientCd.length === 0) dispatch(setIsEmptyClientAction(true));
    else dispatch(setIsEmptyClientAction(false));
    if (!initFlag.current) {
      initFlag.current = true;
    }
    const listIdTemp = listClientCd.map((e) => e.id);
    setListPartners((preState) => preState?.filter((item) => !listIdTemp.includes(item.id)));
  }, [listClientCd]);

  return (
    <Col span={18}>
      <div className={`${!canEdit && editMode && "disable"} head-office-partners`}>
        <TitleDetail title="本社取引先の追加" />
        {isEmptyClient && <div className="msg-err">本社取引先を追加して下さい</div>}
        <Row className="partner-content">
          <Col span={10}>
            <ListChosePartner
              partners={listClientCd}
              handleRemoveItem={handleRemoveItem}
            />
          </Col>
          <Col span={14}>
            <Form
              onFieldsChange={handleFilter}
              name="basic"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
              autoComplete="off"
            >
              <Row gutter={[16]}>
                <InputFilter title="取引先コード" placeholder="テキスト入力" name="client_cd" />
                <InputFilter title="取引先名称" placeholder="テキスト入力" name="client_nm" />
              </Row>
            </Form>
            <TableListPartners
              setPartners={setListOfficeClient}
              partners={listClientCd}
              dataSource={listPartners}
              setListPartners={setListPartners}
              page={params.page}
              totalRow={totalRecords}
              onChangePage={handleChangePage}
              onSortOrders={handleSortOrders}
              rowKey={(record) => `${record.client_cd}_${record.company_cd}`}
            />
          </Col>
        </Row>
      </div>
    </Col>
  );
};

HeadOfficePartners.propTypes = {
  editMode: PropTypes.bool
};

export default HeadOfficePartners;
