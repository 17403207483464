import "./PrivacyPolicy.scss";
import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="page__container">
      <h1 className="page__title">個人情報の保護について</h1>

      <h2 className="page__subtitle">個人情報保護方針の宣言</h2>
      <div className="page__description">
        当社は、当社の運営するウェブサイトをご利用いただくお客様のプライバシーの保護に努めています。<br />
        当社が、サービスを提供するためには、お客様個人に関する情報(以下、「個人情報」といいます)を集めなければなりませんが、
        当社でその情報のプライバシーを守り、秘密を保持するために様々な手段を講じています。<br />
        当社ではお客様のプライバシーを尊重しています。当社は個人情報を売買したり、交換したり、その他の方法で不正使用することには一切関与しておりません。
        このウェブサイトをご利用になり、個人情報を供与することで、あなたはこのプライバシーポリシーに説明されている個人情報の取り扱いなどについて受諾し、承認したものとみなされます。
      </div>

      <h2 className="page__subtitle">個人情報の収集の目的</h2>
      <div className="page__description">
        お客様から集めた個人情報は、以下の目的で利用します。

        <ul>
          <li>当社がお客様に提供するサービスにおいて利用するため</li>
          <li>お客様に合ったサービスや新しい商品などの情報を的確にお知らせするため</li>
          <li>必要に応じてお客様に連絡を行うため</li>
        </ul>
      </div>

      <h2 className="page__subtitle">個人情報の開示</h2>
      <div className="page__description">
        下記の場合には、お客様の事前の同意なく当社はお客様の個人情報を開示できるものとします。

        <ul>
          <li>警察や裁判所、その他の政府機関から召喚状、令状、命令などによって要求された場合</li>
          <li>人の生命、身体または財産の保護のために必要がある場合であって、お客様の同意を得ることが困難であるとき</li>
        </ul>
      </div>

      <h2 className="page__subtitle">個人情報の管理</h2>
      <div className="page__description">
        お客様の個人情報は、当社が適切な管理を行なうとともに、漏洩、滅失、毀損の防止のために最大限の注意を払っております。<br />
        尚、当社ではお客様によりよいサービスを提供するため、個人情報を適切に取り扱っていると認められる外部の委託先に、個人情報の取り扱いの一部を委託しています。<br />
        委託先は、委託業務を行なうために必要な範囲で個人情報を利用します。<br />
        この場合、 当社は、委託先との間で個人情報の取り扱いについて適切な契約を締結し、適切な管理を要求いたします。
      </div>

      <h2 className="page__subtitle">位置情報の管理</h2>
      <div className="page__description">
        当社は、利用者がTLSモバイルを使用する際に（また、バックグラウンドの位置トラッキング機能をオンにしている場合には、利用者が本サービスと直接やりとりをしていないときにも）、
        利用者の移動状況や位置情報を収集し、使用します。
        当社のサービスには、お客様の現実世界における位置に結びついた情報管理を提供することを中核的特徴とする、位置情報の共有を基にした業務効率化を目指すため、
        当社は、利用者がどこでアプリを操作しているかを把握し、位置を特定することで追加業務を設計することが可能になります。当社は、GPSを使用して利用者の位置を特定します。
      </div>

      <h2 className="page__subtitle">従業員の監督方法</h2>
      <div className="page__description">
        個人情報保護の重要性について、適時または定期的に適切な教育を行っております。<br />
        また、当社が個人情報を管理する際は、管理責任者を置き、適切な管理を行うとともに、外部への流出防止に努めます。
        さらに、外部からの不正アクセス、改ざんなどの危険に対しては、適切かつ合理的な範囲の安全対策を実施し、お客様の個人情報保護に努めます。<br />
        個人情報に係るデータベースなどのアクセスについては、アクセス権を有するものを限定し、社内においても不正な利用がなされないように厳重に管理します。
      </div>

      <h2 className="page__subtitle">リンク先</h2>
      <div className="page__description">
        リンク先での個人情報の利用については、当社のプライバシーの考え方ではなく、リンク先自身のプライバシーの考え方に従って行われます。
        リンク先の当社以外の事業者または個人のウェブサイトにおける個人情報の取り扱いについては、当社は責任を負うことはできません。
      </div>

      <h1 className="page__title">Cookieポリシー</h1>

      <h2 className="page__subtitle">Cookie（クッキー）について</h2>
      <div className="page__description">
        当社ウェブサイトでは、当社ウェブサイト改善や当社の広告配信などを行うために、Cookieを利用しています。<br />
        Cookieとは、お客様がウェブサイトにアクセスした際に、サイト訪問の履歴や入力データ、ご利用環境などの情報を、お客様のコンピュータ上にファイルとして保存しておく仕組です。
        Cookieがウェブサーバーとお客様がご利用のブラウザとの間で送受信されることにより、お客様のご興味・ご関心に沿った閲覧体験の提供等につながります。
        お客様は、ご利用のブラウザでCookieの送受信を拒否に設定することができます。
        お客様がご利用のブラウザで許可設定をしている場合、当社はお客様のコンピュータ上に保存されたCookieを取得し、収集した情報と個人情報とを照らし合わせる場合があります。
        例えば、Cookieにより取得したアクセス履歴と、お客様と交換させていただいた名刺情報などを、突合する場合があります。
      </div>

      <h2 className="page__subtitle">Cookieを利用した広告配信について</h2>
      <div className="page__description">
        当社の広告が、第三者配信事業者（Googleなど）により、さまざまなウェブサイトに掲載されることがあります。<br />
        第三者配信事業者は、Cookieを通じて取得した当社ウェブサイトへのアクセス情報を使用し、当社に関する広告を配信します。<br />
        尚、お客様は、第三者配信事業者のオプトアウトページから当該事業者によるCookieの使用を無効にできます。<br />
        また、Network Advertising Initiativeのオプトアウトページにアクセスし、第三者配信事業者によるCookieの使用を無効にすることもできます。
      </div>

      <h2 className="page__subtitle">アクセス履歴について</h2>
      <div className="page__description">
        当社ウェブサイトでは、アクセス履歴（IPアドレス、ブラウザ・OSの種類、アクセス日時など）を記録していますが、
        当社ウェブサイトによる記録情報に、特定の個人を識別できるような情報は含まれていません。<br />
        当社は、このようなアクセス履歴を利用する場合においても、このプライバシーポリシーに記載しております「個人情報の収集の目的」の内容に従った利用を行います。
      </div>

      <h2 className="page__subtitle">このプライバシーポリシーの改訂</h2>
      <div className="page__description">
        当社では、お客様に提供するサービス向上のため、上記各項目の内容を適宜見直し、改善してまいります。<br />
        本書を変更する場合は、この変更について本ウェブサイトに掲載します。<br />
        最新のプライバシーポリシーをサイトに掲載することにより、常にプライバシー情報の収集や使用方法を知ることができます。<br />
        定期的にご確認くださいますようお願い申し上げます。<br />
        また、当初情報が収集された時点で述べた内容と異なった方法で個人情報を使用する場合も、当ウェブサイトへの掲載等によりご連絡させていただきます。<br />
        当社ウェブサイトが当初と異なった方法で個人情報の使用をしてよいかどうかについての選択権は、お客様が有しております。
      </div>
    </div>
  );
};

export default PrivacyPolicy;
