import { ROLE } from "common/constant";
import { DATA_FLAG_ALL } from "common/constant/account";
import { DIV_AUTH_GROUP_CODE } from "common/constant/account";

export const genPostBodyAcc = (params, listClientCd, listBillingAddress, isSaveDraft) => {
  const {
    user_id,
    invoice_send_flg,
    delivery_req_send_flg,
    account_info_upd_send_flg,
    auth_group_invoice,
    auth_group_order,
    sale_office_1,
    sale_office_2,
    sale_office_3,
    office_order_cd,
    office_logic_flag,
    login_password,
    ...rest
  } = params;

  const haveClientPage = params["main_office_div"] === "1";
  const haveTransPage = params["transport_div"] === "1";

  const flagAll = office_logic_flag === "all";
  const authGroup = [];
  if (auth_group_invoice === "1") authGroup.push(DIV_AUTH_GROUP_CODE.INVOICE);
  if (auth_group_order === "1") authGroup.push(DIV_AUTH_GROUP_CODE.ORDER);

  const dataServInvoice = {
    office_to_client_cds: flagAll
      ? DATA_FLAG_ALL.OFFICE_CD
      : [sale_office_1, sale_office_2, sale_office_3].filter((item) => item),
    client_cds: listClientCd?.map((e) => e.id),
    client_nms: listClientCd?.map((e) => e.name)
  };

  const dataPost = {
    user: {
      ...rest,
      company_cd: 10,
      id: user_id,
      use_user_div: ROLE.CUSTOMER,
      save_flg: isSaveDraft ? "1" : "0",
      login_password: login_password || "",
    },
    user_mail_manage: {
      user_id,
      account_info_upd_send_flg,
      invoice_send_flg,
      delivery_req_send_flg
    },
    billing_addr_ele: {
      office_to_billing_cds: haveTransPage ? [office_order_cd] : [],
      billing_addr_cds: haveTransPage ? listBillingAddress?.map((e) => e.id) : [],
      billing_addr_nms: haveTransPage ? listBillingAddress?.map((e) => e.name) : [],
      ...(haveClientPage
        ? dataServInvoice
        : { office_to_client_cds: [], client_cds: [], client_nms: [] })
    },
    auth_group_ids: authGroup
  };
  return dataPost;
};
