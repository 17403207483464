import Modal from "antd/lib/modal/Modal";
import React from "react";
import PropTypes from "prop-types";
import "./ViewInvoiceModal.scss";

const ViewInvoiceModal = ({ isOpenModal, onCancelModal, url }) => {
  return (
    <Modal
      visible={isOpenModal}
      onCancel={onCancelModal}
      footer={null}
      width={865}
      className="view-invoice-modal"
    >
      <iframe className="iframe" src={`${url}#toolbar=0`} frameBorder="0"></iframe>
    </Modal>
  );
};
ViewInvoiceModal.propTypes = {
  isOpenModal: PropTypes.bool,
  onCancelModal: PropTypes.func,
  url: PropTypes.string
};
export default ViewInvoiceModal;
