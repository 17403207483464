import { ConfigProvider, Empty, Table } from "antd";
import ProtoTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";

const TableListBase = ({ dataSource, totalRow, onPageChange, page, onSortOrders }) => {
  const columns = [
    {
      title: "お客様カルテコード",
      width: 170,
      dataIndex: "chart_cd",
      sorter: {
        multiple: 1
      },
      render: (text) => <Link to={`/base_upd/${text}`}>{text}</Link>
    },
    {
      title: "名称",
      width: 400,
      dataIndex: "name",
      sorter: {
        multiple: 1
      }
    },
    {
      title: "荷主荷受人区分",
      width: 150,
      dataIndex: "customer_div",
      sorter: {
        multiple: 1
      },
      render: (value) => {
        switch (value) {
          case "01":
            return "荷主";
          case "02":
            return "荷受人";
        }
      }
    },
    {
      title: "地点情報",
      width: "auto",
      dataIndex: "information",
      sorter: {
        multiple: 1
      }
    }
  ];
  return (
    <div className="account-list">
      <ConfigProvider
        renderEmpty={() => (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="適当な検索結果がありません" />
        )}
      >
        <Table
          title={() => (
            <div className="title">
              {dataSource.length > 0 && `全${totalRow}件中${dataSource.length}件を表示`}
            </div>
          )}
          columns={columns}
          rowKey={(record) => record.id}
          dataSource={dataSource}
          onChange={onSortOrders}
          pagination={{
            current: page,
            onChange: onPageChange,
            position: ["topRight"],
            defaultPageSize: 30,
            total: totalRow,
            showSizeChanger: false
          }}
          scroll={{ y: 490 }}
        />
      </ConfigProvider>
    </div>
  );
};
TableListBase.propTypes = {
  dataSource: ProtoTypes.array,
  totalRow: ProtoTypes.number,
  onPageChange: ProtoTypes.func,
  page: ProtoTypes.number,
  onSortAccounts: ProtoTypes.func,
  onSortOrders: ProtoTypes.func
};
export default TableListBase;
