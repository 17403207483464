import { ConfigProvider, DatePicker as DatePickerAntd, Form } from "antd";
import locale from "antd/lib/locale/ja_JP";
import { IconCalendar } from "assets";
import "moment/locale/ja";
import PropTypes from "prop-types";
import React from "react";

import { DATE_FORMAT } from "common/constant";
import { formatDate } from "common/utils/dateHelper";

DatePicker.propTypes = {
  setDate: PropTypes.func,
  name: PropTypes.string,
  rules: PropTypes.array,
  disabledDate: PropTypes.func,
  disabled: PropTypes.bool,
  defaultValue: PropTypes.any
};

function DatePicker({ setDate, name, rules, disabledDate, disabled, defaultValue }) {
  const handleOnChange = (date) => {
    if (setDate) setTimeout(() => setDate(date.format(DATE_FORMAT)), 0);
  };
  return (
    <ConfigProvider locale={locale}>
      <Form.Item rules={rules} name={name}>
        <DatePickerAntd
          disabledDate={disabledDate}
          suffixIcon={<IconCalendar />}
          format={formatDate}
          onChange={handleOnChange}
          placeholder=""
          showToday={false}
          disabled={disabled}
          defaultValue={defaultValue}
        />
      </Form.Item>
    </ConfigProvider>
  );
}

export default DatePicker;
