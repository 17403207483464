import api from "./baseApi";

export const userApi = {
  getMe() {
    return api.get("/users/me");
  },
  getMeSettings() {
    return api.get("/users/me/settings");
  },
  getListUsers(params) {
    return api.get("/users/", { params });
  },
  getUserById(user_id) {
    return api.get(`/users/${user_id}`);
  },
  getBillingByUserId(user_id){
    return api.get(`/billing_addresses/user/${user_id}`);
  },
  createUser(params) {
    return api.post("/users/", params);
  },
  patchMe(params) {
    return api.patch("/users/me", params);
  },
  updateUser(params, user_id) {
    return api.patch(`/users/${user_id}`, params);
  },
  updateBillingUser(params, user_id) {
    return api.patch(`/users/${user_id}/billing`, params);
  },
  checkExistUser(user_id) {
    return api.get(`/users/check-id-existed?id=${user_id}`);
  },
  deleteUser(user_id) {
    return api.delete(`/users/${user_id}`);
  }
};
