import Icon from "@ant-design/icons/lib/components/Icon";
import { Button, Col, Radio, Row, Tabs } from "antd";
import { IconDownload, IconWarning } from "assets";
import PropTypes from "prop-types";
import React from "react";
import { useNavigate } from "react-router-dom";

import "./HeaderOrder.scss";

import Divider from "components/common/Divider";

HeaderCreateOrder.propTypes = {
  isConfirm: PropTypes.bool,
  typeOrder: PropTypes.string,
  setTypeOrder: PropTypes.func
};

function HeaderCreateOrder({ isConfirm, typeOrder, setTypeOrder }) {
  const navigate = useNavigate();
  const switchType = () => {
    if (typeOrder === "tlot") {
      setTypeOrder("chapter");
    } else setTypeOrder("tlot");
  };
  return (
    <div className="header-order">
      <Row justify="space-between" align="middle" className="header-title">
        {isConfirm ? (
          <h1>配送依頼内容確認</h1>
        ) : (
          <>
            <h1>新規配送依頼</h1>
            <Button
              onClick={() => navigate("csvcapture")}
              icon={<Icon style={{ fontSize: "20px" }} component={IconDownload} />}
            >
              CSVファイル取り込み
            </Button>
          </>
        )}
      </Row>
      <Divider height="2px" />
      {isConfirm ? (
        <p className="hint-confirm">以下の内容で注文しますか？</p>
      ) : (
        <Row justify="center" align="middle" className="message">
          <IconWarning />
          集荷前日、昼12:00締め切りで配送依頼内容を確定致します。
        </Row>
      )}
      {!isConfirm && (
        <Row>
          <Col>
            <div>※Tロット/チャーターの選択を行って下さい。</div>
            <Tabs activeKey={typeOrder} type="card" className="type-order" onChange={switchType}>
              <Tabs.TabPane
                tab={
                  <div className="pos-rel flex-center">
                    <Radio checked={typeOrder === "tlot"} className="radio-left" />
                    Tロット
                  </div>
                }
                key="tlot"
              />
              <Tabs.TabPane
                tab={
                  <div className="pos-rel flex-center">
                    <Radio checked={typeOrder === "chapter"} className="radio-right" />
                    チャーター
                  </div>
                }
                key="chapter"
              />
            </Tabs>
          </Col>
        </Row>
      )}
    </div>
  );
}

export default HeaderCreateOrder;
