import React from "react";
import { Layout, Row, Col } from "antd";
import LoginForm from "./components/LoginForm";
import ForgotPassword from "./components/ForgotPassword";
import "./LoginContainer.scss";


const { Content } = Layout;
function LoginContainer () {
  return (
    <Layout id="login-page">
      <Content className="full-height">
        <Row className="full-height" align="middle">
          <Col span={12}>
            <Row justify="center">
              <Col xs={14} lg={11}>
                <LoginForm/>
              </Col>
            </Row>
            <Row justify="center" className="forgot-pw">
              <Col xs={14} lg={10}>
                <ForgotPassword/>
              </Col>
            </Row>
          </Col>
          <Col span={12} className="login-image full-height">
          </Col>
        </Row>
      </Content>
    </Layout>
  );
}
export default LoginContainer;
