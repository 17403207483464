/* eslint-disable react/prop-types */
import { Row } from "antd";
import GoogleMapReact, { fitBounds } from "google-map-react";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import { GOOGLE_API_KEY } from "common/config";

import SpotMarker from "./SpotMarker";

const ServiceMap = ({ listCard, highlighted, setHighlighted }) => {
  const [defaultCenter, setDefaultCenter] = useState({
    lat: 34.961872701244076,
    lng: 137.17459801587069
  });
  const [defaultZoom, setDefaultZoom] = useState(9);

  const getBounds = () => {
    const latSort = [...listCard]
      .filter((marker) => marker.latitude && marker.longitude)
      .sort((a, b) => {
        return Number(a.latitude) - Number(b.latitude);
      });
    const lngSort = [...listCard]
      .filter((marker) => marker.longitude && marker.latitude)
      .sort((a, b) => {
        return Number(a.longitude) - Number(b.longitude);
      });

    const bound = {
      ne: {
        lat: latSort.at(-1)?.latitude,
        lng: lngSort.at(-1)?.longitude
      },
      sw: {
        lat: latSort.at(0)?.latitude,
        lng: lngSort.at(0)?.longitude
      }
    };
    return bound;
  };

  useEffect(() => {
    if (listCard) {
      const bounds = getBounds();
      const sizes = {
        width: 600,
        height: 500
      };
      if (!bounds.ne.lat || !bounds.ne.lng || !bounds.sw.lat || !bounds.sw.lng) {
        return;
      }

      if (bounds.ne.lat === bounds.sw.lat && bounds.ne.lng === bounds.sw.lng) {
        setDefaultCenter({
          lat: Number(bounds.ne.lat) || 34.961872701244076,
          lng: Number(bounds.ne.lng) || 137.17459801587069
        });
        setDefaultZoom(10);
      } else {
        const { center, zoom } = fitBounds(bounds, sizes);
        setDefaultZoom(zoom);
        setDefaultCenter(center);
      }
    }
  }, [listCard]);

  return (
    <div>
      <Row className="header-order" align="middle">
        <h1 className="fs-20 fw-700">地図情報</h1>
      </Row>
      {listCard && (
        <div className="w-full h-500">
          <GoogleMapReact
            options={{
              streetViewControl: true
            }}
            bootstrapURLKeys={{ key: GOOGLE_API_KEY }}
            defaultCenter={{
              lat: 34.961872701244076,
              lng: 137.17459801587069
            }}
            defaultZoom={9}
            zoom={defaultZoom}
            center={defaultCenter}
          >
            {listCard.map((card) => (
              <SpotMarker
                highlighted={card.round_num*100 + card.order === highlighted}
                setHighlighted={setHighlighted}
                key={card.round_num*100 + card.order}
                lat={Number(card.latitude)}
                lng={Number(card.longitude)}
                text={card.order}
                round={card.round_num}
              />
            ))}
          </GoogleMapReact>
        </div>
      )}
    </div>
  );
};

ServiceMap.propTypes = {
  listCard: PropTypes.array,
  highlighted: PropTypes.number,
  setHighlighted: PropTypes.func
};

export default ServiceMap;
