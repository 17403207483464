export const drawPolyline = (map, maps, startCoord, endCoord) => {
  const lineSymbol = {
    path: "M 0,-1 0,1",
    strokeOpacity: 1,
    scale: 3
  };

  const line = new maps.Polyline({
    path: [startCoord, endCoord],
    strokeOpacity: 0,
    strokeColor: "#D63300",
    icons: [
      {
        icon: lineSymbol,
        offset: "0",
        repeat: "15px"
      }
    ]
  });

  line.setMap(map);
};

export const drawRoutes = (map, maps, routeList) => {
  const lineSymbol = {
    strokeColor: "#D63300",
    strokeOpacity: 1,
    strokeWeight: 3
  };
  routeList.forEach((listpoint) => {
    const directionsService = new maps.DirectionsService();
    const directionsDisplay = new maps.DirectionsRenderer({
      polylineOptions: lineSymbol,
      suppressMarkers: true,
      preserveViewport: true
    });

    directionsDisplay.setMap(map);
    const origin = listpoint[0];
    const destination = listpoint?.at(-1);

    directionsService.route(
      {
        origin: {
          lat: +origin?.latitude,
          lng: +origin?.longitude
        },
        destination: {
          lat: +destination?.latitude,
          lng: +destination?.longitude
        },
        travelMode: "DRIVING",
        waypoints: listpoint.map((point) => ({
          location: {
            lat: +point.latitude,
            lng: +point.longitude
          },
          stopover: true
        }))
      },
      function (response, status) {
        if (status === "OK") {
          directionsDisplay.setDirections(response);
        } else {
          console.log(`Directions request failed due to ${status}`);
        }
      }
    );
  });
};

export const getBounds = (arrLatLng) => {
  const latSort = arrLatLng
    .filter((marker) => marker?.latitude && marker?.longitude)
    .sort((a, b) => {
      return Number(a?.latitude) - Number(b?.latitude);
    });
  const lngSort = arrLatLng
    .filter((marker) => marker?.longitude && marker?.latitude)
    .sort((a, b) => {
      return Number(a?.longitude) - Number(b?.longitude);
    });

  const bound = {
    ne: {
      lat: Number(latSort.at(-1)?.latitude) || 35.80628, // lat ne default tokyo
      lng: Number(lngSort.at(-1)?.longitude) || 139.7875 // lng ne default tokyo
    },
    sw: {
      lat: Number(latSort.at(0)?.latitude) || 35.54612, // lat sw default tokyo
      lng: Number(lngSort.at(0)?.longitude) || 139.5131 // lng sw default tokyo
    }
  };
  return bound;
};

export const sliceArray = (array) => {
  if (array.length < 2) return [];
  let resultArray = [];
  const fisrtSubArray = array.slice(0, 25);
  resultArray.push(fisrtSubArray);
  for (let i = 24; i < array.length; i += 24) {
    const subArray = array.slice(i, i + 25);
    if (subArray.length < 2) break;
    resultArray.push(subArray);
  }
  return resultArray;
};
