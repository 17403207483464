import api from "./baseApi";

export const invoiceApi = {
  getList(params) {
    return api.get("/invoices/", { params });
  },
  changeDownloadStatus(params) {
    return api.post("/invoices/download-status", params);
  }
};
