import { Col } from "antd";
import { bool } from "prop-types";
import React from "react";
import { useSelector } from "react-redux";

import { ROLE } from "common/constant";
import { DIV_AUTH_GROUP, DIV_OFFICE_LOGIC, DIV_SERVICE } from "common/constant/account";

import { flowAccountStore } from "store/slices/flowAccount";

import { FormDetail, TitleDetail } from "components/form";

const InforAccount = ({ isDetail, canEdit = true }) => {
  const { formData, listClientCd, listBillingAddress, listOptOfficeInvoice, listOptOfficeOrder } =
    useSelector(flowAccountStore);

  const haveClientPage = formData?.main_office_div === "1";
  const haveTransPage = formData?.transport_div === "1";
  const isRoleCustomer = formData?.use_user_div === ROLE.CUSTOMER;
  const isRoleSale = formData?.use_user_div === ROLE.SUB_ADMIN;

  return (
    <Col span={canEdit ? 18 : 24}>
      <p style={{ marginLeft: "20px" }}>{!isDetail && "以下の内容で登録しますか？"}</p>
      <div>
        <TitleDetail title="アカウント情報" />
        <FormDetail label="ログインID" value={formData?.user_id} />
        <FormDetail label="ユーザー名" value={formData?.user_nm} />
        <FormDetail label="ユーザー名（フリガナ）" value={formData?.user_nm_kana} />
        <FormDetail label="電話番号" value={formData?.tel} />
        <FormDetail label="メールアドレス" value={formData?.mail_addr} />
        {!isDetail &&
          (formData?.login_password ? (
            <>
              <FormDetail label="パスワード" value={formData?.login_password} type="password" />
              <FormDetail
                label="パスワード（確認）"
                value={formData?.login_password_confirm}
                type="password"
              />
            </>
          ) : (
            <>
              <FormDetail label="パスワード" value="自動生成" />
              <FormDetail label="パスワード（確認）" value="自動生成" />
            </>
          ))}
      </div>
      {isRoleCustomer && (
        <>
          <div>
            <TitleDetail title="利用サービス" />
            <FormDetail
              label="利用サービス"
              value={formData}
              type="checkbox-group"
              listOptions={DIV_AUTH_GROUP}
            />
          </div>
          <div>
            <TitleDetail title="請求先コード識別 " />
            <FormDetail
              label="請求先コード識別"
              value={formData}
              type="checkbox-group"
              listOptions={DIV_SERVICE}
            />
            <FormDetail
              label="取扱営業所"
              value={formData?.office_logic_flag}
              type="select"
              listOptions={DIV_OFFICE_LOGIC}
            />

            <FormDetail
              label="営業所1"
              value={formData?.sale_office_1}
              type="select"
              listOptions={listOptOfficeInvoice}
            />
            <FormDetail
              label="営業所2"
              value={formData?.sale_office_2}
              type="select"
              listOptions={listOptOfficeInvoice}
            />
            <FormDetail
              label="営業所3"
              value={formData?.sale_office_3}
              type="select"
              listOptions={listOptOfficeInvoice}
            />
          </div>
          <div>
            <TitleDetail title="所属営業所" />
            <FormDetail
              label="所属営業所"
              value={formData?.office_order_cd}
              type="select"
              listOptions={listOptOfficeOrder}
            />
          </div>
          {listClientCd?.length > 0 && haveClientPage && (
            <div>
              <TitleDetail title="本社取引先" />
              {listClientCd?.map((item, index) => (
                <FormDetail key={item?.id} label={`取引先${index + 1}`} value={item?.name} />
              ))}
            </div>
          )}
          {listBillingAddress?.length > 0 && haveTransPage && (
            <div>
              <TitleDetail title="運送請求先" />
              {listBillingAddress?.map((item, index) => (
                <FormDetail key={item?.id} label={`請求先${index + 1}`} value={item?.name} />
              ))}
            </div>
          )}
        </>
      )}
      {isRoleSale && (
        <div>
          <TitleDetail title="所属営業所" />
          <FormDetail
            label="所属営業所"
            value={formData?.office_order_cd}
            type="select"
            listOptions={listOptOfficeOrder}
          />
        </div>
      )}
    </Col>
  );
};
InforAccount.propTypes = {
  isDetail: bool,
  canEdit: bool
};
export default InforAccount;
