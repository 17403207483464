import PropTypes from "prop-types";
import React from "react";

import { TIME_FORMAT } from "common/constant";
import { formatDateTime } from "common/utils/dateHelper";

import ItemView from "./ItemView";
import "./ServiceDetailHeader.scss";

import Tag from "components/common/Tag";

const ServiceDetailHeader = ({ data }) => {
  const getServiceStatus = () => {
    let type;
    switch (data?.service_status) {
      case "01":
        type = "service-pending";
        break;
      case "02":
        type = "service-running";
        break;
      case "03":
        type = "service-complete";
        break;
    }
    return type;
  };

  return (
    <div className="bg-safe-fill details-service-header rounded-4 pa-20 mt-20">
      <ItemView label="営業所" childrens={data?.office_nm} />
      <ItemView label="ドライバー" childrens={data?.driver_nm} />
      <ItemView label="ステータス" childrens={<Tag type={getServiceStatus()} size="sm" />} />
      <ItemView
        label="業務開始"
        childrens={formatDateTime(data?.start_time, TIME_FORMAT)}
        hideBorder
      />
      <ItemView
        label="業務終了"
        childrens={formatDateTime(data?.end_time, TIME_FORMAT)}
        hideBorder
      />
      <ItemView label="移動距離" childrens={
        data?.distance && !isNaN(Number(data?.distance)) 
          ? `${Math.floor(data?.distance/1000)} km` 
          : ""
      } hideBorder />
    </div>
  );
};

ServiceDetailHeader.propTypes = {
  data: PropTypes.object
};

export default ServiceDetailHeader;
