import { Col, Form, Input, Row } from "antd";
import PropTypes from "prop-types";
import React from "react";

FormDoubleInput.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  width: PropTypes.number,
  rules: PropTypes.array,
  disabled: PropTypes.bool
};

function FormDoubleInput({ label, name, width, rules, disabled }) {
  return (
    <Row className="form-item form-select">
      <Col span={7} className="label">
        <h1 className="label">{label}</h1>
      </Col>
      <Col span={13}>
        <Row>
          <Form.Item name={name?.at(0)} className="mr-10" rules={rules?.at(0)}>
            <Input
              disabled={disabled}
              autoComplete="off"
              style={{ width: width && `${width}px` }}
            />
          </Form.Item>
          <Form.Item name={name?.at(1)} rules={rules?.at(1)}>
            <Input
              disabled={disabled}
              autoComplete="off"
              style={{ width: width && `${width}px` }}
            />
          </Form.Item>
        </Row>
      </Col>
    </Row>
  );
}

export default FormDoubleInput;
