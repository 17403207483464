import { Col, Form, Input, Row, Tooltip } from "antd";
import { IconHelp } from "assets";
import PropTypes from "prop-types";
import React from "react";

import Tag from "components/common/Tag";
import ValidateTextBox from "components/common/ValidateTextBox";

const FormInputWithValidate = ({
  label,
  name,
  helperText,
  optional,
  rules,
  validateMsg,
  width,
  password,
  ...props
}) => {
  return (
    <Row className="form-item form-input">
      <Col span={7} className="label">
        <h1 className="label">{label}</h1>
      </Col>
      <Col span={17}>
        <div className="pos-rel">
          <Form.Item validateTrigger={["onBlur", "onChange"]} rules={rules} name={name}>
            {password ? (
              <Input.Password
                {...props}
                className="input-password"
                size="large"
                variant="outlined"
                iconRender={(visible) => (visible ? "パスワードを非表示" : "パスワードを表示")}
                style={{ width: width && `${width}px` }}
              />
            ) : (
              <Input style={{ width: `${width}px` }} {...props} />
            )}
          </Form.Item>
          {optional && <Tag type="optional" />}
        </div>
        {helperText && (
          <Tooltip
            placement="bottomLeft"
            title={helperText}
            overlayStyle={{ maxWidth: "max-content" }}
            className="icon-help"
          >
            <IconHelp />
          </Tooltip>
        )}
        <ValidateTextBox listMsg={validateMsg} />
      </Col>
    </Row>
  );
};
FormInputWithValidate.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  helperText: PropTypes.string,
  optional: PropTypes.bool,
  rules: PropTypes.array,
  validateMsg: PropTypes.array,
  width: PropTypes.number,
  password: PropTypes.bool
};
export default FormInputWithValidate;
