/* eslint-disable no-irregular-whitespace */
import Icon from "@ant-design/icons/lib/components/Icon";
import { Modal as AntdModal, Button, Col, Form, Row, Upload } from "antd";
import { IconPlus } from "assets";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import { IMAGE_TYPES, PDF_TYPE } from "common/constant";
import { rulesDetailBase } from "common/constant/rules";
import { formatDateOrder } from "common/utils/dateHelper";
import { trimObj } from "common/utils/objectHelper";

import { baseApi } from "services/base";

import { setAlertAction } from "store/slices/alert";

import BaseDetailFile from "./BaseDetailFile";
import FooterUpdateBase from "./FooterUpdateBase";
import "./FormBaseDetail.scss";

import Modal from "components/common/Modal";
import { FormDetail, FormInput, TitleDetail } from "components/form";
import FormAreaHorizon from "components/form/FormAreaHorizon";
import FormDoubleInput from "components/form/FormDoubleInput";
import FormTimeRange from "components/form/FormTimeRange";

function FormBaseDetail({ dataSource, form, refetch }) {
  const dispatch = useDispatch();
  const { baseId: chart_cd } = useParams();
  const [isLoadingPdf, setIsLoadingPdf] = useState(false);
  const [isLoadingImg, setIsLoadingImg] = useState(false);

  const [isShowDeletePdf, setIsShowDeletePdf] = useState(false);
  const [photoNoDelete, setPhotoNoDelete] = useState();
  const [previewModal, setPreviewModal] = useState({ show: false, isPdf: false, src: "" });
  const timeFrom = Form.useWatch("term_from", form);
  const timeTo = Form.useWatch("term_to", form);

  const dataSourceFilter = {
    ...dataSource,
    term_from: dataSource?.term_from ? moment(dataSource?.term_from, "HH:mm") : null,
    term_to: dataSource?.term_to ? moment(dataSource?.term_to, "HH:mm") : null
  };

  const handleBeforeUpload = async (file, isPdf) => {
    if (isPdf) {
      setIsLoadingPdf(true);
      const isValidType = file.type === PDF_TYPE;
      const isValidSize = file.size / 1024 / 1024 <= 3;
      if (!isValidSize) {
        dispatch(
          setAlertAction({
            message: "ファイル容量が超過しています。カルテPDFは3MB以内です。",
            type: "error"
          })
        );
        setIsLoadingPdf(false);
      }
      if (!isValidType) {
        dispatch(
          setAlertAction({
            message: "アップロード可能な形式のファイルを添付して下さい",
            type: "error"
          })
        );
        setIsLoadingPdf(false);
      }
      if (isValidSize && isValidType) {
        const formData = new FormData();
        formData.append("file", file);

        try {
          await baseApi.uploadChartPdf(chart_cd, formData);
          await refetch();
        } catch (error) {
          dispatch(
            setAlertAction({
              message: "予期せぬエラーが発生しました",
              type: "error"
            })
          );
        }
        setIsLoadingPdf(false);
      }
    } else {
      const isValidType = IMAGE_TYPES.includes(file.type);
      const isValidSize = file.size / 1024 / 1024 <= 10;
      setIsLoadingImg(true);
      if (!isValidSize) {
        dispatch(
          setAlertAction({
            message: "ファイル容量が超過しています。地点写真は10MB以内です。",
            type: "error"
          })
        );
        setIsLoadingImg(false);
      }

      if (!isValidType) {
        dispatch(
          setAlertAction({
            message: "アップロード可能な形式のファイルを添付して下さい",
            type: "error"
          })
        );
        setIsLoadingImg(false);
      }
      if (isValidSize && isValidType) {
        const formData = new FormData();
        formData.append("chart_cd", chart_cd);
        formData.append("file", file);

        try {
          await baseApi.uploadBasePhoto(formData);
          await refetch();
        } catch (error) {
          dispatch(
            setAlertAction({
              message: "予期せぬエラーが発生しました",
              type: "error"
            })
          );
        }
        setIsLoadingImg(false);
      }
    }
  };

  const disableTimeUp = (timeFrom, timeTo) => {
    let hourUp = [];
    let minuteUp = [];
    let hourDown = [];
    let minuteDown = [];

    for (let i = 0; i <= 60; i++) {
      if (i < timeFrom?.minute()) minuteUp.push(i);
      if (i > timeTo?.minute()) minuteDown.push(i);
    }
    for (let i = 0; i <= 24; i++) {
      if (i < timeFrom?.hour()) hourUp.push(i);
      if (i > timeTo?.hour()) hourDown.push(i);
    }

    return {
      hourUp,
      hourDown,
      minuteUp,
      minuteDown,
      hourFrom: timeFrom?.hour(),
      hourTo: timeTo?.hour()
    };
  };
  const handleFinishForm = async (data) => {
    const dataUpdate = trimObj({
      ...data,
      term_from: data.term_from ? data.term_from.format("HH:mm") : null,
      term_to: data.term_to ? data.term_to.format("HH:mm") : null
    });

    await baseApi
      .updateBase(chart_cd, dataUpdate)
      .then(() => {
        refetch();
        dispatch(
          setAlertAction({ message: "お客様カルテマスタ情報を変更しました", type: "success" })
        );
      })
      .catch(() => {
        dispatch(
          setAlertAction({
            message: "予期せぬエラーが発生しました",
            type: "error"
          })
        );
      });
  };

  const openPhotoDelete = (no) => {
    setPhotoNoDelete(no);
  };

  const openDeletePdf = () => {
    setIsShowDeletePdf(true);
  };

  const handleDeletePdf = async () => {
    try {
      await baseApi.deleteChartPdf(chart_cd);
      refetch();
    } catch (error) {
      dispatch(
        setAlertAction({
          message: "予期せぬエラーが発生しました",
          type: "error"
        })
      );
    }
  };

  const handleDeletePhoto = async () => {
    try {
      await baseApi.deleteBasePhoto(photoNoDelete);
      refetch();
    } catch (error) {
      dispatch(
        setAlertAction({
          message: "予期せぬエラーが発生しました",
          type: "error"
        })
      );
    }
  };

  return (
    <div className="mt-24">
      {dataSource ? (
        <Form
          onFinish={handleFinishForm}
          initialValues={dataSourceFilter}
          autoComplete="off"
          form={form}
        >
          <TitleDetail title="拠点情報" />
          <div>
            <FormDetail value={dataSourceFilter?.chart_cd} label="お客様カルテコード" />
            <FormDetail value={dataSourceFilter?.name} label="名称" />
            <FormDetail
              value={`${dataSourceFilter?.postcd || "-"}　　${dataSourceFilter?.address || "-"}`}
              label="住所"
            />
            <FormDetail value={dataSourceFilter?.tel_number || "-"} label="電話番号" />
            <FormDoubleInput
              disabled
              label="緯度・経度"
              width={320}
              name={["latitude", "longitude"]}
            />
            <FormDetail
              value={`更新者：${dataSourceFilter?.lonlat_update_user_nm || "-"}　　更新日時：${
                dataSourceFilter?.lonlat_update_datetime
                  ? formatDateOrder(dataSourceFilter?.lonlat_update_datetime)
                  : "-"
              }`}
              label="緯度・経度最終更新情報"
            />
            <FormTimeRange
              label="配達可能時間"
              name={["term_from", "term_to"]}
              disable={disableTimeUp(timeFrom, timeTo)}
            />
            <FormInput
              label="車両制限"
              width={320}
              name="car_limit"
              rules={rulesDetailBase.car_limit}
            />
            <FormAreaHorizon
              label="地点情報"
              name="information"
              rules={rulesDetailBase.information}
            />
            <FormDetail
              value={`更新者：${dataSourceFilter?.information_update_user_nm || "-"}　　更新日時：${
                dataSourceFilter?.information_update_datetime
                  ? formatDateOrder(dataSourceFilter?.information_update_datetime)
                  : "-"
              }`}
              label="地点情報最終更新情報"
            />
            <FooterUpdateBase
              handleSubmitChanged={() => {
                form.submit();
              }}
            />
          </div>
        </Form>
      ) : null}

      <TitleDetail title="添付ファイル" />
      <Row align="middle" justify="space-between" className="w-400 ml-40 mb-24">
        <Col>
          <div className="fs-16 text-label fw-700">カルテPDF</div>
        </Col>
        <Col>
          <Upload
            accept=".pdf"
            showUploadList={false}
            beforeUpload={(file) => {
              handleBeforeUpload(file, true);
              return false;
            }}
          >
            <Button
              disabled={dataSource?.chart_pdf_url || isLoadingPdf}
              type="link"
              className="fs-16 w-full"
              icon={
                <Icon
                  className={`${
                    dataSource?.chart_pdf_url || isLoadingPdf ? "fill-text-disable" : "fill-link"
                  } fs-18`}
                  component={IconPlus}
                />
              }
            >
              カルテPDFを追加
            </Button>
          </Upload>
        </Col>
      </Row>
      {dataSource?.chart_pdf_url && (
        <Row className="ml-32">
          <Col span={8}>
            <BaseDetailFile
              onDelete={openDeletePdf}
              isPdf={true}
              src={dataSource.chart_pdf_url}
              onPreview={() => {
                setPreviewModal({ show: true, isPdf: true, src: dataSource.chart_pdf_url });
              }}
            />
          </Col>
        </Row>
      )}
      <Row align="middle" justify="space-between" className="w-400 ml-40 mb-24">
        <Col>
          <div className="fs-16 text-label fw-700">地点写真</div>
        </Col>
        <Col>
          <Upload
            accept="image/png, image/jpeg, image/gif, image/bmp"
            showUploadList={false}
            beforeUpload={(file) => {
              handleBeforeUpload(file, false);
              return false;
            }}
          >
            <Button
              disabled={dataSource?.base_photos.length >= 10 || isLoadingImg}
              type="link"
              className="w-full fs-16"
              icon={
                <Icon
                  className={`${
                    dataSource?.base_photos.length >= 10 || isLoadingImg
                      ? "fill-text-disable"
                      : "fill-link"
                  } fs-18`}
                  component={IconPlus}
                />
              }
            >
              地点写真を追加
            </Button>
          </Upload>
        </Col>
      </Row>
      <Row className="ml-32" gutter={[20, 20]}>
        {dataSource?.base_photos.map((item) => (
          <Col span={8} key={item.base_photo_no}>
            <BaseDetailFile
              onDelete={() => {
                openPhotoDelete(item.base_photo_no);
              }}
              data={item}
              onPreview={() => {
                setPreviewModal({ show: true, isPdf: false, src: item.photo_url });
              }}
            />
          </Col>
        ))}
      </Row>
      <AntdModal
        width={976}
        visible={previewModal.show}
        onCancel={() => {
          setPreviewModal({ show: false, isPdf: false, src: "" });
        }}
        footer={null}
      >
        {previewModal.isPdf ? (
          <iframe title="TestPDF" width="100%" height="700px" src={dataSource.chart_pdf_url} />
        ) : (
          <img width="100%" height="auto" src={previewModal.src} />
        )}
      </AntdModal>
      <Modal
        danger
        title="選択した カルテPDFを削除しますよ"
        secondTitle="ろしいですか？"
        subTitle="削除すると元に戻せません"
        isShowModal={isShowDeletePdf}
        setIsShowModal={setIsShowDeletePdf}
        handleConfirmOk={handleDeletePdf}
      />

      <Modal
        danger
        title="選択した地点写真を削除しますよろし"
        secondTitle="いですか？"
        subTitle="削除すると元に戻せません"
        isShowModal={Boolean(photoNoDelete)}
        setIsShowModal={setPhotoNoDelete}
        handleConfirmOk={handleDeletePhoto}
      />
    </div>
  );
}

export default FormBaseDetail;

FormBaseDetail.propTypes = {
  dataSource: PropTypes.object,
  form: PropTypes.any,
  refetch: PropTypes.func
};
