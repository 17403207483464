import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { orderApi } from "services/order";

const Attachment = () => {
  const [params] = useSearchParams();

  const fetchAttachment = async () => {
    const fileName = params.get("file_name");
    const res = await orderApi.getAttachment(fileName);
    window.open(res.msg, "_blank");
  };

  useEffect(() => {
    fetchAttachment();
  }, []);

  return <div></div>;
};

export default Attachment;
