import PropTypes from "prop-types";
import React from "react";

import "./ItemView.scss";

ItemView.propTypes = {
  label: PropTypes.string.isRequired,
  childrens: PropTypes.node,
  hideBorder: PropTypes.bool,
  unit: PropTypes.string,
  subLabel: PropTypes.string
};

function ItemView({ label, childrens, hideBorder, unit, subLabel }) {
  return (
    <div className={`${hideBorder ? "" : "item-view"} py-12`}>
      <div className="fw-700 fs-12 black-050 item-label">
        {label} {subLabel && <span className="black">({subLabel})</span>}
      </div>
      <div className="item-view-content black fw-500 fs-14 mt-8 min-h-24 item-content">
        {childrens || "-"}
        {unit}
      </div>
    </div>
  );
}

export default ItemView;
