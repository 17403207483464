import { createSlice } from "@reduxjs/toolkit";

import { arrivalApi } from "services/arrival";
import { masterDataApi } from "services/masterData";
import { serviceApi } from "services/service";

export const slice = createSlice({
  name: "pulldown",
  initialState: {
    listBillingAddr: [],
    listAddressJisPickup: [],
    listAddressJis: [],
    listPickupAddr: [],
    listOptItems: [],
    listOptOffices: [],
    listOptOfficesInvoice: [],
    listOptDeliTime: [],
    listOptDeliVehicleType: [],
    listOptUnitQuantity: [],
    listOptUnitVolume: [],
    listOptStatus: [],
    listOptAuthGroups: [],
    listOptUserTypes: [],
    listOptVehicleChapter: [],
    listOptDeliveryStatus: [],
    listOptServiceStatus: [],
    listOptServiceDriver: [],
    listOptServiceYmd: [],
    errorMessage: ""
  },
  reducers: {
    getBillingAddr: (state, action) => {
      state.listBillingAddr = action.payload;
    },
    getListAddressJisPickup: (state, action) => {
      state.listAddressJisPickup = action.payload;
    },
    getListAddressJis: (state, action) => {
      state.listAddressJis = action.payload;
    },
    getListPickupAddr: (state, action) => {
      state.listPickupAddr = action.payload;
    },
    getListOptItems: (state, action) => {
      state.listOptItems = action.payload;
    },
    getListOptOffices: (state, { payload }) => {
      state.listOptOffices = payload.optOfficesPayload;
      state.listOptOfficesInvoice = payload.optOfficesInvoicePayload;
    },
    getListOptStatic: (state, { payload }) => {
      state.listOptDeliTime = payload.optDeliTimesPayload;
      state.listOptDeliVehicleType = payload.optDeliVehicleTypesPayload;
      state.listOptUnitQuantity = payload.optUnitQuantityPayload;
      state.listOptUnitVolume = payload.optUnitVolumesPayload;
      state.listOptStatus = payload.optStatusPayload;
      state.listOptAuthGroups = payload.optAuthGroups;
      state.listOptUserTypes = payload.optUserTypes;
      state.listOptVehicleChapter = payload.optVehicleChapter;
    },
    getAllFlexPullDown: (state, action) => {
      const { listBillingAddr, listAddressJis, listPickupAddr, listOptItems } = action.payload;
      state.listBillingAddr = listBillingAddr;
      state.listAddressJis = listAddressJis;
      state.listPickupAddr = listPickupAddr;
      state.listOptItems = listOptItems;
    },
    clearDataPulldown: (state, action) => {
      state[action.payload] = [];
    },
    getArrivalListPullDown: (state, { payload }) => {
      state.listOptDeliveryStatus = payload.listOptDeliveryStatus;
      state.listOptOffices = payload.optOfficesPayload;
    },
    getServiceListPullDown: (state, { payload }) => {
      state.listOptServiceStatus = payload.listOptServiceStatus;
      state.listOptOffices = payload.optOfficesPayload;
      state.listOptServiceYmd = payload.listOptServiceYmd;
    },
    getServiceDriverPulldownList: (state, { payload }) => {
      state.listOptServiceDriver = payload.listOptServiceDriver;
    },
    setFail: (state, action) => {
      state.error = action.payload;
    }
  }
});

// Action creators are generated for each case reducer function
export const {
  getBillingAddr,
  getListAddressJisPickup,
  getListAddressJis,
  getListPickupAddr,
  getListOptItems,
  getListOptOffices,
  getListOptStatic,
  clearDataPulldown,
  getArrivalListPullDown,
  setFail,
  getAllFlexPullDown,
  getServiceListPullDown,
  getServiceDriverPulldownList
} = slice.actions;

// Async action

export const getListBillingAddrAction = (office_cd, callback) =>
  async function (dispatch) {
    try {
      const response = await masterDataApi.getBillingAddresses(office_cd);
      const payload = response?.map((item) => ({
        label: item?.billing_addr_nm,
        value: item?.billing_addr_cd
      }));
      dispatch(getBillingAddr(payload));
      callback(response[0]?.billing_addr_cd);
    } catch (error) {
      dispatch(setFail(error?.response?.data?.detail));
    }
  };

export const getListAddressJisPickupAction = (post_cd) =>
  async function (dispatch) {
    try {
      const response = await masterDataApi.getAddressJis(post_cd);
      const payload = response?.map(({ area_cd, post_area_nm, pref_nm, town_nm }) => ({
        value: [area_cd, [pref_nm, post_area_nm, town_nm].join("")].join(" "),
        label: [pref_nm, post_area_nm, town_nm].join("")
      }));
      dispatch(getListAddressJisPickup(payload));
    } catch (error) {
      dispatch(setFail(error?.response?.data?.detail));
    }
  };

export const getListAddressJisAction = (post_cd) =>
  async function (dispatch) {
    try {
      const response = await masterDataApi.getAddressJis(post_cd);
      const payload = response?.map(({ area_cd, post_area_nm, pref_nm, town_nm }) => ({
        value: [area_cd, [pref_nm, post_area_nm, town_nm].join("")].join(" "),
        label: [pref_nm, post_area_nm, town_nm].join("")
      }));
      dispatch(getListAddressJis(payload));
    } catch (error) {
      dispatch(setFail(error?.response?.data?.detail));
    }
  };

export const getListPickupAddrAction = ({ office_cd, billing_addr_cd }) =>
  async function (dispatch) {
    try {
      const response = await masterDataApi.getPickupAddress({ office_cd, billing_addr_cd });
      const payload = response?.map((item) => ({
        value: item?.pickup_addr_cd,
        label: item?.pickup_addr_nm,
        ...item
      }));
      dispatch(getListPickupAddr(payload));
    } catch (error) {
      dispatch(setFail(error?.response?.data?.detail));
    }
  };

export const getListOptItemsAction = ({ office_cd, pickup_addr_cd }) =>
  async function (dispatch) {
    try {
      const response = await masterDataApi.getItems({
        office_cd,
        pickup_addr_cd
      });
      const payload = response?.map((item) => ({
        value: item?.item_cd,
        label: item?.item_nm,
        ...item
      }));
      dispatch(getListOptItems(payload));
    } catch (error) {
      dispatch(setFail(error?.response?.data?.detail));
    }
  };

export const getListOptOfficesAction = (page) =>
  async function (dispatch) {
    try {
      const [listOptOffices, listOptOfficesInvoice] = await Promise.all([
        page == "operation"
          ? masterDataApi.getOperationPageOffices()
          : masterDataApi.getOffices(),
        masterDataApi.getOfficesInvoices()
      ]);
      const optOfficesPayload =
        listOptOffices?.map((office) => ({
          value: office.office_cd,
          label: office.office_nm,
          tel: office?.tel
        })) || [];
      const optOfficesInvoicePayload =
        listOptOfficesInvoice?.map((office) => ({
          value: office.office_cd,
          label: office.office_nm,
          tel: office?.tel
        })) || [];
      dispatch(
        getListOptOffices({
          optOfficesPayload,
          optOfficesInvoicePayload
        })
      );
    } catch (error) {
      dispatch(setFail(error?.response?.data?.detail));
    }
  };

export const getListArrivalStatusOpt = () =>
  async function (dispatch) {
    try {
      const [responseDeliveryStatus, responseOffices] = await Promise.all([
        arrivalApi.getDeliveryStatus(),
        masterDataApi.getOffices()
      ]);
      const listOptDeliveryStatus = responseDeliveryStatus?.map((item) => ({
        value: item?.div_value,
        label: item?.div_value_nm
      }));
      const optOfficesPayload = responseOffices?.map((item) => ({
        value: item?.office_cd,
        label: item?.office_nm
      }));
      dispatch(getArrivalListPullDown({ listOptDeliveryStatus, optOfficesPayload }));
    } catch (error) {
      dispatch(setFail(error?.response?.data?.detail));
    }
  };
export const getServiceDriverList = (params) =>
  async function (dispatch) {
    try {
      const responseDriver = await masterDataApi.getDriver(params);
      const listOptServiceDriver = responseDriver?.map((item) => ({
        value: item?.driver_cd,
        label: `${item?.driver_nm_last} ${item?.driver_nm_first ? item.driver_nm_first : ""}`
      }));

      dispatch(getServiceDriverPulldownList({ listOptServiceDriver }));
    } catch (error) {
      dispatch(setFail(error?.response?.data?.detail));
    }
  };

export const getListServiceOpt = () =>
  async function (dispatch) {
    try {
      const [responseServiceStatus, responseOffices, responseServiceYmd] = await Promise.all([
        serviceApi.getServiceStatus(),
        masterDataApi.getOffices(),
        masterDataApi.getServiceYmdDiv()
      ]);
      const listOptServiceStatus = responseServiceStatus?.map((item) => ({
        value: item?.div_value,
        label: item?.div_value_nm
      }));
      const optOfficesPayload = responseOffices?.map((item) => ({
        value: item?.office_cd,
        label: item?.office_nm
      }));
      const listOptServiceYmd = responseServiceYmd?.map((item) => ({
        value: item?.div_value,
        label: item?.div_value_nm
      }));

      dispatch(
        getServiceListPullDown({
          listOptServiceStatus,
          optOfficesPayload,
          listOptServiceYmd
        })
      );
    } catch (error) {
      dispatch(setFail(error?.response?.data?.detail));
    }
  };

export const getListOptionStatic = () =>
  async function (dispatch) {
    try {
      const [
        listOptDeliTime,
        listOptDeliVehicleType,
        listOptUnitQuantity,
        listOptUnitVolume,
        listOptStatus,
        listAuthGroups,
        listUserTypes,
        listOptVehicleChapter
      ] = await Promise.all([
        masterDataApi.getDivDeliveryTime(),
        masterDataApi.getDivDeliveryVehicle(),
        masterDataApi.getDivUnitQuantity(),
        masterDataApi.getDivUnitVolume(),
        masterDataApi.getDivStatus(),
        masterDataApi.getAuthGroups(),
        masterDataApi.getUserTypes(),
        masterDataApi.getDivVehicleChapter()
      ]);

      const generateListOpt = (response) =>
        response
          ?.sort((a, b) => a.display_order - b.display_order)
          ?.map((item) => ({
            label: item?.div_value_nm,
            value: item?.div_value
          }));
      const optDeliTimesPayload = generateListOpt(listOptDeliTime);
      const optDeliVehicleTypesPayload = generateListOpt(listOptDeliVehicleType);
      const optUnitQuantityPayload = generateListOpt(listOptUnitQuantity);
      const optUnitVolumesPayload = generateListOpt(listOptUnitVolume);
      const optStatusPayload = generateListOpt(listOptStatus);
      const optUserTypes = generateListOpt(listUserTypes);
      const optVehicleChapter = generateListOpt(listOptVehicleChapter);
      const optAuthGroups = listAuthGroups.map((item) => ({
        label: item?.auth_group_nm,
        value: item?.auth_group_id
      }));

      dispatch(
        getListOptStatic({
          optDeliTimesPayload,
          optDeliVehicleTypesPayload,
          optUnitQuantityPayload,
          optUnitVolumesPayload,
          optStatusPayload,
          optAuthGroups,
          optUserTypes,
          optVehicleChapter
        })
      );
    } catch (error) {
      dispatch(setFail(error?.response?.data?.detail));
    }
  };
export const getAllFlexPullDownAction = (params) => async (dispatch) => {
  const { office_cd, delivery_addr_postcd, pickup_addr_postcd } = params;
  dispatch(getListBillingAddrAction(office_cd));
  dispatch(getListPickupAddrAction(params));
  dispatch(getListOptItemsAction(params));
  dispatch(getListAddressJisAction(delivery_addr_postcd));
  dispatch(getListAddressJisPickupAction(pickup_addr_postcd));
};

export const clearDataPulldownAction = (listOpts) =>
  function (dispatch) {
    dispatch(clearDataPulldown(listOpts));
  };
// Get value from state
export const pulldownStore = (state) => state.pulldown;

export default slice.reducer;
