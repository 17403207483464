import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

import { getListOptOfficesAction } from "store/slices/pulldown";

import CloneOrder from "./CloneOrder";
import CreateOrder from "./CreateOrder";

function CreateOrderContainer() {
  const { state } = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getListOptOfficesAction());
  }, []);

  return state?.orderId ? <CloneOrder orderId={state?.orderId} /> : <CreateOrder />;
}

export default CreateOrderContainer;
