import { Button } from "antd";
import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { flowAccountStore } from "store/slices/flowAccount";
import "./FooterCreate.scss";

const FooterCreate = ({
  currentPageFlag,
  isFormConfirm,
  setIsFormConfirm,
  setCurrentPageFlag,
  toggleFormDone,
  handleSaveDraft
}) => {
  const { formData } = useSelector(flowAccountStore);
  const redirectEdit = () => {
    setIsFormConfirm && setIsFormConfirm(false);
    setCurrentPageFlag && setCurrentPageFlag("form");
  };

  const handleBack = () => {
    const haveClientPage = formData["main_office_div"] === "1";
    switch (currentPageFlag) {
      case "trans": {
        if (haveClientPage) setCurrentPageFlag("client");
        else setCurrentPageFlag("form");
        break;
      }
      case "client": {
        setCurrentPageFlag("form");
        break;
      }
      default:
        break;
    }
  };

  return (
    <div className="footer-create-customer bg-white">
      <div className="hint">内容の確認後、決定ボタンを押してください</div>
      <div>
        <Button onClick={handleSaveDraft} className="btn-submit" size="large">
          下書き保存
        </Button>
        <Button onClick={toggleFormDone} className="btn-submit" type="primary" size="large">
          {isFormConfirm ? "登録" : "次へ"}
        </Button>
      </div>
      {isFormConfirm ? (
        <Button onClick={redirectEdit} style={{ marginTop: "10px" }} type="link">
          内容を修正する
        </Button>
      ) : (
        currentPageFlag !== "form" &&
        currentPageFlag && (
          <Button onClick={handleBack} type="link">
            前のページへ移動する
          </Button>
        )
      )}
    </div>
  );
};
FooterCreate.propTypes = {
  currentPageFlag: PropTypes.string,
  isFormConfirm: PropTypes.bool,
  setIsFormConfirm: PropTypes.func,
  setCurrentPageFlag: PropTypes.func,
  toggleFormDone: PropTypes.func,
  handleSaveDraft: PropTypes.func
};
export default FooterCreate;
