import { Button, Col, Form, Row } from "antd";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ROLE } from "common/constant";
import useAuth from "common/hooks/useAuth";
import { getObjectOnlyValue } from "common/utils/objectHelper";

import { masterDataApi } from "services/masterData";
import { serviceApi } from "services/service";

import { pulldownStore } from "store/slices/pulldown";
import { getListOptOfficesAction } from "store/slices/pulldown";

import { FormSelect } from "components/form";

const OperationHeader = ({ form, numService, onSearch }) => {
  const { listOptOffices } = useSelector(pulldownStore);
  const [listDriver, setListDriver] = useState([]);
  const [listCarNumber, setListCarNumber] = useState([]);
  const { role, branchOfficeCd } = useAuth();

  const dispatch = useDispatch();
  const office = Form.useWatch("office_cd", form);

  useEffect(async () => {
    if (office) {
      const listDriver = await masterDataApi.getDriver({ office_cd: office });
      const listCarNum = await serviceApi.getListCarNum({ office_cd: office });

      const listDriverOption = listDriver.map((driver) => ({
        value: driver.driver_cd,
        label: driver.driver_nm_last + (driver.driver_nm_first ? ` ${driver.driver_nm_first}` : "")
      }));

      const listCarNumOption = listCarNum.map((car) => ({
        value: car.car_number,
        label: car.car_number
      }));

      setListCarNumber(listCarNumOption);
      setListDriver(listDriverOption);
    } else {
      setListCarNumber([]);
      setListDriver([]);
    }

    form.resetFields(["driver_cd", "car_number"]);
  }, [office]);

  useEffect(() => {
    dispatch(getListOptOfficesAction("operation"));
  }, []);

  useEffect(() => {
    if (role === ROLE.SUB_ADMIN && listOptOffices?.length > 0 && branchOfficeCd) {
      form.setFieldsValue({ office_cd: branchOfficeCd });
    }
  }, [listOptOffices, role, branchOfficeCd]);

  const handleSubmitForm = (value) => {
    onSearch(getObjectOnlyValue(value));
  };

  return (
    <div className="operation-header">
      <Row align="middle" className="bg-white" justify="space-between">
        <Col span={4}>
          <div className="dis-flex ai-center pl-10">
            <div className="fs-32 fw-700 mr-6 mb-6"> {numService}</div>
            <div>台 稼働中</div>
          </div>
        </Col>
        <Form form={form} onFinish={handleSubmitForm}>
          <Row align="middle">
            <FormSelect
              name="office_cd"
              listOptions={listOptOffices}
              label="営業所"
              width={160}
            />
            <FormSelect name="driver_cd" listOptions={listDriver} label="ドライバー" width={160} />
            <FormSelect
              name="car_number"
              listOptions={listCarNumber}
              label="車両番号"
              width={160}
            />
            <Col className="mr-28 ml-20 h-32">
              <Button htmlType="submit" type="primary">
                絞り込み
              </Button>
            </Col>
          </Row>
        </Form>
      </Row>
    </div>
  );
};

OperationHeader.propTypes = {
  form: PropTypes.object,
  numService: PropTypes.number,
  onSearch: PropTypes.func,
};

export default OperationHeader;
