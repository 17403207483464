import { IconSpot } from "assets";
import React from "react";

import "./SpotMarker.scss";

function NextSpotMarker() {
  return (
    <div className="next-spot">
      <div className="map-marker">
        <IconSpot />
      </div>
      <div className="map-marker-pointer" />
    </div>
  );
}

export default NextSpotMarker;
