import { Button, Modal } from "antd";
import React, { useEffect, useState } from "react";

function LoginDriver() {
  const [isOpenModal, setIsOpenModel] = useState(false);

  useEffect(() => {
    const userAgent = window.navigator.userAgent;
    if (/Android/.test(userAgent)) {
      setIsOpenModel(true);
    }
  }, []);

  return (
    <div>
      {isOpenModal && (
        <Modal
          open={isOpenModal}
          footer={null}
          onCancel={() => {
            setIsOpenModel(false);
          }}
        >
          <div className="text-center">
            <div className="mb-20 fw-700">
              アプリストアからTLSモバイルをインストールしてください
            </div>
            <Button type="primary">
              <a href="https://play.google.com/store/apps/details?id=com.adobe.reader">
                Playストアに移動
              </a>
            </Button>
          </div>
        </Modal>
      )}
    </div>
  );
}

export default LoginDriver;
