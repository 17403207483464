import { Col, Row } from "antd";
import { useForm } from "antd/lib/form/Form";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { serviceApi } from "services/service";

import { authStore } from "store/slices/auth";

import "./Operation.scss";
import OperationHeader from "./components/OperationHeader";
import OperationMap from "./components/OperationMap";
import OperationServiceList from "./components/OperationServiceList";

const Operation = () => {
  const [listOperation, setListOperation] = useState([]);
  const { company_cd, branchOfficeCd } = useSelector(authStore);
  const [activeService, setActiveService] = useState({});
  const [check, setCheck] = useState(false);
  const [listAct, setListAct] = useState([]);
  const [paramFilters, setParamFilters] = useState({});
  const [form] = useForm();

  useEffect(() => {
    if (branchOfficeCd)
      if (branchOfficeCd == "0000")
        fetchOperations({});
      else
        fetchOperations({ office_cd: branchOfficeCd });
  }, [branchOfficeCd]);

  const fetchOperations = async (queryParams) => {
    const response = await serviceApi.getListOperation({ ...queryParams, company_cd });

    setListOperation(response);
    setActiveService(response.length > 0 ? response[0] : {});
    setParamFilters({ ...queryParams, company_cd });
  };

  const onSearch = async (searchParams) => {
    const response = await serviceApi.getListOperation({ ...searchParams, company_cd });

    setListOperation(response);
    setActiveService(response.length > 0 ? response[0] : {});
    setParamFilters({ ...searchParams, company_cd });

    // reset data after search
    scrollToTop();
    setCheck(false);
    setListAct([]);
  };

  const onRefetch = async () => {
    const response = await serviceApi.getListOperation(paramFilters);

    setListOperation(response);

    if (response.length > 0) {
      setActiveService(response.find(service => service.service_no == activeService.service_no));
    } else setActiveService({});

    if (check) {
      const res = await serviceApi.getListActOperation(activeService.service_no);
      setListAct(res);
    }
  };

  const scrollToTop = () => {
    const listOperationElement = document.getElementById("operation-list");
    listOperationElement.scrollTop = 0;
  };

  const handleChangeActive = (data) => {
    if (data.service_no !== activeService.service_no) {
      setActiveService(data);
      setCheck(false);
      setListAct([]);
    }
  };

  const handleToggleCheck = async (service) => {
    if (!check || service.service_no !== activeService.service_no) {
      const res = await serviceApi.getListActOperation(service.service_no);
      setListAct(res);

      if (service.service_no !== activeService.service_no) {
        setActiveService(service);
        if (!check) setCheck(true);
      } else setCheck(true);
    } else {
      setListAct([]);
      setCheck(false);
    }
  };

  return (
    <div id="operation-page">
      <OperationHeader
        form={form}
        numService={listOperation.length}
        onSearch={onSearch}
      />
      <Row>
        <Col flex={"270px"}>
          <OperationServiceList
            listOperation={listOperation}
            activeService={activeService}
            setActiveService={setActiveService}
            onChangeActive={handleChangeActive}
            onToggleCheck={handleToggleCheck}
            check={check}
          />
        </Col>
        <Col flex={"auto"}>
          <OperationMap
            listAct={listAct}
            refetch={onRefetch}
            activeService={activeService}
            unActiveService={listOperation.filter(
              (opration) => opration.service_no !== activeService.service_no)
            }
            onChangeActive={handleChangeActive}
          />
        </Col>
      </Row>
    </div>
  );
};

export default Operation;
