import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

import { formatFilterDateSlash } from "common/utils/dateHelper";

export const slice = createSlice({
  name: "listFilter",
  initialState: {
    filterServiceObject: {
      service_ymd_start: formatFilterDateSlash(moment()),
      service_ymd_end: formatFilterDateSlash(moment())
    }
  },
  reducers: {
    setFilterService: (state, action) => {
      state.filterServiceObject = action.payload;
    }
  }
});

// Action creators are generated for each case reducer function
export const { setFilterService } = slice.actions;

// Async action

export const setFilterServiceAction = (object) =>
  function (dispatch) {
    dispatch(setFilterService(object));
  };

// Get value from state

export const listFilterStore = (state) => state.listFilter;

export default slice.reducer;
