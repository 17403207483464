import { Col, Form, Row, TimePicker } from "antd";
import PropTypes from "prop-types";
import React from "react";

FormTimeRange.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  disable: PropTypes.object
};

function FormTimeRange({ label, name, disable }) {
  return (
    <Row className="form-item form-select">
      <Col span={7} className="label">
        <h1 className="label">{label}</h1>
      </Col>
      <Col span={8}>
        <Row justify="space-between">
          <Col span={11}>
            <Form.Item name={name?.at(0)}>
              <TimePicker
                showNow={false}
                format="HH:mm"
                className="w-full"
                inputReadOnly
                placeholder=""
                disabledTime={() => ({
                  disabledHours: () => disable.hourDown,
                  disabledMinutes: (hour) => (hour === disable.hourTo ? disable.minuteDown : [])
                })}
              />
            </Form.Item>
          </Col>
          <Col span={2}>
            <div className="fs-24 flex-center">~</div>
          </Col>
          <Col span={11}>
            <Form.Item name={name?.at(1)}>
              <TimePicker
                showNow={false}
                format="HH:mm"
                className="w-full"
                inputReadOnly
                placeholder=""
                disabledTime={() => ({
                  disabledHours: () => disable.hourUp,
                  disabledMinutes: (hour) => (hour === disable.hourFrom ? disable.minuteUp : [])
                })}
              />
            </Form.Item>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default FormTimeRange;
