import { Button, Modal as ModalAntd, Row } from "antd";
import PropTypes from "prop-types";
import React from "react";

Modal.propTypes = {
  title: PropTypes.string,
  danger: PropTypes.bool,
  subTitle: PropTypes.string,
  handleConfirmOk: PropTypes.func,
  isShowModal: PropTypes.bool,
  setIsShowModal: PropTypes.func,
  btnSubmitTitle: PropTypes.string,
  secondTitle: PropTypes.string
};

function Modal({
  title,
  secondTitle,
  danger,
  subTitle,
  handleConfirmOk,
  isShowModal,
  setIsShowModal,
  btnSubmitTitle
}) {
  const handleOk = () => {
    setIsShowModal(false);
    handleConfirmOk();
  };

  const handleCancel = () => {
    setIsShowModal(false);
  };

  return (
    <ModalAntd
      className="custom-modal"
      footer={null}
      visible={isShowModal}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      <h1>{title}</h1>
      <h1>{secondTitle}</h1>
      <h2 style={{ color: btnSubmitTitle && "#222222" }}>{subTitle}</h2>
      {btnSubmitTitle ? (
        <Row justify="center">
          <Button
            onClick={handleOk}
            className="btn-action"
            danger={danger}
            type={`${!danger && "primary"}`}
            size="large"
          >
            {btnSubmitTitle}
          </Button>
        </Row>
      ) : (
        <Row justify="space-between">
          <Button onClick={handleCancel} className="btn-action" type="ghost" size="large">
            いいえ
          </Button>
          <Button
            onClick={handleOk}
            className="btn-action"
            danger={danger}
            type={`${!danger && "primary"}`}
            size="large"
          >
            はい
          </Button>
        </Row>
      )}
    </ModalAntd>
  );
}

export default Modal;
