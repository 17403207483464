import { configureStore } from "@reduxjs/toolkit";

import alert from "./slices/alert";
import auth from "./slices/auth";
import flowAccount from "./slices/flowAccount";
import listFilter from "./slices/listData";
import listFilterBase from "./slices/listDataBase";
import listFilterArrival from "./slices/listDataArrival";
import listFilterOrder from "./slices/listDataOrder";
import pulldown from "./slices/pulldown";
import searchCard from "./slices/searchCard";

export default configureStore({
  reducer: {
    auth,
    pulldown,
    alert,
    flowAccount,
    listFilter,
    listFilterBase,
    listFilterArrival,
    listFilterOrder,
    searchCard,
  }
});
