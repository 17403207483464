import { Form, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { PATH_NAME, ROLE } from "common/constant";
import { DATA_FLAG_ALL, DIV_AUTH_GROUP_CODE } from "common/constant/account";
import useAuth from "common/hooks/useAuth";
import { genPostBodyAcc } from "common/utils/accountHelper";
import { isEmptyObj, trimObj } from "common/utils/objectHelper";

import { userApi } from "services/user";

import {
  clearDataAction,
  flowAccountStore,
  setFormDataAction,
  setIsEmptyBillingAction,
  setIsEmptyClientAction,
  setListBillingAddressAction,
  setListOfficeClientAction
} from "store/slices/flowAccount";

import {
  CreateAccountSuccess,
  FooterUpdate,
  FormCustomerAccount,
  HeadOfficePartners,
  InforAccount,
  RightUpdate,
  ShippingPartners
} from "../components";

import Divider from "components/common/Divider";

const UpdateCustomerAcc = () => {
  const [isRegisterSuccess, setIsRegisterSuccess] = useState(false);
  const [currentPageFlag, setCurrentPageFlag] = useState("form");
  const [fixedItems, setFixedItems] = useState();

  const { role } = useAuth();
  const { formData, listBillingAddress, listClientCd } = useSelector(flowAccountStore);
  const dispatch = useDispatch();
  const { userId } = useParams();
  const navigate = useNavigate();

  const [form] = Form.useForm();

  const toggleFormDone = async () => {
    const valueObj = isEmptyObj(form.getFieldsValue()) ? formData : form.getFieldsValue();
    const haveClientPage = valueObj["main_office_div"] === "1";
    const haveTransPage = valueObj["transport_div"] === "1";
    switch (currentPageFlag) {
      case "form": {
        await form
          .validateFields()
          .then(() => {
            form.submit();
            if (haveClientPage) setCurrentPageFlag("client");
            else if (haveTransPage) setCurrentPageFlag("trans");
            else setCurrentPageFlag("infor");
          })
          .catch((e) => {
            console.log("error", e);
            // setValidateFail(true);
          });
        break;
      }
      case "client": {
        if (listClientCd.length > 0) {
          if (haveTransPage) setCurrentPageFlag("trans");
          else setCurrentPageFlag("infor");
        } else dispatch(setIsEmptyClientAction(true));
        break;
      }
      case "trans": {
        if (listBillingAddress.length > 0) setCurrentPageFlag("infor");
        else dispatch(setIsEmptyBillingAction(true));
        break;
      }
      case "infor": {
        const postBody = genPostBodyAcc(valueObj, listClientCd, listBillingAddress);
        postBody.user.id = userId;
        postBody.user_mail_manage.user_id = userId;
        const updateInforUser = async () => await userApi.updateUser(postBody, userId);
        const updateBillingUser = async () =>
          await userApi.updateBillingUser(postBody.billing_addr_ele, userId);
        Promise.all([updateInforUser(), updateBillingUser()])
          .then((res) => {
            if (res) {
              setIsRegisterSuccess(true);
            }
          })
          .catch((e) => console.log(e));
        break;
      }
      default:
        break;
    }
  };

  const handleSaveDraft = async () => {
    const valueObj = isEmptyObj(form.getFieldsValue()) ? formData : form.getFieldsValue();
    const emptyArrr = [{ id: "00000000000", name: "null" }];
    const listClientDraft = listClientCd.length > 0 ? listClientCd : emptyArrr;
    const listBillingDraft = listBillingAddress.length > 0 ? listBillingAddress : emptyArrr;
    let postBody = genPostBodyAcc(valueObj, listClientDraft, listBillingDraft, true);
    postBody.user.id = userId;
    postBody.user_mail_manage.user_id = userId;
    const updateInforUser = async () => {
      if (role === ROLE.SUB_ADMIN) {
        return true;
      }
      return await userApi.updateUser(postBody, userId);
    };
    const updateBillingUser = async () =>
      await userApi.updateBillingUser(postBody.billing_addr_ele, userId);
    if (currentPageFlag === "form") {
      await form
        .validateFields()
        .then(async () => {
          Promise.all([updateInforUser(), updateBillingUser()])
            .then((res) => {
              if (res) {
                navigate(PATH_NAME.ACCOUNT_LIST);
              }
            })
            .catch((e) => console.log(e));
        })
        .catch((e) => {
          console.log("error", e);
          // setValidateFail(true);
        });
    } else {
      Promise.all([updateInforUser(), updateBillingUser()])
        .then((res) => {
          if (res) {
            navigate(PATH_NAME.ACCOUNT_LIST);
          }
        })
        .catch((e) => console.log(e));
    }
  };

  const handleSubmitForm = (params) => {
    const paramsTrimed = trimObj({ ...formData, ...params, use_user_div: ROLE.CUSTOMER });
    dispatch(setFormDataAction(paramsTrimed));
  };

  const generateUi = (component) => {
    return (
      <Row justify="space-between" gutter={[100]} className="form-customer-account">
        {component}
        <RightUpdate
          isFormConfirm={currentPageFlag === "infor"}
          toggleFormDone={toggleFormDone}
          setCurrentPageFlag={setCurrentPageFlag}
          currentPageFlag={currentPageFlag}
          handleSaveDraft={handleSaveDraft}
        />
      </Row>
    );
  };

  const listPage = {
    form: generateUi(
      <FormCustomerAccount
        fixedItems={fixedItems}
        editMode
        form={form}
        handleSubmitForm={handleSubmitForm}
      />
    ),
    client: generateUi(<HeadOfficePartners editMode />),
    trans: generateUi(<ShippingPartners editMode />),
    infor: generateUi(<InforAccount isDetail />)
  };

  const fetchInforUser = async () => {
    let formData = {};
    let saveFlag;
    await userApi
      .getUserById(userId)
      .then((res) => {
        const { id, perm, save_flg, ...rest } = res;
        saveFlag = save_flg !== "1";
        formData = {
          ...formData,
          user_id: id,
          auth_group_invoice: perm.includes(DIV_AUTH_GROUP_CODE.INVOICE) ? "1" : "0",
          auth_group_order: perm.includes(DIV_AUTH_GROUP_CODE.ORDER) ? "1" : "0",
          ...rest
        };
      })
      .catch((e) => console.log(e));
    await userApi
      .getBillingByUserId(userId)
      .then((res) => {
        const listBillingClient = res?.filter((item) => item?.billing_addr_cd_id_div === "01");
        const listBillingTrans = res?.filter((item) => item?.billing_addr_cd_id_div === "02");
        let office_to_client_cds = listBillingClient?.map((item) => item?.office_cd);
        let clientCds = listBillingClient?.map((item) => ({
          id: item?.billing_addr_cd,
          name: item?.billing_addr_nm,
          isFixed: saveFlag
        }));
        // get unique
        office_to_client_cds = [...new Set(office_to_client_cds)];
        // get unique
        clientCds = [...new Map(clientCds.map((item) => [item["id"], item])).values()];
        const billingAddrCds = listBillingTrans?.map((item) => ({
          id: item?.billing_addr_cd,
          name: item?.billing_addr_nm,
          isFixed: saveFlag
        }));
        const office_order_cd = listBillingTrans[0]?.office_cd;
        const objSaleOffice = {};
        if (office_to_client_cds[0] !== DATA_FLAG_ALL.OFFICE_CD[0]) {
          office_to_client_cds.forEach((item, index) => {
            objSaleOffice[`sale_office_${index + 1}`] = item;
          });
        }
        formData = {
          ...formData,
          office_order_cd,
          office_logic_flag:
            office_to_client_cds[0] !== DATA_FLAG_ALL.OFFICE_CD[0] ? "some" : "all",
          main_office_div: office_to_client_cds?.length > 0 ? "1" : "0",
          transport_div: office_order_cd ? "1" : "0",
          ...objSaleOffice
        };

        if (clientCds[0]?.id !== DATA_FLAG_ALL.BILLING_ADDR_CD[0]) {
          dispatch(setListOfficeClientAction(clientCds));
        }
        if (billingAddrCds[0]?.id !== DATA_FLAG_ALL.BILLING_ADDR_CD[0]) {
          dispatch(setListBillingAddressAction(billingAddrCds));
        }

        const listFixed = [];
        if (formData?.main_office_div === "1") {
          listFixed.push("main_office_div");
        }
        if (formData?.auth_group_invoice === "1") {
          listFixed.push("auth_group_invoice");
        }
        if (formData?.transport_div === "1") {
          listFixed.push("transport_div", "auth_group_order");
        }
        if (formData.save_flg !== "1") {
          setFixedItems(listFixed.concat(office_to_client_cds));
        }
      })
      .catch((e) => console.log(e));
    dispatch(setFormDataAction(formData));
    form.setFieldsValue(formData);
  };
  useEffect(() => {
    fetchInforUser();
    dispatch(clearDataAction());
    form.resetFields();
  }, []);

  return (
    <div className="create-customer-account-page">
      {isRegisterSuccess ? (
        <div className="content bg-white">
          <CreateAccountSuccess />
        </div>
      ) : (
        <>
          <div className="main-content bg-white">
            <h1 className="title">
              {currentPageFlag === "infor" ? "登録内容確認" : "アカウント登録"}
            </h1>
            <Divider height="2px" />
            {listPage[currentPageFlag]}
          </div>
          <FooterUpdate
            currentPageFlag={currentPageFlag}
            toggleFormDone={toggleFormDone}
            isFormConfirm={currentPageFlag === "infor"}
            setCurrentPageFlag={setCurrentPageFlag}
            handleSaveDraft={handleSaveDraft}
          />
        </>
      )}
    </div>
  );
};

export default UpdateCustomerAcc;
