import React from "react";
import PropTypes from "prop-types";
import { Space } from "antd";
import "./form.scss";

TitleDetail.propTypes = {
  title: PropTypes.string
};

function TitleDetail({title}) {
  return <Space align="center" className="title-detail">{title}</Space>;
}

export default TitleDetail;
