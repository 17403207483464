import { Col } from "antd";
import PropTypes from "prop-types";
import React from "react";

import Select from "components/common/Select";

SelectFilter.propTypes = {
  title: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  listOptions: PropTypes.array,
  width: PropTypes.number,
  allowClear: PropTypes.bool,
  defaultValue: PropTypes.any,
  disabled: PropTypes.bool
};

function SelectFilter({
  title,
  placeholder,
  name,
  listOptions,
  width,
  allowClear,
  defaultValue,
  disabled
}) {
  return (
    <Col className="filter-item">
      <div className="mb-5">{title}</div>
      <Select
        name={name}
        placeholder={placeholder}
        style={{ width: width ? `${width}px` : "100px" }}
        listOptions={listOptions}
        allowClear={allowClear}
        defaultValue={defaultValue}
        disabled={disabled}
      />
    </Col>
  );
}

export default SelectFilter;
