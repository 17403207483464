import { ConfigProvider, Empty, Space, Table } from "antd";
import { IconDownload } from "assets";
import ProtoTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";

import { formatDate, formatDateString } from "common/utils/dateHelper";

import Tag from "components/common/Tag";

const TableListService = ({
  dataSource,
  totalRow,
  onPageChange,
  page,
  onSortOrders,
  handleDownloadSignImage,
  handleDownloadReceiptImage
}) => {
  const columns = [
    {
      title: "運行ID",
      width: 120,
      dataIndex: "service_no",
      sorter: {
        multiple: 1
      },
      render: (text) => (text ? <Link to={`/service_detail/${text}`}>{text}</Link> : null)
    },
    {
      title: "ステータス",
      width: 110,
      dataIndex: "service_status",
      sorter: {
        multiple: 1
      },
      render: (text) => {
        let type;
        switch (text) {
          case "01":
            type = "service-pending";
            break;
          case "02":
            type = "service-running";
            break;
          case "03":
            type = "service-complete";
            break;
        }
        return text ? <Tag type={type} size="sm" /> : null;
      }
    },
    {
      title: "運行日",
      width: 150,
      dataIndex: "service_ymd",
      sorter: {
        multiple: 1
      },
      render: (text) => (text ? formatDate(formatDateString(text)) : null)
    },
    {
      title: "ドライバー",
      width: 160,
      dataIndex: "driver_nm",
      sorter: {
        multiple: 1
      }
    },
    {
      title: "受領書",
      width: 100,
      dataIndex: "has_receipt_img",
      sorter: {
        multiple: 1
      },
      render: (text, record) =>
        text ? (
          <Space>
            受領書
            <IconDownload
              onClick={() => handleDownloadReceiptImage(record?.service_no)}
              className="cursor-pointer"
            />
          </Space>
        ) : null
    },
    {
      title: "電子サイン",
      width: 120,
      dataIndex: "has_sign_img",
      sorter: {
        multiple: 1
      },
      render: (text, record) =>
        text ? (
          <Space>
            サイン
            <IconDownload
              onClick={() => handleDownloadSignImage(record?.service_no)}
              className="cursor-pointer"
            />
          </Space>
        ) : null
    },
    {
      title: "乗務員メモ",
      width: 140,
      dataIndex: "has_memo",
      sorter: {
        multiple: 1
      },
      render: (text) => (text ? "メモあり" : null)
    },
    {
      title: "カルテ更新",
      width: 140,
      dataIndex: "has_upd_base",
      sorter: {
        multiple: 1
      },
      render: (text) => (text ? "更新あり" : null)
    },
    {
      title: "営業所",
      width: 160,
      dataIndex: "office_nm",
      sorter: {
        multiple: 1
      }
    }
  ];
  return (
    <div className="account-list">
      <ConfigProvider
        renderEmpty={() => (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="適当な検索結果がありません" />
        )}
      >
        <Table
          title={() => (
            <div className="title">
              {dataSource.length > 0 && `全${totalRow}件中${dataSource.length}件を表示`}
            </div>
          )}
          columns={columns}
          rowKey={(record) => record.service_no}
          dataSource={dataSource}
          onChange={onSortOrders}
          pagination={{
            current: page,
            onChange: onPageChange,
            position: ["topRight"],
            defaultPageSize: 30,
            total: totalRow,
            showSizeChanger: false
          }}
          scroll={{ y: 490 }}
        />
      </ConfigProvider>
    </div>
  );
};
TableListService.propTypes = {
  dataSource: ProtoTypes.array,
  totalRow: ProtoTypes.number,
  onPageChange: ProtoTypes.func,
  page: ProtoTypes.number,
  onSortAccounts: ProtoTypes.func,
  onSortOrders: ProtoTypes.func,
  handleDownloadSignImage: ProtoTypes.func,
  handleDownloadReceiptImage: ProtoTypes.func
};
export default TableListService;
