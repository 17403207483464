import { Form, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ROLE } from "common/constant";
import useAuth from "common/hooks/useAuth";
import cacheInforDelivery from "common/utils/cacheInforDelivery";
import { genOrderFormData } from "common/utils/orderHelper";

import { orderApi } from "services/order";

import { getListBillingAddrAction, pulldownStore } from "store/slices/pulldown";
import { clearDataPulldownAction } from "store/slices/pulldown";

import {
  CreateOrderSuccess,
  FooterCreateOrder,
  FormOrderDetail,
  HeaderCreateOrder,
  InforOrderDetail,
  ModalValidateFail,
  RightCreateOrder
} from "../components";
import "./CreateOrder.scss";

function CreateOrder() {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [isConfirm, setIsConfirm] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [values, setValues] = useState();
  const [initialValues, setInitialValues] = useState({});
  const [stateFileList, setStateFileList] = useState();
  const [inforOffice, setInforOffice] = useState();
  const [validateFail, setValidateFail] = useState(false);
  const [loading, setLoading] = useState(false);
  const [postCode, setPostCode] = useState({
    delivery: "",
    pickup: ""
  });
  const { listOptOffices, listPickupAddr } = useSelector(pulldownStore);
  const [typeOrder, setTypeOrder] = useState("tlot"); // tlot || chapter

  const { role } = useAuth();

  const handleSubmitForm = (params) => {
    const { delivery_addr1, pickup_addr1 } = params;
    const { office_cd, billing_addr_cd } = initialValues;
    setValues({
      office_cd,
      billing_addr_cd,
      ...params,
      delivery_addr1: `${postCode.delivery} ${delivery_addr1}`,
      pickup_addr1: `${postCode.pickup} ${pickup_addr1}`
    });
    cacheInforDelivery(params);
  };

  const toggleConfirmMode = async () => {
    await form
      .validateFields()
      .then(() => {
        if (!isConfirm) {
          form.submit();
          if (!form.getFieldValue("pickup_addr_cd")) {
            form.setFieldsValue({ pickup_addr_cd: "000000000" });
          }
        }
        setIsConfirm(!isConfirm);
      })
      .catch((e) => {
        console.log("error", e);
        setValidateFail(true);
      });
  };

  const handleSubmitConfirm = async () => {
    setLoading(true);
    const formData = genOrderFormData(values, listPickupAddr, typeOrder);
    await orderApi
      .create(formData)
      .then(() => {
        setIsSuccess(true);
      })
      .catch((e) => console.log(e));
    setLoading(false);
  };

  useEffect(async () => {
    if (listOptOffices && role) {
      let office_cd;
      if (role !== ROLE.ADMIN) {
        office_cd = listOptOffices[0]?.value;
        setInforOffice(listOptOffices[0]);
        if (role === ROLE.CUSTOMER) {
          dispatch(
            getListBillingAddrAction(office_cd, async (e) => {
              setInitialValues({
                office_cd,
                billing_addr_cd: e
              });
            })
          );
        } else {
          dispatch(getListBillingAddrAction(office_cd));
          form.setFieldsValue({
            office_cd
          });
          setInitialValues({
            office_cd
          });
        }
      }
    }
  }, [listOptOffices, role]);

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue({
      delivery_time_div: "01",
      delivery_vehicle_type: "01",
      stack_feasible_flg: "1"
    });
  }, [initialValues]);

  useEffect(() => {
    form.setFieldsValue({
      delivery_time_div: "01",
      delivery_vehicle_type: "01",
      stack_feasible_flg: "1",
      item_cd: null,
      quantity: null,
      quantity_div: null,
      volume: null,
      volume_div: null,
      weight: null,
      packing_sepa: null,
      packing_pl: null,
      packing_lo: null,
      packing_variant: null
    });
    if (typeOrder === "chapter") {
      const listError = form?.getFieldsError()?.filter((field) => field.errors.length > 0);
      if (listError.length > 0) {
        form.validateFields(["quantity_div"]);
      }
    }
  }, [typeOrder]);

  useEffect(() => {
    dispatch(clearDataPulldownAction("listBillingAddr"));
    dispatch(clearDataPulldownAction("listPickupAddr"));
    dispatch(clearDataPulldownAction("listAddressJis"));
    dispatch(clearDataPulldownAction("listOptItems"));
  }, []);

  return isSuccess ? (
    <div className="content bg-white">
      <CreateOrderSuccess />
    </div>
  ) : (
    <div id="create-order-page">
      <ModalValidateFail setValidateFail={setValidateFail} validateFail={validateFail} />
      <>
        <div className="content bg-white">
          <HeaderCreateOrder
            isConfirm={isConfirm}
            typeOrder={typeOrder}
            setTypeOrder={setTypeOrder}
          />
          <Row justify="space-between">
            {isConfirm ? (
              <InforOrderDetail values={values} typeOrder={typeOrder} />
            ) : (
              <FormOrderDetail
                values={values}
                setValues={setValues}
                initialValues={initialValues}
                form={form}
                handleSubmit={handleSubmitForm}
                stateFileList={stateFileList}
                setStateFileList={setStateFileList}
                setInforOffice={setInforOffice}
                setPostCode={setPostCode}
                typeOrder={typeOrder}
              />
            )}
            <RightCreateOrder
              isConfirm={isConfirm}
              toggleConfirmMode={toggleConfirmMode}
              handleSubmit={handleSubmitConfirm}
              inforOffice={inforOffice}
              loading={loading}
            />
          </Row>
        </div>
        <FooterCreateOrder
          isConfirm={isConfirm}
          toggleConfirmMode={toggleConfirmMode}
          handleSubmit={handleSubmitConfirm}
        />
      </>
    </div>
  );
}

export default CreateOrder;
