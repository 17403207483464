import { Button } from "antd";
import GoogleMapReact, { fitBounds } from "google-map-react";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";

import { GOOGLE_API_KEY } from "common/config";
import { drawPolyline, getBounds } from "common/utils/mapHelper";

import NextSpotMarker from "pages/service/components/NextSpotMarker";
import TruckMarker from "pages/service/components/TruckMarker";

const OperationMap = ({ activeService, listAct, unActiveService, onChangeActive, refetch }) => {
  const refMapContainer = useRef();
  const [defaultZoom, setDefaultZoom] = useState(10);
  const [defaultCenter, setDefaultCenter] = useState({
    lat: 34.961872701244076,
    lng: 137.17459801587069
  });
  const lastDeliLat = activeService.last_delivery_act?.latitude;
  const lastDeliLng = activeService.last_delivery_act?.longitude;
  const nextSpotLat = activeService.next_delivery_spot?.latitude;
  const nextSpotLng = activeService.next_delivery_spot?.longitude;

  const drawMap = (map, maps) => {
    drawPolyline(
      map,
      maps,
      {
        lat: lastDeliLat,
        lng: lastDeliLng
      },
      {
        lat: nextSpotLat,
        lng: nextSpotLng
      }
    );

    if (listAct.length > 1) {
      listAct.map((item, index) => {
        const { latitude: currentLatitude, longitude: currentLongitude } = item;
        const currentPosition = new maps.LatLng(currentLatitude, currentLongitude);

        if (index < listAct.length - 1) {
          const { latitude: nextLatitude, longitude: nextLongitude } = listAct[index + 1];
          const nextPosition = new maps.LatLng(nextLatitude, nextLongitude);

          new maps.Marker({
            position: currentPosition,
            map,
            icon: {
              path: maps.SymbolPath.FORWARD_CLOSED_ARROW,
              fillColor: "red",
              fillOpacity: 1,
              strokeWeight: 1,
              scale: 4,
              rotation: maps.geometry.spherical.computeHeading(currentPosition, nextPosition)
            }
          });
        }
      });
    }
  };

  const onRefreshData = () => {
    refetch();
  };

  useEffect(() => {
    const listLatLng = [...listAct];
    if (lastDeliLat && lastDeliLng) {
      listLatLng.push(activeService.last_delivery_act);
    }
    if (nextSpotLat && nextSpotLng) {
      listLatLng.push(activeService.next_delivery_spot);
    }
    const listLatLngFormat = listLatLng.map((item) => ({
      latitude: item.latitude,
      longitude: item.longitude
    }));

    if (listLatLngFormat.length > 1) {
      const bounds = getBounds(listLatLngFormat);
      if (bounds.ne.lat === bounds.sw.lat && bounds.ne.lng === bounds.sw.lng) {
        setDefaultCenter({
          lat: Number(bounds.ne.lat) || 34.961872701244076,
          lng: Number(bounds.ne.lng) || 137.17459801587069
        });
        setDefaultZoom(10);
      } else {
        const { width, height } = refMapContainer.current?.getClientRects()?.[0] || {};
        const { center, zoom } = fitBounds(bounds, {
          width: width,
          height: height
        });
        setDefaultZoom(zoom);
        setDefaultCenter(center);
      }
    } else {
      setDefaultCenter({
        lat: listLatLngFormat[0]?.latitude || 34.961872701244076,
        lng: listLatLngFormat[0]?.longitude || 137.17459801587069
      });
      setDefaultZoom(10);
    }
  }, [listAct, activeService]);

  return (
    <div className="w-full h-full-screen dis-flex" ref={refMapContainer}>
      <Button
        onClick={onRefreshData}
        className="w-112 h-32 pos-absolute update-button"
        type="primary"
      >
        更新
      </Button>
      <GoogleMapReact
        options={{
          streetViewControl: true
        }}
        bootstrapURLKeys={{ key: GOOGLE_API_KEY, libraries: ["geometry"] }}
        zoom={defaultZoom}
        center={defaultCenter}
        key={`${activeService.service_no}
        -${listAct}-${lastDeliLat}-${lastDeliLng}-${nextSpotLat}-${nextSpotLng}`}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) => drawMap(map, maps)}
      >
        {lastDeliLat && lastDeliLng && (
          <TruckMarker
            lat={lastDeliLat}
            lng={lastDeliLng}
            status={"active"}
            data={activeService}
            onChangeActive={onChangeActive}
          />
        )}
        {nextSpotLat && nextSpotLng && <NextSpotMarker lat={nextSpotLat} lng={nextSpotLng} />}
        {unActiveService.length > 0 &&
          unActiveService.map(
            (item) =>
              item?.last_delivery_act?.latitude &&
              item?.last_delivery_act?.longitude && (
                <TruckMarker
                  key={item.service_no}
                  lat={item?.last_delivery_act?.latitude}
                  lng={item?.last_delivery_act?.longitude}
                  status="unactive"
                  onChangeActive={onChangeActive}
                  data={item}
                />
              )
          )}
      </GoogleMapReact>
    </div>
  );
};

OperationMap.propTypes = {
  activeService: PropTypes.object,
  listAct: PropTypes.array,
  unActiveService: PropTypes.array,
  onChangeActive: PropTypes.func,
  refetch: PropTypes.func
};

export default OperationMap;
