import { createSlice } from "@reduxjs/toolkit";

export const slice = createSlice({
  name: "listFilterOrder",
  initialState: {
    filterOrderObject: {
      pi_from: "",
      pi_to: "",
      de_from: "",
      de_to: ""
    }
  },
  reducers: {
    setFilterOrder: (state, action) => {
      state.filterOrderObject = action.payload;
    }
  }
});

// Action creators are generated for each case reducer function
export const { setFilterOrder } = slice.actions;

// Async action

export const setFilterOrderAction = (object) =>
  function (dispatch) {
    dispatch(setFilterOrder(object));
  };

// Get value from state

export const listFilterOrderStore = (state) => state.listFilterOrder;

export default slice.reducer;
