import { Col, Form } from "antd";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { DIV_BOOL, DIV_PACKING, ROLE } from "common/constant";
import { rulesOrder, validateMessages } from "common/constant/rules";
import useAuth from "common/hooks/useAuth";
import cache from "common/utils/cache";
import { splitAddress } from "common/utils/stringHelper";

import {
  clearDataPulldownAction,
  getListAddressJisAction,
  getListBillingAddrAction,
  getListOptItemsAction,
  getListPickupAddrAction,
  pulldownStore
} from "store/slices/pulldown";
import { getListAddressJisPickupAction } from "store/slices/pulldown";

import {
  FormArea,
  FormCheckboxGroup,
  FormDatePicker,
  FormDetail,
  FormInput,
  FormInputSearch,
  FormMultiInput,
  FormRadioGroup,
  FormSelect,
  TitleDetail,
  UploadDragger
} from "components/form";

FormOrderDetail.propTypes = {
  form: PropTypes.any,
  isInitValues: PropTypes.bool,
  handleSubmit: PropTypes.func,
  initialValues: PropTypes.object,
  setStateFileList: PropTypes.func,
  stateFileList: PropTypes.array,
  setInforOffice: PropTypes.func,
  defaultFileList: PropTypes.array,
  setPostCode: PropTypes.func,
  typeOrder: PropTypes.string
};

function FormOrderDetail({
  form,
  isInitValues,
  handleSubmit,
  initialValues,
  setStateFileList,
  stateFileList,
  setInforOffice,
  defaultFileList,
  setPostCode,
  typeOrder = "tlot"
}) {
  const dispatch = useDispatch();

  const {
    listBillingAddr,
    listPickupAddr,
    listAddressJisPickup,
    listAddressJis,
    listOptItems,
    listOptOffices,
    listOptDeliTime,
    listOptDeliVehicleType,
    listOptUnitQuantity,
    listOptUnitVolume,
    listOptVehicleChapter
  } = useSelector(pulldownStore);

  const { role } = useAuth();

  const [typePickupCd, setTypePickupCd] = useState("select"); // select || input
  const [togglePickupAddr1, setTogglePickupAddr1] = useState(false);
  const [toggleDeliAddr1, setToggleDeliAddr1] = useState(false);
  const pickupCdState = Form.useWatch("pickup_addr_cd", form);

  const fetchUserList = async (searchValue) => {
    const listInforDelivery = cache.getJson("listInforDelivery") || [];
    return listInforDelivery
      ?.filter((item) => item.delivery_addr_nm.includes(searchValue))
      ?.map((item) => ({
        label: item.delivery_addr_nm,
        value: item.delivery_addr_nm
      }));
  };

  const handleAfterSelectInputSearch = (value) => {
    const listInforDelivery = cache.getJson("listInforDelivery") || [];
    const inforSelectedDelivery = listInforDelivery?.find(
      (item) => item.delivery_addr_nm === value
    );
    if (inforSelectedDelivery) {
      const {
        delivery_addr1,
        delivery_addr2,
        delivery_addr3,
        delivery_addr_postcd,
        delivery_addr_tel
      } = inforSelectedDelivery;

      form.setFieldsValue({
        delivery_addr1,
        delivery_addr2,
        delivery_addr3,
        delivery_addr_postcd,
        delivery_addr_tel
      });

      handleSearchPostal("delivery");
    }
  };
  const handleSearchPostal = async (type) => {
    if (type === "delivery") {
      dispatch(getListAddressJisAction(form?.getFieldValue("delivery_addr_postcd")));
    } else {
      dispatch(getListAddressJisPickupAction(form?.getFieldValue("pickup_addr_postcd")));
    }
  };

  const fetchListOtpItems = async (office_cd, pickup_addr_cd) => {
    dispatch(getListOptItemsAction({ office_cd, pickup_addr_cd }));
  };

  const fetchPickUpAddress = async (office_cd, billing_addr_cd) => {
    dispatch(getListPickupAddrAction({ office_cd, billing_addr_cd }));
  };

  const getInforPickupAddress = (pickup_addr_cd) =>
    listPickupAddr?.find((item) => item.pickup_addr_cd === pickup_addr_cd);

  const onValuesChange = async (changedValues, values) => {
    const office_cd = values?.office_cd || initialValues?.office_cd;
    // fetch list option billing_addr_cd when  office_cd change
    if ("office_cd" in changedValues) {
      const isValidateErr = form.getFieldError(["billing_addr_cd"]).length > 0;
      dispatch(getListBillingAddrAction(office_cd));
      setInforOffice(listOptOffices?.find((e) => e.value === office_cd));
      form.setFieldsValue({
        billing_addr_cd: null,
        pickup_addr_cd: null,
        pickup_addr_nm: null,
        pickup_addr1: null,
        pickup_addr2: null,
        pickup_addr3: null,
        pickup_addr_postcd: null,
        pickup_addr_tel: null,
        item_cd: null
      });
      if (!changedValues?.office_cd) {
        dispatch(clearDataPulldownAction("listBillingAddr"));
        dispatch(clearDataPulldownAction("listPickupAddr"));
        dispatch(clearDataPulldownAction("listOptItems"));
      }
      if (isValidateErr) form.validateFields();
    }

    if ("pickup_addr_cd" in changedValues) {
      // fetch list option item when  office_cd, pickupAddr change
      if (office_cd && values?.pickup_addr_cd) {
        fetchListOtpItems(office_cd, values?.pickup_addr_cd);
      }
      form.setFieldsValue({ item_cd: null });
      if (!changedValues?.pickup_addr_cd) {
        dispatch(clearDataPulldownAction("listOptItems"));
      }
      if (!values?.pickup_addr_cd) {
        form.setFieldsValue({
          pickup_addr1: null,
          pickup_addr2: null,
          pickup_addr3: null,
          pickup_addr_postcd: null,
          pickup_addr_tel: null
        });
      }
      // revalidate pickup_post_cd
      form.validateFields(["pickup_addr_postcd"]);
    }

    // fetch list option pickupaddress when company_cd, office_cd, billing_addr_cd change
    if ("billing_addr_cd" in changedValues) {
      if (office_cd && values?.billing_addr_cd) {
        fetchPickUpAddress(office_cd, values?.billing_addr_cd);
      }
      form.setFieldsValue({
        pickup_addr_cd: null,
        pickup_addr_nm: null,
        pickup_addr1: null,
        pickup_addr2: null,
        pickup_addr3: null,
        pickup_addr_postcd: null,
        pickup_addr_tel: null,
        item_cd: null
      });
      setTypePickupCd("select");
      if (!changedValues?.billing_addr_cd) {
        dispatch(clearDataPulldownAction("listPickupAddr"));
        dispatch(clearDataPulldownAction("listOptItems"));
      }
    }

    // get all infor pickupAddr when pickup_addr_cd change
    if ("pickup_addr_cd" in changedValues) {
      const { pickup_addr1, pickup_addr2, pickup_addr3, pickup_addr_postcd, pickup_addr_tel } =
        getInforPickupAddress(values?.pickup_addr_cd) || {};
      form.setFieldsValue({
        pickup_addr1,
        pickup_addr2,
        pickup_addr3,
        pickup_addr_postcd,
        pickup_addr_tel
      });
    }

    if ("delivery_addr_postcd" in changedValues) {
      if (changedValues.delivery_addr_postcd.length === 7) {
        handleSearchPostal("delivery");
        form.setFieldsValue({ delivery_addr1: null });
        setToggleDeliAddr1(false);
      } else {
        form.setFieldsValue({ delivery_addr1: null });
        setToggleDeliAddr1(false);
        dispatch(clearDataPulldownAction("listAddressJis"));
      }
    }

    if ("pickup_addr_postcd" in changedValues) {
      if (changedValues.pickup_addr_postcd.length === 7) {
        handleSearchPostal("pickup");
        form.setFieldsValue({ pickup_addr1: null });
        setTogglePickupAddr1(false);
      } else {
        form.setFieldsValue({ pickup_addr1: null });
        setTogglePickupAddr1(false);
        dispatch(clearDataPulldownAction("listAddressJisPickup"));
      }
    }

    // re-validate when 1 one 3 field changed
    if ("volume" in changedValues || "weight" in changedValues || "quantity" in changedValues) {
      form.validateFields(["infor-item", "quantity", "volume", "weight"]);
    }

    if ("pickup_addr1" in changedValues) {
      if (!togglePickupAddr1) {
        setTogglePickupAddr1(true);
        const [addrCode, addrName] = splitAddress(form.getFieldValue("pickup_addr1"));
        form.setFieldsValue({ pickup_addr1: addrName });
        setPostCode && setPostCode((pre) => ({ ...pre, pickup: addrCode }));
      }

      if (!form.getFieldValue("pickup_addr1")) {
        form.setFieldsValue({ pickup_addr1: null });
        setTogglePickupAddr1(false);
      }
    }

    if ("delivery_addr1" in changedValues) {
      if (!toggleDeliAddr1) {
        setToggleDeliAddr1(true);
        const [addrCode, addrName] = splitAddress(form.getFieldValue("delivery_addr1"));
        form.setFieldsValue({ delivery_addr1: addrName });
        setPostCode && setPostCode((pre) => ({ ...pre, delivery: addrCode }));
      }

      if (!form.getFieldValue("delivery_addr1")) {
        form.setFieldsValue({ delivery_addr1: null });
        setToggleDeliAddr1(false);
      }
    }

    // new spec
    const changedName = Object.getOwnPropertyNames(changedValues)?.at(0);
    const arrToCheck = [
      "pickup_addr_tel",
      "pickup_addr_postcd",
      "pickup_addr1",
      "pickup_addr2",
      "pickup_addr3"
    ];
    if (arrToCheck.includes(changedName)) {
      const valuesToCheck = form.getFieldsValue(arrToCheck);
      let type = "select";
      Object.values(valuesToCheck).every((value) => {
        if (value) type = "input";
        return true;
      });
      if (
        (typePickupCd === "select" && type === "input") ||
        (typePickupCd === "input" && type === "select")
      ) {
        form.setFieldsValue({
          pickup_addr_nm: null,
          pickup_addr_cd: null
        });
      }
      setTypePickupCd(type);
    }
  };

  useEffect(() => {
    if (initialValues) {
      const { office_cd, billing_addr_cd } = initialValues;
      if (office_cd && billing_addr_cd) fetchPickUpAddress(office_cd, billing_addr_cd);
      if (form.getFieldValue("delivery_addr_postcd")) {
        handleSearchPostal("delivery");
      }
    }
    if (form.getFieldValue("delivery_addr1")) {
      setToggleDeliAddr1(true);
    }
    if (form.getFieldValue("pickup_addr1")) {
      setTogglePickupAddr1(true);
    }
    if (form.getFieldValue("pickup_addr_cd") === "000000000") {
      setTypePickupCd("input");
    }
  }, [initialValues]);

  useEffect(() => {
    if (listAddressJis.length > 0) {
      const [addrCode] = splitAddress(listAddressJis[0]?.value);
      setPostCode && setPostCode((pre) => ({ ...pre, delivery: addrCode }));
    }
  }, [listAddressJis]);

  useEffect(() => {
    if (listAddressJisPickup.length > 0) {
      const [addrCode] = splitAddress(listAddressJisPickup[0]?.value);
      setPostCode && setPostCode((pre) => ({ ...pre, pickup: addrCode }));
    }
  }, [listAddressJisPickup]);

  useEffect(() => {
    if (form.getFieldValue("pickup_addr_cd") === "000000000") {
      setTypePickupCd("input");
    }
  }, []);

  return (
    <Col span={18}>
      <Form
        onValuesChange={onValuesChange}
        onFinish={handleSubmit}
        form={form}
        initialValues={initialValues}
        className="form-order"
        validateMessages={validateMessages}
      >
        <div id="basic_infor" className="form-order-group">
          <TitleDetail title="基本情報" />
          {role === ROLE.ADMIN ? (
            <FormSelect
              rules={rulesOrder.office_cd}
              label="タカスエ営業所"
              name="office_cd"
              placeholder="プルダウンから選択"
              listOptions={listOptOffices}
            />
          ) : (
            <FormDetail
              type="select"
              listOptions={listOptOffices}
              value={initialValues?.office_cd}
              label="タカスエ営業所"
            />
          )}
          <FormSelect
            label="お客様"
            name="billing_addr_cd"
            rules={rulesOrder.billing_addr_cd}
            placeholder="プルダウンから選択"
            listOptions={listBillingAddr}
          />
          <FormInput
            optional
            label="お客様管理ナンバー"
            name="client_manage_no"
            placeholder="英数字・記号の組み合わせ"
            helperText="お客様で管理いただいている注文番号を入力してください"
            rules={rulesOrder.client_manage_no}
          />
        </div>
        <div id="collection" className="form-order-group">
          <TitleDetail title="荷積（集荷）情報" />
          <div className="note">※ 集荷先が見当たらない場合、郵便番号から直接ご入力下さい。</div>
          {typePickupCd === "select" ? (
            <FormSelect
              label="集荷先名称"
              name="pickup_addr_cd"
              rules={rulesOrder.pickup_addr_cd}
              placeholder="プルダウンから選択"
              listOptions={listPickupAddr}
            />
          ) : (
            <FormInput
              label="集荷先名称"
              name="pickup_addr_nm"
              placeholder="テキスト入力"
              rules={rulesOrder.pickup_addr_nm}
            />
          )}
          <FormInput
            label="電話番号"
            name="pickup_addr_tel"
            placeholder="テキスト入力"
            disabled={Boolean(pickupCdState)}
            rules={typePickupCd === "input" && rulesOrder.delivery_addr_tel}
          />
          <FormInput
            label="郵便番号"
            name="pickup_addr_postcd"
            placeholder="テキスト入力"
            disabled={Boolean(pickupCdState)}
            rules={
              typePickupCd === "input"
                ? rulesOrder.delivery_addr_postcd
                : !pickupCdState && rulesOrder.pickup_addr_cd
            }
          />
          {togglePickupAddr1 ? (
            <FormInput
              allowClear
              label="住所1"
              name="pickup_addr1"
              placeholder="テキスト入力"
              rules={typePickupCd === "input" && rulesOrder.pickup_addr1}
              disabled={Boolean(pickupCdState)}
            />
          ) : (
            <FormSelect
              label="住所1"
              name="pickup_addr1"
              rules={typePickupCd === "input" && rulesOrder.pickup_addr1}
              placeholder="プルダウンから選択"
              listOptions={listAddressJisPickup}
              disabled={Boolean(pickupCdState)}
            />
          )}
          <FormInput
            label="住所2"
            name="pickup_addr2"
            rules={rulesOrder.pickup_addr2}
            placeholder="テキスト入力"
            optional
            disabled={Boolean(pickupCdState)}
          />
          <FormInput
            label="住所3"
            name="pickup_addr3"
            rules={rulesOrder.pickup_addr3}
            placeholder="テキスト入力"
            optional
            disabled={Boolean(pickupCdState)}
          />
          <FormDatePicker name="pickup_ymd" rules={rulesOrder.pickup_ymd} label="集荷日" />
        </div>
        <div id="upload" className="form-order-group">
          <TitleDetail title="荷降（配達）情報" />
          <div className="note">
            ※
            荷降先が複数の場合は、備考欄にその旨を記載いただくか、資料をお持ちの場合は添付をお願い致します。
          </div>
          <FormInputSearch
            form={form}
            label="配達先名称"
            name="delivery_addr_nm"
            placeholder=""
            fetchOptions={fetchUserList}
            handleAfterSelect={handleAfterSelectInputSearch}
            rules={rulesOrder.delivery_addr_nm}
            isInitValues={isInitValues}
          />
          <FormInput
            label="電話番号"
            name="delivery_addr_tel"
            rules={rulesOrder.delivery_addr_tel}
            placeholder="半角数字で入力してください"
          />
          <FormInput
            label="郵便番号"
            name="delivery_addr_postcd"
            placeholder="テキスト入力"
            rules={rulesOrder.delivery_addr_postcd}
          />
          {toggleDeliAddr1 ? (
            <FormInput
              allowClear
              label="住所1"
              name="delivery_addr1"
              rules={rulesOrder.delivery_addr1}
            />
          ) : (
            <FormSelect
              label="住所1"
              name="delivery_addr1"
              rules={rulesOrder.delivery_addr1}
              placeholder="プルダウンから選択"
              listOptions={listAddressJis}
            />
          )}
          <FormInput
            label="住所2"
            name="delivery_addr2"
            rules={rulesOrder.delivery_addr2}
            placeholder="テキスト入力"
            optional
          />
          <FormInput
            label="住所3"
            name="delivery_addr3"
            rules={rulesOrder.delivery_addr3}
            placeholder="テキスト入力"
            optional
          />
          <FormDatePicker name="delivery_ymd" rules={rulesOrder.delivery_ymd} label="配達日" />
          <FormRadioGroup
            label="配達時間指定"
            name="delivery_time_div"
            listOptions={listOptDeliTime}
            rules={rulesOrder.delivery_time_div}
          />
          <FormRadioGroup
            label="入場車種制限"
            name="delivery_vehicle_type"
            listOptions={listOptDeliVehicleType}
            rules={rulesOrder.delivery_vehicle_type}
          />
          <div id="upload">
            <UploadDragger
              stateFileList={stateFileList}
              setStateFileList={setStateFileList}
              name="files"
              defaultFileList={defaultFileList}
            />
          </div>
        </div>
        <div id="item_infor" className="form-order-group">
          <TitleDetail title="荷物情報" />
          <div className="note">※ 重量と容積が明確な場合は入力お願い致します。</div>
          {typeOrder === "chapter" ? (
            <>
              <FormSelect
                label="車両"
                name="quantity_div"
                rules={rulesOrder?.quantity_div_chapter}
                placeholder="プルダウンから選択"
                listOptions={listOptVehicleChapter}
                subSpan={9}
              />
              <FormMultiInput
                form={form}
                label="容積"
                name="volume"
                subName="volume_div"
                rules={[rulesOrder.volumeChapter, rulesOrder.volume_div]}
                listOptions={listOptUnitVolume}
                optional={typeOrder === "chapter"}
                nameWrapper=""
              />
              <FormMultiInput
                label="重量"
                name="weight"
                rules={[rulesOrder.weightChapter]}
                unit="kg"
                optional={typeOrder === "chapter"}
                nameWrapper=""
              />
            </>
          ) : (
            <>
              <FormSelect
                label="品名"
                name="item_cd"
                rules={rulesOrder.item_cd}
                placeholder="プルダウンから選択"
                listOptions={listOptItems}
                optional
              />
              <FormMultiInput
                form={form}
                label="数量"
                name="quantity"
                subName="quantity_div"
                rules={[rulesOrder.quantity, rulesOrder.quantity_div]}
                listOptions={listOptUnitQuantity}
              />
              <FormMultiInput
                form={form}
                label="容積"
                name="volume"
                subName="volume_div"
                rules={[rulesOrder.volume, rulesOrder.volume_div]}
                listOptions={listOptUnitVolume}
              />
              <FormMultiInput label="重量" name="weight" rules={[rulesOrder.weight]} unit="kg" />
              <FormRadioGroup
                label="段積"
                name="stack_feasible_flg"
                rules={rulesOrder.stack_feasible_flg}
                listOptions={DIV_BOOL}
              />
              <FormCheckboxGroup
                form={form}
                label="荷姿"
                groupName="packing"
                listOptions={DIV_PACKING}
                isInitValues={isInitValues}
                rules={rulesOrder.packing}
              />
            </>
          )}
        </div>
        <div id="note" className="form-order-group">
          <TitleDetail title="コメント" />
          <FormArea
            disabled={role !== ROLE.CUSTOMER}
            label="お客様用"
            name="note_client"
            rules={rulesOrder.note_client}
            placeholder={"荷降先が複数の場合など特記事項がある場合に\n記載してください"}
            optional
          />
          <FormArea
            disabled={role === ROLE.CUSTOMER}
            label="タカスエ用"
            name="note_takasue"
            rules={rulesOrder.note_takasue}
            placeholder={"荷降先が複数の場合など特記事項がある場合に\n記載してください"}
            optional
          />
        </div>
      </Form>
    </Col>
  );
}

export default FormOrderDetail;
