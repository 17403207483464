import { Button, Tooltip } from "antd";
import { IconPdf } from "assets";
import PropTypes from "prop-types";
import React from "react";

import { formatDateOrder } from "common/utils/dateHelper";

import "./BaseDetailFile.scss";

BaseDetailFile.propTypes = {
  data: PropTypes.string,
  onPreview: PropTypes.func,
  isPdf: PropTypes.bool,
  onDelete: PropTypes.func
};

function BaseDetailFile({ data, onPreview, isPdf = false, onDelete }) {
  return (
    <div className="base-detail-file">
      <Button onClick={onDelete} danger className="delete-btn bg-white error">
        削除
      </Button>
      {isPdf ? (
        <div className="flex-center h-full">
          <div onClick={onPreview} className="cursor-pointer">
            <IconPdf />
          </div>
        </div>
      ) : (
        <Tooltip
          title={
            <div>
              <div>{formatDateOrder(data?.upd_datetime)}</div>
              <div>{data?.upd_user_nm}</div>
            </div>
          }
        >
          <img className="cursor-pointer" onClick={onPreview} src={data.photo_url} />
        </Tooltip>
      )}
    </div>
  );
}

export default BaseDetailFile;
