import { Col } from "antd";
import Form, { useWatch } from "antd/lib/form/Form";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { PER_ACCESS_PAGES, PER_AUTH_DIV } from "common/constant";
import {
  DIV_AUTH_GROUP,
  DIV_OFFICE_LOGIC,
  DIV_SERVICE,
  ID_INPUT_VALIDATE_MSG,
  INCOME_MAIL_SETTING,
  PWD_INPUT_VALIDATE_MSG
} from "common/constant/account";
import { rulesCreateAccount, validateMessages } from "common/constant/rules";
import useAuth from "common/hooks/useAuth";

import { masterDataApi } from "services/masterData";

import {
  flowAccountStore,
  setListOptOfficeInvoiceAction,
  setListOptOfficeOrderAction
} from "store/slices/flowAccount";

import {
  FormCheckboxGroup,
  FormDetail,
  FormInput,
  FormInputWithValidate,
  FormRadioGroup,
  FormSelect,
  TitleDetail
} from "components/form";

const FormCustomerAccount = ({ fixedItems, form, handleSubmitForm, editMode }) => {
  const { formData, listOptOfficeInvoice, listOptOfficeOrder } = useSelector(flowAccountStore);
  const dispatch = useDispatch();
  const [optSaleOffice, setOptSaleOffice] = useState({
    one: [],
    two: [],
    three: []
  });
  const [saleOfficeFixed, setSaleOfficeFixed] = useState({
    one: false,
    two: false,
    three: false
  });
  const [canEdit, setCanEdit] = useState();

  const officeLogicFlag = useWatch("office_logic_flag", form);
  const transportDiv = useWatch("transport_div", form);
  const mainOfficeDiv = useWatch("main_office_div", form);

  const { authPer } = useAuth();

  const fetchListOfficeByFlag = async (flag) => {
    await masterDataApi
      .getOfficesByFlag(flag)
      .then((response) => {
        const listTemp = response?.map((item) => ({
          label: item.office_nm,
          value: item?.office_cd
        }));

        if (flag === "all") {
          dispatch(setListOptOfficeInvoiceAction(listTemp));
          setOptSaleOffice({
            one: listTemp,
            two: listTemp,
            three: listTemp
          });
        } else dispatch(setListOptOfficeOrderAction(listTemp));
      })
      .catch((e) => console.log(e));
  };

  const onFocus = async () => {
    if(document.getElementById("user_id").hasAttribute("readOnly"))
      document.getElementById("user_id").removeAttribute("readOnly");
  };

  const onValuesChange = async (changedValues) => {
    const keyChanged = Object.keys(changedValues)[0];

    switch (keyChanged) {
      case "auth_group_order":
        form.validateFields(["service_div"]);
        break;
      case "office_logic_flag":
        form.validateFields(["sale_office_1"]);
        break;
      case "sale_office_1":
      case "sale_office_2":
      case "sale_office_3": {
        const listSaleOffices = Object.values(
          form.getFieldsValue(["sale_office_1", "sale_office_2", "sale_office_3"])
        );
        setOptSaleOffice({
          one: listOptOfficeInvoice.filter(
            (item) => !listSaleOffices.slice(-2).includes(item.value)
          ),
          two: listOptOfficeInvoice.filter(
            (item) => ![listSaleOffices[0], listSaleOffices[2]].includes(item.value)
          ),
          three: listOptOfficeInvoice.filter(
            (item) => !listSaleOffices.slice(0, 2).includes(item.value)
          )
        });
        break;
      }
      default:
        break;
    }
  };

  useEffect(() => {
    if (fixedItems?.length > 0 && formData) {
      setSaleOfficeFixed({
        one: Boolean(formData?.sale_office_1),
        two: Boolean(formData?.sale_office_2),
        three: Boolean(formData?.sale_office_3)
      });
    }
  }, [fixedItems, formData]);

  useEffect(() => {
    setCanEdit(authPer && authPer[PER_ACCESS_PAGES.CREATE_ACCOUNT] === PER_AUTH_DIV.CRUD);
  }, [authPer]);

  useEffect(async () => {
    if (formData) form.setFieldsValue(formData);
    fetchListOfficeByFlag("all");
    fetchListOfficeByFlag(1);
  }, []);

  return (
    <Col span={18}>
      <Form
        onValuesChange={onValuesChange}
        autoComplete="off"
        form={form}
        onFinish={handleSubmitForm}
        validateMessages={validateMessages}
        className={`${!canEdit && editMode && "form-disable"}`}
      >
        <div>
          <TitleDetail title="アカウント情報" />
          {editMode ? (
            <FormDetail label="ログインID" value={formData?.user_id} />
          ) : (
            <FormInputWithValidate
              validateMsg={ID_INPUT_VALIDATE_MSG}
              label="ログインID"
              name="user_id"
              readOnly
              onFocus={onFocus}
              rules={rulesCreateAccount.user_id}
              placeholder=""
              width={320}
            />
          )}
          <FormInput
            width={320}
            label="ユーザー名"
            name="user_nm"
            rules={rulesCreateAccount.user_nm}
            placeholder="テキスト入力"
          />
          <FormInput
            width={320}
            label="ユーザー名（フリガナ）"
            name="user_nm_kana"
            rules={rulesCreateAccount.user_nm_kana}
            placeholder="テキスト入力"
          />
          <FormInput
            width={320}
            label="電話番号"
            name="tel"
            rules={rulesCreateAccount.tel}
            placeholder="08011112222"
          />
          <FormInput
            width={320}
            label="メールアドレス"
            name="mail_addr"
            rules={rulesCreateAccount.mail_addr}
            placeholder="sample.gmail.com"
          />
          {!editMode && (
            <>
              <FormInput
                password
                width={320}
                label="パスワード"
                name="login_password"
                rules={rulesCreateAccount.login_password}
                placeholder="英数字・記号の組み合わせ"
                optional
              />
              <FormInputWithValidate
                password
                validateMsg={PWD_INPUT_VALIDATE_MSG}
                width={320}
                label="パスワード（確認）"
                name="login_password_confirm"
                rules={rulesCreateAccount.login_password_confirm}
                placeholder="英数字・記号の組み合わせ"
                optional
              />
            </>
          )}
          <FormCheckboxGroup
            label="受信メール設定"
            groupName="income_email_setting"
            optional
            listOptions={INCOME_MAIL_SETTING}
            form={form}
            noticeMsg="更新時にメールを受け取る場合はチェックを入れてください"
            isInitValues={formData}
          />
        </div>
        <div>
          <TitleDetail title="利用サービス" />
          <FormCheckboxGroup
            label="利用サービス"
            groupName="auth_group"
            rules={rulesCreateAccount.auth_group}
            listOptions={DIV_AUTH_GROUP}
            form={form}
            isInitValues={formData}
            fixedItems={fixedItems}
          />
        </div>
        <div>
          <TitleDetail title="請求先コード識別" />
          <FormCheckboxGroup
            label="請求先コード識別"
            groupName="service_div"
            rules={rulesCreateAccount.service_div}
            listOptions={DIV_SERVICE}
            form={form}
            isInitValues={formData}
            fixedItems={fixedItems}
          />
        </div>
        <div>
          <h2 className="sale-office-title">取扱営業所設定（本社取引先）</h2>
          <FormRadioGroup
            label="取扱営業所"
            name="office_logic_flag"
            listOptions={DIV_OFFICE_LOGIC}
            disabled={mainOfficeDiv !== "1"}
          />
          <FormSelect
            width={320}
            listOptions={optSaleOffice.one}
            placeholder="プルダウンから選択"
            label="営業所1"
            name="sale_office_1"
            rules={
              !(officeLogicFlag === "all" || mainOfficeDiv !== "1") &&
              rulesCreateAccount.sale_office_1
            }
            disabled={officeLogicFlag === "all" || mainOfficeDiv !== "1" || saleOfficeFixed.one}
          />
          <FormSelect
            width={320}
            listOptions={optSaleOffice.two}
            optional
            placeholder="プルダウンから選択"
            label="営業所2"
            name="sale_office_2"
            rules={rulesCreateAccount.sale_office_2}
            disabled={officeLogicFlag === "all" || mainOfficeDiv !== "1" || saleOfficeFixed.two}
          />
          <FormSelect
            width={320}
            listOptions={optSaleOffice.three}
            optional
            placeholder="プルダウンから選択"
            label="営業所3"
            name="sale_office_3"
            rules={rulesCreateAccount.sale_office_3}
            disabled={officeLogicFlag === "all" || mainOfficeDiv !== "1" || saleOfficeFixed.three}
          />
        </div>
        <div>
          <h2 className="sale-office-title">取扱営業所設定（運送取引先）</h2>
          <FormSelect
            width={320}
            listOptions={listOptOfficeOrder}
            placeholder="プルダウンから選択"
            label="運送取引営業所"
            name="office_order_cd"
            rules={transportDiv === "1" && rulesCreateAccount.office_order_cd}
            disabled={transportDiv !== "1" || fixedItems?.includes("transport_div")}
          />
        </div>
      </Form>
    </Col>
  );
};
FormCustomerAccount.propTypes = {
  form: PropTypes.any,
  handleSubmitForm: PropTypes.func,
  editMode: PropTypes.bool,
  fixedItems: PropTypes.array
};
export default FormCustomerAccount;
