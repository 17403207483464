import { Button, Col, Row } from "antd";
import { PATH_NAME } from "common/constant";
import Divider from "components/common/Divider";
import { FormDetail } from "components/form";
import { TitleDetail } from "components/form";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { userApi } from "services/user";
import "./UserDetail.scss";

function UserDetail() {
  const [inforUser, setInforUser] = useState();
  const navigate = useNavigate();
  useEffect(async () => {
    const response = await userApi.getMeSettings();
    setInforUser(response);
  }, []);
  return (
    <div className="account-page bg-white">
      <h1 className="big-title">アカウント設定</h1>
      <Divider height="2px" />
      <Row gutter={40} className="infor">
        <Col flex="auto">
          <div>
            <TitleDetail title="アカウント情報" />
            <FormDetail label="ログインID" value={inforUser?.id} />
            <FormDetail label="お名前" value={inforUser?.user_nm} />
            <FormDetail label="お名前（カナ）" value={inforUser?.user_nm_kana} />
            <FormDetail label="電話番号" value={inforUser?.tel} />
            <FormDetail label="メールアドレス" value={inforUser?.mail_addr} />
          </div>
        </Col>
        <Col flex="280px">
          <div className="text-center">内容を変更する場合は</div>
          <div className="text-center">アカウント情報変更を押してください</div>
          <Row justify="center">
            <Button
              size="large"
              onClick={() => navigate(PATH_NAME.USER_UPDATE)}
              className="btn-act"
            >
              アカウント情報変更
            </Button>
          </Row>
        </Col>
      </Row>
    </div>
  );
}

export default UserDetail;
