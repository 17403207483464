import React from "react";
import PropTypes from "prop-types";
import { Button, Col } from "antd";
import TableContent from "./TableContent";
import { IconPhone, IconTrash } from "assets";
import "./RightOrder.scss";
import Icon from "@ant-design/icons/lib/components/Icon";

RightDetailOrder.propTypes = {
  editMode: PropTypes.bool,
  toggleEditMode: PropTypes.func,
  handleSubmit: PropTypes.func,
  handleRemove: PropTypes.func,
  isDone: PropTypes.bool,
  inforOffice: PropTypes.object,
  loading: PropTypes.bool
};

function RightDetailOrder({
  editMode,
  toggleEditMode,
  handleSubmit,
  handleRemove,
  isDone,
  inforOffice,
  loading
}) {
  return (
    <Col span={6} className="right-create-order">
      <TableContent />
      <div className={`${isDone && "dis-none"} hint`}>
        {editMode
          ? "内容の確認後\n注文ボタンを押してください"
          : "内容を変更する場合は\n注文内容の変更を押してください"}
      </div>
      {isDone ? (
        <>
          <div className="hint">{"内容の確認後、\n変更ボタンを押してください"}</div>
          <Button loading={loading} type="primary" size="large" onClick={handleSubmit}>
            変更
          </Button>
        </>
      ) : editMode ? (
        <>
          <Button loading={loading} type="primary" size="large" onClick={handleSubmit}>
            変更
          </Button>
          <Button style={{ marginTop: "8px" }} size="large" onClick={toggleEditMode}>
            キャンセル
          </Button>
        </>
      ) : (
        <>
          <Button size="large" onClick={toggleEditMode}>
            注文内容の変更
          </Button>
          <Button
            style={{ marginTop: "8px" }}
            size="large"
            danger
            icon={<Icon style={{ fontSize: "20px" }} component={IconTrash} />}
            onClick={handleRemove}
          >
            注文キャンセル
          </Button>
        </>
      )}
      {inforOffice && (
        <div className="infor-office">
          <div className="phone">
            <IconPhone />
            {inforOffice?.tel}
          </div>
          <p>{inforOffice?.label}</p>
          <span>
            その他ご要望については、詳細情報を添付いただくか、弊社まで直接お電話ください。
          </span>
        </div>
      )}
    </Col>
  );
}

export default RightDetailOrder;
