import { Row } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { PATH_NAME, ROLE } from "common/constant";
import { DATA_FLAG_ALL, DIV_AUTH_GROUP_CODE } from "common/constant/account";

import { masterDataApi } from "services/masterData";
import { userApi } from "services/user";

import {
  setFormDataAction,
  setListBillingAddressAction,
  setListOfficeClientAction,
  setListOptOfficeInvoiceAction,
  setListOptOfficeOrderAction
} from "store/slices/flowAccount";

import { FooterDetail, InforAccount, RightDetail } from "../components";

import Divider from "components/common/Divider";

function DetailAccount() {
  const { userId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [roleId, setRoleId] = useState();

  const handleEdit = () => {
    switch (roleId) {
      case "01":
        navigate(`/account_upd/main/${userId}`);
        break;
      case "02":
        navigate(`/account_upd/honsha/${userId}`);
        break;
      case "03":
        navigate(`/account_upd/unso/${userId}`);
        break;
      default:
        break;
    }
  };

  const handleRemove = async () => {
    await userApi
      .deleteUser(userId)
      .then(() => navigate(PATH_NAME.ACCOUNT_LIST))
      .catch((e) => console.log(e));
  };

  const fetchInforUser = async () => {
    let formData = {};
    await userApi
      .getUserById(userId)
      .then((res) => {
        const { id, perm, ...rest } = res;
        formData = {
          ...formData,
          user_id: id,
          auth_group_invoice: perm.includes(DIV_AUTH_GROUP_CODE.INVOICE) ? "1" : "0",
          auth_group_order: perm.includes(DIV_AUTH_GROUP_CODE.ORDER) ? "1" : "0",
          ...rest
        };
      })
      .catch((e) => console.log(e));
    await userApi
      .getBillingByUserId(userId)
      .then((res) => {
        const roleOfUser = formData.use_user_div;
        if (roleOfUser === ROLE.CUSTOMER) {
          const listBillingClient = res?.filter((item) => item?.billing_addr_cd_id_div === "01");
          const listBillingTrans = res?.filter((item) => item?.billing_addr_cd_id_div === "02");
          let office_to_client_cds = listBillingClient?.map((item) => item?.office_cd);
          let clientCds = listBillingClient?.map((item) => ({
            id: item?.billing_addr_cd,
            name: item?.billing_addr_nm
          }));
          // get unique
          office_to_client_cds = [...new Set(office_to_client_cds)];
          // get unique
          clientCds = [...new Map(clientCds.map((item) => [item["id"], item])).values()];
          const billingAddrCds = listBillingTrans?.map((item) => ({
            id: item?.billing_addr_cd,
            name: item?.billing_addr_nm
          }));
          const office_order_cd = listBillingTrans[0]?.office_cd;
          const objSaleOffice = {};
          office_to_client_cds.forEach((item, index) => {
            objSaleOffice[`sale_office_${index + 1}`] = item;
          });
          formData = {
            ...formData,
            office_order_cd,
            office_logic_flag:
              office_to_client_cds[0] !== DATA_FLAG_ALL.OFFICE_CD[0] ? "some" : "all",
            main_office_div: office_to_client_cds?.length > 0 ? "1" : "0",
            transport_div: office_order_cd ? "1" : "0",
            ...objSaleOffice
          };
          if (formData.save_flg !== "1") {
            dispatch(setListOfficeClientAction(clientCds));
            dispatch(setListBillingAddressAction(billingAddrCds));
          }
        } else {
          formData = { ...formData, office_order_cd: res[0]?.office_cd };
        }
      })
      .catch((e) => console.log(e));
    dispatch(setFormDataAction(formData));
    setRoleId(formData.use_user_div);
  };

  const fetchListOfficeByFlag = async (flag) => {
    await masterDataApi
      .getOfficesByFlag(flag)
      .then((response) => {
        const listTemp = response?.map((item) => ({
          label: item.office_nm,
          value: item?.office_cd
        }));

        if (flag === "all") {
          dispatch(setListOptOfficeInvoiceAction(listTemp));
        } else dispatch(setListOptOfficeOrderAction(listTemp));
      })
      .catch((e) => console.log(e));
  };

  useEffect(() => {
    fetchInforUser();
    fetchListOfficeByFlag("all");
    fetchListOfficeByFlag(1);
  }, []);

  return (
    <div className="create-customer-account-page">
      <div className="main-content bg-white">
        <h1 className="title">アカウント情報参照</h1>
        <Divider height="2px" />
        <Row justify="space-between" gutter={[100]} className="form-customer-account">
          <InforAccount isDetail={true} />
          <RightDetail handleRemove={handleRemove} handleEdit={handleEdit} />
        </Row>
      </div>
      <FooterDetail handleRemove={handleRemove} handleEdit={handleEdit} />
    </div>
  );
}

export default DetailAccount;
