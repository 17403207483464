import api from "./baseApi";

export const orderApi = {
  getList(params) {
    return api.get("/orders/", { params });
  },
  create(params) {
    return api.post("/orders/", params, { headers: { "Content-Type": "multipart/form-data" } });
  },
  update(params, id) {
    return api.patch(`/orders/${id}`, params, {
      headers: { "Content-Type": "multipart/form-data" }
    });
  },
  getDataById(id) {
    return api.get(`/orders/${id}`);
  },
  delete(listId) {
    return api.delete("/orders/delete", { data: listId });
  },
  importCSV(params, options) {
    return api.post("/orders/import-csv", params, {
      headers: { "Content-Type": "multipart/form-data" },
      ...options
    });
  },
  getAttachment(params) {
    return api.get(`/orders/attachment-path?file_path=${params}`);
  },
  exportCSV() {
    return api.post("/orders/export-csv");
  }
};
