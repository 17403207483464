import { Form, Row } from "antd";
import { useForm } from "antd/lib/form/Form";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { OPTION_CUSTOMER_DIV } from "common/constant";

import { baseApi } from "services/base";

import { authStore } from "store/slices/auth";
import { setFilterBaseAction } from "store/slices/listDataBase";
import { listFilterBaseStore } from "store/slices/listDataBase";
import { getListOptOfficesAction, pulldownStore } from "store/slices/pulldown";

import TableListBase from "../components/TableListBase";

import Divider from "components/common/Divider";
import InputFilter from "components/filter/InputFilter";
import SelectFilter from "components/filter/SelectFilter";

function ListBase() {
  const [listBase, setListBase] = useState([]);
  const [totalBases, setTotalBases] = useState();
  const { filterBaseObject } = useSelector(listFilterBaseStore);
  const [page, setPage] = useState(1);
  const [sortParams, setSortParams] = useState([]);

  const dispatch = useDispatch();
  const [form] = useForm();
  const { company_cd } = useSelector(authStore);
  const { listOptOffices } = useSelector(pulldownStore);

  const initialValues = {
    ...filterBaseObject
  };

  const fetchListBase = async () => {
    const res = await baseApi.getListBase({
      ...filterBaseObject,
      page,
      company_cd,
      sort: sortParams.length > 0 ? sortParams : []
    });
    setListBase(res.items);
    setTotalBases(res.total);
  };

  const handleFilter = (changedFields) => {
    if (changedFields[0].value) {
      if (["office_cd", "customer_div"].includes(changedFields[0].name[0])) {
        dispatch(
          setFilterBaseAction({
            ...filterBaseObject,
            [changedFields[0].name[0]]: changedFields[0].value,
          })
        );
      } else {
        dispatch(
          setFilterBaseAction({
            ...filterBaseObject,
            [changedFields[0].name[0]]: changedFields[0].value,
          })
        );
      }
    } else {
      const cloneFilter = { ...filterBaseObject };
      delete cloneFilter[changedFields[0].name[0]];
      dispatch(setFilterBaseAction({ ...cloneFilter }));
    }
  };

  const handleSortOrders = (pagination, _, sorter) => {
    if (pagination.current === page) {
      if (sorter.length > 1) {
        setSortParams(
          sorter.map((item) => (item.order === "descend" ? `-${item.field}` : item.field))
        );
      } else if (sorter.column) {
        setSortParams([sorter.order === "descend" ? `-${sorter.field}` : sorter.field]);
      } else {
        setSortParams([]);
      }
    }
  };
  const handleChangePage = (currentPage) => {
    setPage(currentPage);
  };

  useEffect(() => {
    if (company_cd) {
      fetchListBase();
    }
  }, [company_cd, filterBaseObject, sortParams, page]);

  useEffect(() => {
    dispatch(getListOptOfficesAction());
  }, []);

  return (
    <div className="bg-white px-24 py-28">
      <Row align="middle" justify="space-between" className="mb-20">
        <h1 className="fs-24 fw-700">お客様カルテ</h1>
      </Row>
      <Divider height="2px" />
      <Form
        form={form}
        onFieldsChange={handleFilter}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        autoComplete="off"
        className="mt-20"
        initialValues={initialValues}
      >
        <Row gutter={[16, 12]}>
          <SelectFilter
            title="営業所"
            name="office_cd"
            placeholder=""
            listOptions={listOptOffices}
            width={260}
          />
          <InputFilter title="お客様カルテコード" placeholder="" name="chart_cd" width={260} />
          <InputFilter title="名称" placeholder="" name="name" width={260} />
          <SelectFilter
            title="荷主荷受人区分"
            name="customer_div"
            placeholder=""
            listOptions={OPTION_CUSTOMER_DIV}
            width={260}
          />
        </Row>
      </Form>
      <TableListBase
        page={page}
        dataSource={listBase}
        totalRow={totalBases}
        onSortOrders={handleSortOrders}
        onPageChange={handleChangePage}
      />
    </div>
  );
}

export default ListBase;
