import { trimObj } from "./objectHelper";

export const getLabel = (value, array) => {
  return array?.find((item) => item?.value === value)?.label || "";
};
export const stringifyParams = (params) => {
  const trimParams = trimObj(params);
  return Object.keys(trimParams)
    .map((key) => {
      if (key === "sort") {
        return trimParams[key].map((e) => `sort=${e}`).join("&");
      } else {
        return `${key}=${String(trimParams[key])?.replaceAll("#", "%27")}`;
      }
    })
    .join("&");
};

export const splitAddress = (address) => {
  if (address) {
    const [addrCode, ...rest] = address.split(" ");
    const addrName = rest.join(" ");
    return [addrCode, addrName];
  }
};

export const getByteLength = (s) => {
  return s.replace(
    // eslint-disable-next-line no-control-regex
    /[^\x00-\x80｡｢｣､･ｦｧｨｩｪｫｬｭｮｯｰｱｲｳｴｵｶｷｸｹｺｻｼｽｾｿﾀﾁﾂﾃﾄﾅﾆﾇﾈﾉﾊﾋﾌﾍﾎﾏﾐﾑﾒﾓﾔﾕﾖﾗﾘﾙﾚﾛﾜﾝ ﾞ ﾟ]/g,
    "xx"
  ).length;
};
