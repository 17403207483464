import Icon from "@ant-design/icons/lib/components/Icon";
import { Button, Col } from "antd";
import { IconTrash } from "assets";
import PropTypes from "prop-types";
import React from "react";

import { ROLE } from "common/constant";
import useAuth from "common/hooks/useAuth";

RightDetail.propTypes = {
  handleRemove: PropTypes.func,
  handleEdit: PropTypes.func
};

function RightDetail({ handleEdit, handleRemove }) {
  const { role } = useAuth();
  return (
    <Col span={6}>
      <div className="right-customer-account">
        <p>内容を変更する場合は</p>
        <p>アカウント情報の変更を押してください</p>
        <div>
          <Button onClick={handleEdit} className="btn-submit" size="large">
            アカウント内容の変更
          </Button>
        </div>
        {role === ROLE.ADMIN && (
          <Button
            className="btn-submit"
            style={{ marginTop: "8px" }}
            size="large"
            danger
            icon={<Icon style={{ fontSize: "20px" }} component={IconTrash} />}
            onClick={handleRemove}
          >
            アカウント削除
          </Button>
        )}
      </div>
    </Col>
  );
}

export default RightDetail;
